import React, { useEffect, useState } from "react";
import { format, startOfMonth } from "date-fns";
import {
  TokenUsageTracking,
  useUserTokenUsage,
} from "../../../../../../api/queryHooks";
import { toast } from "../../../../../utilities/Toast";
import { CircularProgress } from "@mui/material";
import { formatDateInputValue } from "../../../../../../utils/datetime";
import { ChartEndpointEntry } from "./types";
import { TokenUsageGraph } from "./TokenUsageGraph";
import { tokenListToCharEndpointData } from "./utils";

export function UserTokenUsageComponent() {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState<ChartEndpointEntry[]>();

  const { isLoading, data, error } = useUserTokenUsage({
    startDate,
    endDate,
  });

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    if (error) {
      toast.error({
        title: "Failed to fetch token usage data:",
        description: error,
      });
      return;
    }

    const transformedData = transformDataForGraph(data);
    setChartData(transformedData);
  }, [isLoading, data, error]);

  const transformDataForGraph = (
    apiResponse: TokenUsageTracking,
  ): ChartEndpointEntry[] => {
    return tokenListToCharEndpointData(apiResponse.token_list);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedDate = new Date(event.target.value);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setEndDate(selectedDate);
  };

  return (
    <div className="p-3">
      <div className="flex flex-col md:flex-row justify-center items-center gap-6 mt-4">
        <div>
          <label className="block text-gray-800 font-medium mb-1">Start:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-primary rounded-lg shadow-lg focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(startDate)}
            onChange={handleStartDateChange}
            max={formatDateInputValue(endDate)}
          />
        </div>
        <div>
          <label className="block text-gray-800 font-medium mb-1">End:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-primary rounded-lg shadow-lg focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(endDate)}
            onChange={handleEndDateChange}
            min={formatDateInputValue(startDate)}
            max={formatDateInputValue(new Date())}
          />
        </div>
      </div>
      {isLoading && (
        <div className="text-gray-500 text-lg text-center py-10">
          <CircularProgress />
        </div>
      )}
      {!isLoading && chartData && (
        <div className="space-y-6">
          <div className="shadow-lg rounded-lg overflow-hidden">
            <TokenUsageGraph data={chartData} />
          </div>
        </div>
      )}
    </div>
  );
}
