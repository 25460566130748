import React, { useContext, useState, useRef, useEffect } from "react";
import { DataContext } from "../../../../../../../context/DataContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faFile,
  faInfoCircle,
  faTimes,
  faArrowLeft,
  faFileUpload,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import FolderList from "../../../../../../utilities/NavigationBar/FolderList";
import { ENDPOINTS } from "../../../../../../../api/endpoints";
import { sendRequest } from "../../../../../../utilities/functions/api";
import Auth from "../../../../../../../auth/AuthProvider";
import ReactDatePicker from "react-datepicker";
import { toast } from "./../../../../../../utilities/Toast";

const ScheduleSettingComponent = ({
  folders,
  currentFolder,
  setCurrentFolder,
  checkedItems,
  setCheckedItems,
  setShowFilePreview,
  filteredFolderKeys,
  searchText,
  setSearchText,
  setFolders,
  setTimeSelectionModalOpen,
  selectedTagKeys,
  isSmartSelectEnabled,
  setIsSmartSelectEnabled,
  availableTags,
  currentDataGroup,
  usedCatalog,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [minTime, setMinTime] = useState(new Date());
  const [maxTime, setMaxTime] = useState(new Date().setHours(23, 59, 59, 999));
  const [type, setType] = useState("One-time");
  const [integration, setIntegration] = useState("s3");
  const [isExternalOptionsOpen, setIsExternalOptionsOpen] = useState(false);
  const [isFolderListModalOpen, setIsFolderListModalOpen] = useState(false);
  const { preferences } = useContext(DataContext);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  useEffect(() => {
    if (type === "Periodic") {
      setSelectedPeriod("HALF_HOUR");
    }
  }, [type]);

  const fetchFolders = async (integration) => {
    let dataStore = preferences.webapp_profile.DATA_STORES[integration];
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"],
      );
      const retrievedFolders = await folderResponse.json();

      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    }
  };

  const handleIntegrationClick = (key) => {
    setIntegration(key);
    fetchFolders(key);
    setIsFolderListModalOpen(true);
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date &&
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const getMinTime = (date) => {
    const now = new Date();
    if (!date || isToday(date)) {
      return now;
    }
    return new Date().setHours(0, 0, 0, 0);
  };

  const getMaxTime = () => {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  };

  useEffect(() => {
    setMinTime(getMinTime(selectedDate));
    setMaxTime(getMaxTime());
  }, [selectedDate]);

  const WarningModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg">
          <div className="text-lg">
            Please select a start date and time before confirming the schedule.
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={onClose}
              className="mt-2 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleScheduleTaskByChunk = async () => {
    if (!selectedDate) {
      setIsWarningModalOpen(true);
      return;
    }
    setIsWarningModalOpen(false);
    setTimeSelectionModalOpen(false);

    const entries = [];
    const smartSelection = isSmartSelectEnabled;

    const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
    for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
      {}) {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey))
        continue;
      delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
    }

    const creds = (await Auth.currentAuthenticatedUser()).username;
    let endpoint, params;
    if (
      type === "Periodic" &&
      selectedPeriod &&
      selectedPeriod !== "" &&
      selectedPeriod !== '""'
    ) {
      endpoint = ENDPOINTS["schedule_periodic_catalog_creation_bulk"];
      params = {
        schedule_at: selectedDate.toISOString(),
        period: selectedPeriod,
      };
    } else {
      endpoint = ENDPOINTS["schedule_catalog_creation_bulk"];
      params = {
        schedule_at: selectedDate.toISOString(),
      };
    }
    const dataSnapShot = { ...currentDataGroup };

    const selectedFiles = Object.keys(checkedItems).filter(
      (file) => checkedItems[file].isChecked,
    );

    for (const file_name of selectedFiles) {
      let fileDetails = dataSnapShot[file_name] || {};
      let dataStore =
        preferences.webapp_profile.DATA_STORES[checkedItems[file_name].source];

      let path, data_store_details;
      if (fileDetails.file_directory) {
        path = `${fileDetails.file_directory}/${file_name}`;
        const catalogItem = dataSnapShot[file_name];
        data_store_details = JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[
            catalogItem.data_store_name
              ? catalogItem.data_store_name
              : catalogItem.storage_type
          ],
          path: `${catalogItem.file_directory}/${file_name}`,
        });
      } else {
        const { source, folder } = checkedItems[file_name];
        let baseDirectory = dataStore.base_path;
        if (baseDirectory[baseDirectory.length - 1] !== "/") {
          baseDirectory += "/";
        }
        const subDirectory = folder === "/" ? "" : folder;
        data_store_details = JSON.stringify({
          ...dataStore,
          path: `${baseDirectory}${subDirectory}${file_name}`,
        });
      }
      const sendChunkObject = {
        data_store: data_store_details,
        tagger_list: JSON.stringify(availableTagsCopy),
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        file_catalog_entry: JSON.stringify({ [file_name]: {} }),
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: false,
        smart_selection: smartSelection,
      };

      entries.push(sendChunkObject);
    }
    try {
      const sendDetails = {
        ...params,
        entries,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        preferences: JSON.stringify(preferences),
        period: JSON.stringify(selectedPeriod || ""),
      };
      await sendRequest(sendDetails, endpoint);
      toast.success({
        title: `Labeling scheduling at ${new Date(selectedDate).toLocaleDateString()}`,
      });
    } catch (error) {
      console.error("Error during scheduling setup:", error);
      toast.error({
        title: `Labeling scheduled at ${new Date(selectedDate).toLocaleDateString()} failed`,
      });
    } finally {
      setIsSmartSelectEnabled(false);
    }
  };

  return (
    <div className="flex flex-col pt-10 px-5 items-center w-full">
      <div className="flex justify-between w-full gap-3">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={() => setTimeSelectionModalOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} className="text-xl" />
        </button>
        <div
          className="w-3/5 bg-white p-10 rounded-lg shadow-md"
          style={{ height: "70vh", maxHeight: "70vh" }}
        >
          <h2 className="text-3xl font-bold mb-5">Select Data</h2>
          {isFolderListModalOpen ? (
            <div className="w-full h-full flex justify-center items-center overflow-y-auto">
              <FolderList
                folders={folders}
                currentFolder={currentFolder}
                checkedItems={checkedItems}
                searchText={searchText}
                setCurrentFolder={setCurrentFolder}
                setCheckedItems={setCheckedItems}
                integration={integration}
                setShowFilePreview={setShowFilePreview}
                filteredFolderKeys={filteredFolderKeys}
                setSearchText={setSearchText}
                mode="scheduling"
              />
            </div>
          ) : (
            <>
              {Object.keys(checkedItems).length !== 0 ? (
                <>
                  <div className="mb-5">
                    <label className="text-gray-700">
                      Files selected from current catalog:
                    </label>
                  </div>
                  <div className="flex flex-wrap items-start overflow-y-auto h-32">
                    {Object.keys(checkedItems).map(
                      (filename) =>
                        checkedItems[filename] && (
                          <span
                            key={filename}
                            className="bg-gray-100 rounded-md px-3 py-1 text-xs text-gray-700 mr-2 mb-2"
                          >
                            {filename}
                          </span>
                        ),
                    )}
                  </div>
                </>
              ) : (
                <p className="text-gray-600">
                  You haven't selected any files from the catalog
                </p>
              )}
              <div className="mt-5">
                <button
                  className="border-blue-500 border-2 text-blue-500 hover:bg-blue-500 hover:text-white px-4 py-2 rounded-md transition duration-300"
                  onClick={() => setIsExternalOptionsOpen(true)}
                >
                  Connect to external data source
                </button>
              </div>
              {isExternalOptionsOpen && (
                <div className="mt-4 space-y-2">
                  {Object.entries(preferences.webapp_profile.DATA_STORES).map(
                    ([key, value]) => (
                      <div
                        key={key}
                        className="flex items-center p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition duration-300"
                        onClick={() => handleIntegrationClick(key, "s3List")}
                      >
                        <FontAwesomeIcon
                          icon={
                            value["storage"]["type"] === "s3"
                              ? faAmazon
                              : value["storage"]["type"] === "sharepoint"
                                ? faMicrosoft
                                : faFile
                          }
                          className={`mr-2 ${
                            value["storage"]["type"] === "s3"
                              ? "text-yellow-500"
                              : "text-blue-500"
                          }`}
                        />
                        <span>{`Connect to ${key}`}</span>
                      </div>
                    ),
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="w-2/5 bg-white p-10 rounded-lg shadow-md"
          style={{ height: "70vh", maxHeight: "70vh" }}
        >
          <h2 className="text-3xl font-bold mb-5">Configure Schedule</h2>
          <hr className="mb-5" />
          <form className="space-y-6">
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Start date and time
              </label>
              <ReactDatePicker
                id="date"
                selected={selectedDate || new Date()} // Set default to current date and time
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect
                showTimeInput
                dateFormat="MMM d, yyyy h:mm aa"
                minDate={new Date()}
                minTime={minTime}
                maxTime={maxTime}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                popperPlacement="bottom-start"
                calendarClassName="bg-white shadow-lg rounded-lg border border-gray-200"
                showPopperArrow={false}
              />
            </div>
            <div>
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Type
              </label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="Periodic">Periodic</option>
                <option value="One-time">One-time</option>
              </select>
            </div>
            {type === "Periodic" && (
              <div className="space-y-4">
                <div className="bg-green-50 border-l-4 border-primary p-4">
                  <p className="text-sm text-green-700">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    Periodic scheduling means that the selected folders will be
                    continuously scanned for:
                    <ul className="list-disc list-inside mt-2 ml-4 mb-2">
                      <li>1. Changes to existing files</li>
                      <li>2. Modified files</li>
                      <li>3. New files added to the folder</li>
                    </ul>
                    Any detected changes will trigger automatic tagging of the
                    affected files.
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="period"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Scheduling period
                  </label>
                  <select
                    id="period"
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="HALF_HOUR">Every 30 minutes</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="DAILY">Daily</option>
                  </select>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      {isWarningModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg">
            <p className="text-lg mb-4">
              Please select a start date and time before confirming the
              schedule.
            </p>
            <button
              onClick={() => setIsWarningModalOpen(false)}
              className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex w-full justify-between items-center mt-6">
        <div className="bg-gray-100 rounded-md p-4 flex items-center max-w-lg">
          <FontAwesomeIcon icon={faInfoCircle} className="text-primary mr-3" />
          <p className="text-sm text-gray-700">
            {
              "To preview scheduled tasks, go to Account -> Tag -> Scheduled Task Console"
            }
          </p>
        </div>
        <button
          onClick={handleScheduleTaskByChunk}
          disabled={Object.keys(checkedItems).length === 0}
          className="bg-primary text-white text-lg font-bold py-3 px-10 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Confirm Schedule
        </button>
      </div>
    </div>
  );
};

export default ScheduleSettingComponent;
