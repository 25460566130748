import React, { useState, useEffect } from 'react';
import Auth from "../../../auth/AuthProvider";
import { ENDPOINTS } from "../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";
import { sendRequest } from "../functions/api";
import "./ScheduledTasksModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

export const ScheduledTasksModal = ({ isModalOpen }) => {
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');

  const SortIcon = ({ isAsc }) => (
    <FontAwesomeIcon icon={isAsc ? faSortUp : faSortDown} />
  );

  const toggleSortDate = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedTasks = [...scheduledTasks].sort((a, b) => {
      const dateA = new Date(a.schedule_at);
      const dateB = new Date(b.schedule_at);
      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setScheduledTasks(sortedTasks);
  };

  const onSortChange = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedTasks = [...scheduledTasks].sort((a, b) => {
      const dateA = new Date(a.schedule_at);
      const dateB = new Date(b.schedule_at);
      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setScheduledTasks(sortedTasks);
  };

  const fetchScheduledTasks = async () => {
    setIsLoading(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_user_scheduled_tasks"]);
      const responseData = await response.json();
      setScheduledTasks(responseData.scheduled_tasks || []); // Ensuring default to empty array
    } catch (error) {
      console.error('Failed to fetch scheduled tasks:', error);
      setScheduledTasks([]); // Resetting on error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchScheduledTasks();
    }
  }, [isModalOpen]);

  useEffect(() => {
    setFilteredTasks(
      scheduledTasks.filter(task => statusFilter ? task.status.toLowerCase() === statusFilter.toLowerCase() : true)
    );
  }, [statusFilter, scheduledTasks]);

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  if (!isModalOpen) return null;

  return (
    <div className="scheduled-tasks-modal">
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <div className="flex h-full w-full p-4 flex-col gap-1">
          <div className="ModalHeader">Scheduled Tasks
            <button onClick={fetchScheduledTasks} className="refresh-button">Refresh</button>
          </div>
          <div className="flex w-full justify-center">
            <table className="w-full styled-table">
              <thead>
                <tr>
                  <th>Task ID</th>
                  {/* <th>Catalog Name</th> */}
                  {/* <th>File</th> */}
                  {/* <th>Tags</th> */}
                  {/* <th>Schedule Time</th> */}
                  <th onClick={onSortChange}>
                    Schedule Time <SortIcon isAsc={sortDirection === 'asc'} />
                  </th>
                  <th>Running Period</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">No scheduled tasks</td>
                  </tr>
                ) : (
                  filteredTasks.map(task => (
                    <tr key={task.task_id}>
                      <td>{task.task_id}</td>
                      {/* <td>{task.catalog_name}</td> */}
                      {/* <td>{Object.keys(task.file_catalog_entry || {}).join(', ')}</td> */}
                      {/* <td>{(task.tags || []).join(', ')}</td> */}
                      <td>{new Date(task.schedule_at).toLocaleString()}</td>
                      <td>{task.running_period || 'Non-periodic'}</td>
                      <td>
                        <div className={`status-${task.status.toLowerCase()}`}>
                          {task.status}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
