import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Radio,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ListSubheader,
  TableContainer,
  Tabs,
  Tab,
  TextField,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import "./styles.css";
import { useUsers, updateGroups } from "../../../hooks/Users";
import { toast } from "../Toast";
import { sendRequest } from "../functions/api";
import { ENDPOINTS } from "../../../api/endpoints";
import { DataContext } from "../../../context/DataContext";
import Auth from "../../../auth/AuthProvider";
import { NumericFormat } from 'react-number-format';

export const TeamManager = ({ onClose }) => {
  const { preferences } = useContext(DataContext);
  const users = useUsers();

  const [isLoading, setIsLoading] = useState(false);
  const [snapshot, setSnapshot] = useState([]);
  const [catalogTeams, setCatalogTeams] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // 0 for "Manage User", 1 for "Manage Tokens"
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamUsers, setTeamUsers] = useState([]);
  const [userTokenLimits, setUserTokenLimits] = useState({});
  const [teamTokenLimit, setTeamTokenLimit] = useState(null);

  const isOk = useMemo(
    () => !users.isLoading && !users.isError && !isLoading,
    [users.isLoading, users.isError, isLoading],
  );

  useEffect(() => {
    if (users.isLoading) return;

    if (users.isError) {
      toast.error({ title: "Failed to load teams" });
      onClose();
      return;
    }

    setSnapshot(users.users);
    setCatalogTeams(users.catalogTeams);
    setIsLoading(false);
  }, [users, onClose]);

  const submit = useCallback(async () => {
    onClose();

    if (activeTab === 1) { // for token management

      // if team token is null, then toast error
      if (teamTokenLimit === null) {
        toast.error({
          title: "Team token limit not set so you can't assign tokens",
          description: "Please contact your admin",
        });
        return;
      }
      const totalAssignedTokens = Object.values(userTokenLimits).reduce((sum, limit) => {
        const tokenLimit = parseInt(limit, 10);
        return sum + (isNaN(tokenLimit) ? 0 : tokenLimit);
      }, 0);

      if (totalAssignedTokens > teamTokenLimit) {
        toast.error({
          title: "Token limit exceeded",
          description: `Total assigned tokens (${totalAssignedTokens.toLocaleString()}) exceed the team's available tokens (${teamTokenLimit.toLocaleString()}).`,
        });
        return;
      }

      try {
        const updatePromises = teamUsers
          .filter((user) => userTokenLimits[user.id] !== null && userTokenLimits[user.id] !== undefined)
          .map((user) =>
            updateUserTokenLimit(user.id, parseInt(userTokenLimits[user.id], 10))
          );

        await Promise.all(updatePromises);
        toast.success({ title: "Token limits updated successfully" });
      } catch (error) {
        toast.error({ title: "Failed to update token limits" });
        return;
      }

    } else { // for user management
      updateGroups({
        users: snapshot,
        catalog_teams: catalogTeams,
      })
        .then(() => {
          toast.success({ title: "Teams updated" });
          onClose();
        })
        .catch((error) => {
          console.error(error);
          toast.error({ title: "Failed to update teams" });
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [snapshot, catalogTeams, onClose, activeTab, teamUsers, userTokenLimits]);

  const updateSnapshot = useCallback(
    (email, key, value) => {
      const newSnapshot = snapshot.map((user) => (user.email === email ? { ...user, [key]: value } : user));
      setSnapshot(newSnapshot);
    },
    [snapshot],
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchTeamUsers = async (team) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        catalog_team: team,
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_team_members"]);
      const data = await response.json();

      const transformedData = data.catalog_team_users.map(user => {
        const [id, details] = Object.entries(user)[0];
        return { id, ...details };
      });

      setTeamUsers(transformedData);
      return transformedData;
    } catch (error) {
      console.error('Failed to fetch team users', error);
      toast.error({ title: 'Failed to fetch team users' });
    }
  };

  const fetchTeamTokenLimit = async (team) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        team_name: team,
        group_total: true,
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_token_limit"]);
      const data = await response.json();
      const token_limit = data.token_limit;
      setTeamTokenLimit(token_limit);
    } catch (error) {
      console.error('Failed to fetch team token limit', error);
    }
  };

  const fetchUserTokenLimits = async (teamUsers) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const promises = teamUsers.map(async (user) => {
        const sendDetails = {
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          group_total: false,
          user_id: user.id,
        };
        const response = await sendRequest(sendDetails, ENDPOINTS["get_token_limit"]);
        const data = await response.json();
        return { id: user.id, token_limit: data.token_limit };
      });
      const results = await Promise.all(promises);
      const updatedUserTokenLimits = results.reduce((acc, { id, token_limit }) => {
        acc[id] = token_limit;
        return acc;
      }, {});
      setUserTokenLimits(updatedUserTokenLimits);
    } catch (error) {
      console.error('Failed to fetch user token limits', error);
    }
  };


  const handleTeamChange = async (event) => {
    const team = event.target.value;
    setSelectedTeam(team);

    const users = await fetchTeamUsers(team);
    if (users) {
      await fetchUserTokenLimits(users)
    }
    await fetchTeamTokenLimit(team);
    setIsLoading(false);
  };

  const handleTokenLimitChange = (id, newLimit) => {
    if (newLimit === '') {
      // Allow deletion of the input
      setUserTokenLimits((prevLimits) => ({
        ...prevLimits,
        [id]: '',
      }));
      return;
    }
  
    const numericValue = parseInt(newLimit, 10);
  
    if (isNaN(numericValue)) {
      toast.error({
        title: "Invalid input",
        description: "Please enter a valid integer for the token limit.",
      });
      return;
    }
  
    setUserTokenLimits((prevLimits) => ({
      ...prevLimits,
      [id]: numericValue,
    }));
  };

  const updateUserTokenLimit = async (user_id, tokenLimit) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        user_id: user_id,
        token_limit: tokenLimit,
      };
      await sendRequest(sendDetails, ENDPOINTS["update_user_token_limit"]);
    } catch (error) {
      console.error('Failed to update token limit', error);
    }
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined) {
      return "unset";
    }
    return num.toLocaleString();
  };

  const calculateProportion = (userLimit, teamLimit) => {
    if (!teamLimit || teamLimit === 0) return "N/A";
    const proportion = (userLimit / teamLimit) * 100;
    return `${proportion.toFixed(2)}%`;
  };

  return (
    <Modal open onClose={onClose}>
      <>
        {!isOk ? (
          <Box className="TeamManagerLoadingIndicator">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="TeamManagerContainer">
            <div className="bg-primary text-white p-3 rounded-md flex justify-center">
              <Typography variant="h6">Manage Team</Typography>
            </div>
            <div className="w-full flex">
              <Tabs value={activeTab} onChange={handleTabChange} className="w-full">
                <Tab label="Manage Users" className="flex-1" />
                <Tab label="Manage Tokens" className="flex-1" />
              </Tabs>
            </div>
            {activeTab === 0 && (
              <Box>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Teams</TableCell>
                        {users.teams.map((team) => (
                          <TableCell key={team}>{team}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {snapshot.map(({ email, team, catalog_team = "" }) => (
                        <TableRow key={email}>
                          <TableCell>{email}</TableCell>
                          <TableCell>
                            <Select
                              value={catalog_team}
                              onChange={(e) => {
                                if (e.target.value === "Add new team") {
                                  const newTeam = prompt("Enter new team name");
                                  if (newTeam) {
                                    updateSnapshot(email, "catalog_team", newTeam);
                                    setCatalogTeams((old) => [...old, newTeam]);
                                  }
                                } else {
                                  updateSnapshot(email, "catalog_team", e.target.value);
                                }
                              }}
                            >
                              {catalogTeams.map((catalogTeam) => (
                                <MenuItem value={catalogTeam} key={catalogTeam}>
                                  {catalogTeam}
                                </MenuItem>
                              ))}
                              <ListSubheader>---</ListSubheader>
                              <MenuItem value={null}>No team</MenuItem>
                              <MenuItem value="Add new team">Add new team</MenuItem>
                            </Select>
                          </TableCell>
                          {users.teams.map((t) => (
                            <TableCell key={t}>
                              <Radio
                                onClick={() => updateSnapshot(email, "team", t)}
                                checked={team === t}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="TeamManagerFooter">
                  <Button type="reset" variant="outlined" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" onClick={submit}>
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
            {activeTab === 1 && (
              <Box>
                <Select
                  value={selectedTeam}
                  onChange={(e) => {
                    setIsLoading(true);
                    const team = e.target.value;
                    setSelectedTeam(team);
                    // fetchTeamUsers(team);
                    handleTeamChange(e);
                  }}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                >
                  <MenuItem value="" disabled>Select a team</MenuItem>
                  {catalogTeams.map((team) => (
                    <MenuItem key={team} value={team}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
                {teamUsers.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        marginBottom: 1,
                        padding: 1.5,
                        backgroundColor: '#f3f3f3',
                        borderRadius: '3px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        textAlign: 'center',
                        color: '#2e3b55'
                      }}
                    >
                      You have <strong>{formatNumber(teamTokenLimit)}</strong> tokens in total available for the team.
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>
                              Token Limit
                              <Tooltip
                                title={<Typography sx={{ fontSize: '0.9rem' }}>You can enter <strong>integers</strong> as the fixed amount of tokens this user can use</Typography>}
                                PopperProps={{
                                  sx: {
                                    '& .MuiTooltip-tooltip': {
                                      fontSize: '0.9rem',
                                    },
                                  },
                                }}
                              >
                                  <IconButton size="small" sx={{ marginLeft: 1 }}>
                                    <InfoOutlined fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell>Proportion</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {teamUsers.map((user) => (
                               <TableRow key={user.id}>
                               <TableCell>{user.email}</TableCell>
                               <TableCell>
                                 <NumericFormat
                                   value={userTokenLimits[user.id] !== undefined ? userTokenLimits[user.id] : ''}
                                   onValueChange={(values) => handleTokenLimitChange(user.id, values.value)}
                                   thousandSeparator=","
                                   allowNegative={false}
                                   variant="outlined"
                                   fullWidth
                                   customInput={TextField} // Use TextField for styling
                                 />
                               </TableCell>
                               <TableCell>
                                 {calculateProportion(userTokenLimits[user.id], teamTokenLimit)}
                               </TableCell>
                             </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    <Alert severity="info" sx={{ marginBottom: 2 }}>
                      <strong>"unset"</strong> token limit indicates user doesn't have a fixed token limit, and will share team's token pool.
                      The token usage will be calculated <strong>per month</strong>.
                    </Alert>
                    <Box className="TeamManagerFooter">
                      <Button type="reset" variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" onClick={submit}>
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              </Box>

            )}
          </Box>
        )}
      </>
    </Modal>
  );
};
