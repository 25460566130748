import { useEffect, useState } from "react";
import { toast } from "./managers";

const toastNotificationClassNameMapping = {
  success: {
    body: "bg-deasieTurquoise border-primary text-white",
    close: "text-white",
  },
  error: {
    body: "bg-red-100 border-l-red-500",
    close: "text-red-600",
  },
  warning: {
    body: "bg-orange-100 border-l-orange-500",
    close: "text-orange-600",
  },
  info: {
    body: "bg-blue-100 border-l-blue-500",
    close: "text-blue-600",
  },
};

const Toast = ({ notification, onClose }) => {
  const [shouldUnmount, setShouldUnmount] = useState(undefined);
  useEffect(() => {
    const fadeInTimeout = setTimeout(() => {
      setShouldUnmount(false);
    }, 0); // fade in animation requires an action to be sent from next iteration of the event loop

    const autoUnmountTimeout = setTimeout(() => {
      setShouldUnmount(true);
    }, toast.timeout);

    return () => {
      clearTimeout(fadeInTimeout);
      clearTimeout(autoUnmountTimeout);
    };
  }, [notification]);

  useEffect(() => {
    if (shouldUnmount !== true) return;
    const closeTimeout = setTimeout(() => {
      onClose(notification);
    }, 500); // fade out duration

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [notification, onClose, shouldUnmount]);

  return (
    <div
      className={`p-4 select-none relative text-gray-600 rounded-md shadow border-l-8 shadow-gray-300 border w-full duration-500 transition-opacity ${
        shouldUnmount === false ? "opacity-100" : "opacity-0"
      } ${toastNotificationClassNameMapping[notification.level].body}`}
    >
      <div
        className={`${
          toastNotificationClassNameMapping[notification.level].close
        } cursor-pointer font-medium absolute right-2 flex items-center justify-center top-2 w-6 h-6 rounded-full`}
        onClick={() => setShouldUnmount(true)}
      >
        <div>✖</div>
      </div>
      <div className="font-medium">{notification.title}</div>
      <div className="break-words">{notification.description}</div>
    </div>
  );
};

export default Toast;
