import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Select, MenuItem } from "@mui/material";
import Auth from "../../../../../auth/AuthProvider";
import { DataContext } from "../../../../../context/DataContext";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import InfoIcon from "../../../../utilities/InfoIcon/InfoIcon";
import {
  useCatalogNames,
  useFolderEntriesAvailable,
} from "../../../../../api/queryHooks";
import { TagContext } from "../../../../../context/TagContext";
import { NonTaskProgressBar } from "../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import { toast } from "../../../../utilities/Toast";
import { uploadTags } from "../../../../utilities/functions/apiCalls";
import { CircularProgress } from "@mui/material";
import { customStyleForSelect } from "../../../../utilities/SearchBar/SearchBar";
import { v4 as uuidv4 } from "uuid";

const FILTERMAPPING = {
  "Defined Values": "custom",
  "Yes No": "yesNo",
  "Open ended tags": "aiGenerated",
};

const OUTPUTTYPES = ["word", "number", "date"];

const ContextModal = ({ isOpen, onClose, onSubmit }) => {
  const [input, setInput] = useState("");

  if (!isOpen) return null;

  const handleSubmit = (context) => {
    onSubmit(context.trim());
    setInput("");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl mb-2">
          <strong>Narrow auto-suggestion domain</strong> (optional)
        </h2>
        <p className="text-base text-gray-600 mb-4">
          Provide a few words of context to focus tag suggestion on a certain
          domain
        </p>
        <div>
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="border p-2 w-full mb-4"
            placeholder="Enter context (optional)"
            rows="3"
          />
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button
              type="button"
              className="mr-2 px-4 py-2 bg-primary text-white rounded"
              onClick={() => handleSubmit(input)}
            >
              Generate tags
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AutoCreateTag() {
  const { data: folderEntryByStorageKind, isLoadingFolderEntires } =
    useFolderEntriesAvailable();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [outputTypeFilter, setOutputTypeFilter] = useState("");
  const [selectedTags, setSelectedTags] = useState(new Set());
  const [selectAllTags, setSelectAllTags] = useState(false);
  const [taskID, setTaskID] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: catalogNames = [] } = useCatalogNames();

  const {
    preferences,
    usedCatalog,
    setAvailableTags,
    catalogFiles,
    handleCatalogChange,
    autoCreateProgress,
    setAutoCreateProgress,
    isAutoCreating,
    setIsAutoCreating,
  } = useContext(DataContext);

  const {
    autoCreatedTags,
    setAutoCreatedTags,
    evaluatedScores,
    setEvaluatedScores,
  } = useContext(TagContext);

  const selectedCatalog = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  const availableStorageKinds = useMemo(
    () =>
      Object.keys(folderEntryByStorageKind ?? {}).filter(
        (storageKind) =>
          Object.keys(folderEntryByStorageKind[storageKind]).length > 0,
      ),
    [folderEntryByStorageKind],
  );

  const [selectedStorageKind, setSelectedStorageKind] = useState(
    () => availableStorageKinds[0] || "",
  );

  const folders = useMemo(() => {
    const folderStructure = {};
    Object.keys(catalogFiles).forEach((file) => {
      const directory = catalogFiles[file].file_directory[0];
      if (!folderStructure[directory]) {
        folderStructure[directory] = [];
      }
      folderStructure[directory].push(file);
    });
    return folderStructure;
  }, [catalogFiles]);

  // Get filtered folder keys based on search text
  const filteredFolderKeys = useMemo(() => {
    return Object.keys(folders).filter(
      (folderKey) =>
        folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
        folders[folderKey].some((file) =>
          file.toLowerCase().includes(searchText.toLowerCase()),
        ),
    );
  }, [folders, searchText]);

  const handleModalSubmit = (context) => {
    setIsModalOpen(false);
    const finalContext = context || "proceed_without_context";
    handleGenerateTags(finalContext);
  };

  const getUniqueTagId = (tag) => `${tag.name}-${tag.option}`;

  const selectedFolderEntry = useMemo(
    () => folderEntryByStorageKind?.[selectedStorageKind] ?? {},
    [folderEntryByStorageKind, selectedStorageKind],
  );

  const filteredTags = useMemo(() => {
    return autoCreatedTags.filter(
      (tag) =>
        (filterOption === "" || tag.option === FILTERMAPPING[filterOption]) &&
        (outputTypeFilter === "" || tag.output_type === outputTypeFilter),
    );
  }, [autoCreatedTags, filterOption, outputTypeFilter]);

  const selectAll = () => {
    setSelectAllTags((prevState) => !prevState);
    const currentState = !selectAllTags;
    if (currentState) {
      const allTags = new Set(
        autoCreatedTags.map((tag) => getUniqueTagId(tag)),
      );
      setSelectedTags(allTags);
    } else {
      setSelectedTags(new Set());
    }
  };

  const toggleTag = (tag) => {
    if (!tag) return;
    const tagId = getUniqueTagId(tag);
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (newSelectedTags.has(tagId)) {
        newSelectedTags.delete(tagId);
      } else {
        newSelectedTags.add(tagId);
      }
      return newSelectedTags;
    });
  };

  useEffect(() => {
    if (!isAutoCreating) return;

    const checkProgress = () => {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;
      let preparedItems = 0;

      selectedFiles.forEach((file) => {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }
      });

      setAutoCreateProgress(Math.round((preparedItems / totalItems) * 90));
    };

    const interval = setInterval(checkProgress, 1000);

    return () => clearInterval(interval);
  }, [isAutoCreating, catalogFiles]);

  const PreviewModal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close-button hover:bg-primary m-3"
          >
            &times;
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    );
  };

  const handleGenerateTags = async (context) => {
    setAutoCreatedTags([]);
    setEvaluatedScores({});
    setIsAutoCreating(true);
    setAutoCreateProgress(0);

    const isNoContext = context === "proceed_without_context";

    try {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;

      let preparedItems = 0;
      const updateProgress = (increment) => {
        setAutoCreateProgress((prevProgress) => prevProgress + increment);
      };

      for (const file of selectedFiles) {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }
      }
      updateProgress(Math.round(preparedItems / totalItems));

      const taskRequestParams = {
        file_names: selectedFiles,
        tag_context: isNoContext ? "" : context,
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: usedCatalog,
      };

      const autoCreateTagTaskResponse = await sendRequest(
        taskRequestParams,
        ENDPOINTS["auto_create_tags"],
      );

      const createdTagsTask = await autoCreateTagTaskResponse.json();
      const taskID = createdTagsTask.task_id;
      setTaskID(taskID);

      const resultRequestParams = {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        task_id: taskID,
      };
      const autoCreateTagResultResponse = await sendRequest(
        resultRequestParams,
        ENDPOINTS["auto_create_tags_result"],
      );
      const createdTags = await autoCreateTagResultResponse.json();
      const autoTags = createdTags.auto_created_tags;

      setTaskID("");
      setAutoCreatedTags(autoTags);
      setAutoCreateProgress(100);
      setIsAutoCreating(false);
      toast.success({
        title: "Success",
        description: `${Object.keys(autoTags).length} tags have been successfully generated`,
      });
    } catch (error) {
      console.log("Error during the request", error);
      setIsAutoCreating(false);
    }
  };

  useEffect(() => {
    if (
      selectedStorageKind ||
      isLoadingFolderEntires ||
      !availableStorageKinds
    ) {
      return;
    }

    setSelectedStorageKind(availableStorageKinds[0]);
  }, [availableStorageKinds, isLoadingFolderEntires, selectedStorageKind]);

  const sortTags = (tags) => {
    if (sortOption === "score") {
      return tags.sort((a, b) => {
        const aScore = evaluatedScores[a.name] || 0;
        const bScore = evaluatedScores[b.name] || 0;
        return bScore - aScore;
      });
    } else if (sortOption === "name") {
      return tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    return tags;
  };

  const closePreviewModal = () => {
    setShowFilePreview(false);
  };

  const updateTagVersion = async (tag) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        UUID: tag.UUID,
        tag_name: tag.name,
        catalog_name: usedCatalog,
        modified_attributes: JSON.stringify([]),
        new_values: JSON.stringify([]),
        old_values: JSON.stringify([]),
        updated_time: new Date().toISOString(),
        tag_details: tag,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
      };
      await sendRequest(sendDetails, ENDPOINTS["update_tag_version"]);
    } catch (error) {
      console.error("Error updating tag:", error);
      toast.error({
        title: "Error",
        description: "An error occurred while updating the tag",
      });
    }
  };

  const handleConfirmSelection = async () => {
    let countNewTags = 0;
    let updatedTags = {};
    const tagsToUpdate = [];

    const confirmSelection = window.confirm(
      `Are you sure you want to add ${selectedTags.size} tags?`,
    );
    if (!confirmSelection) {
      return;
    }

    setAvailableTags((prevAvailableTags) => {
      const updatedTagDict = {
        ...prevAvailableTags.llm.tagger_params.tag_dict,
      };
      const startingNumberAvaibleTags = Object.keys(updatedTagDict).length;
      selectedTags.forEach((tagId) => {
        const tagDetails = autoCreatedTags.find(
          (tag) => getUniqueTagId(tag) === tagId,
        );
        if (tagDetails && !tagDetails.name.includes(",")) {
          tagDetails.UUID = uuidv4();
          updatedTagDict[tagDetails.name] = { ...tagDetails };
          tagsToUpdate.push(tagDetails);
        } else if (tagDetails && tagDetails.name.includes(",")) {
          toast.warning({
            title: "Tag name contains invalid character",
            description: `Tag name '${tagDetails.name}' contains invalid character ',' and will not be added to the tag library`,
          });
        }
      });
      countNewTags =
        Object.keys(updatedTagDict).length - startingNumberAvaibleTags;

      updatedTags = {
        ...prevAvailableTags,
        llm: {
          ...prevAvailableTags.llm,
          tagger_params: {
            ...prevAvailableTags.llm.tagger_params,
            tag_dict: updatedTagDict,
          },
        },
      };

      return updatedTags;
    });

    for (const tag of tagsToUpdate) {
      await updateTagVersion(tag);
    }

    if (countNewTags > 0) {
      try {
        await uploadTags(updatedTags, usedCatalog);
        toast.success({
          title: "Success",
          description: `Added ${countNewTags} tag${countNewTags > 1 ? `s` : ""}`,
        });
      } catch (error) {
        console.error(`Error uploading tags: ${error.message}`);
        toast.error({
          title: "Error",
          description: `Error uploading tags: ${error.message}`,
        });
      }
    } else {
      alert(`Tag(s) already in library`);
    }
  };

  useEffect(() => {
    if (availableStorageKinds.length > 0 && !selectedStorageKind) {
      setSelectedStorageKind(availableStorageKinds[0]);
    }
  }, [availableStorageKinds, selectedStorageKind]);

  const abortAutoCreateTags = useCallback(async () => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      await sendRequest(
        {
          task_id: taskID,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
        },
        ENDPOINTS.revoke_task,
      );
    } catch (error) {
      console.error("Error aborting the task: ", taskID, "error: ", error);
      toast.error({
        title: "Error",
        description: "Failed to stop the auto create tag process.",
      });
      return;
    }
    setIsAutoCreating(false);
    toast.info({
      title: "Auto Create Tag Stopped",
      description:
        "The auto creating tag process has been successfully stopped.",
    });
  });

  return (
    <div className="flex bg-white h-full w-full justify-between flex-row p-5 rounded-md gap-5">
      <div className="flex flex-col w-2/5 gap-2 relative">
        <header className="p-1 text-gray-700 font-bold text-lg">
          Select data from "{usedCatalog}" to generate new tags{" "}
          <InfoIcon
            infoText="Deasie is distilling your datasets to create meaningful tags. Select one or more datasets to auto-create Deasie tags.
          If data is prepared, you can generate Deasie tags at a lower latency."
          />
        </header>
        <div className="flex justify-between w-full">
          {isLoadingFolderEntires ? (
            <CircularProgress size={24} />
          ) : (
            <div className="flex flex-row gap-3">
              <Select
                className="h-10"
                onChange={handleCatalogChange}
                value={selectedCatalog}
                style={customStyleForSelect}
              >
                <MenuItem value="" disabled>
                  Select a catalog
                </MenuItem>
                {catalogNames.map((catalog, index) => (
                  <MenuItem key={index} value={catalog}>
                    {catalog}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div className="w-full flex justify-center items-center overflow-y-auto h-full">
          {isLoadingFolderEntires ? (
            <CircularProgress />
          ) : (
            <FolderList
              checkedItems={checkedItems}
              currentFolder={currentFolder}
              filteredFolderKeys={filteredFolderKeys}
              folders={folders}
              integration={usedCatalog}
              searchText={searchText}
              setCheckedItems={setCheckedItems}
              setCurrentFolder={setCurrentFolder}
              setSearchText={setSearchText}
              mode="autoCreation"
            />
          )}
        </div>
        <PreviewModal
          isOpen={showFilePreview}
          onClose={closePreviewModal}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: filePreviewContent,
              }}
            />
          }
        />
        <div className="absolute bottom-5 left-0 flex flex-col items-start space-y-2 p-2 w-full z-100">
          <button
            onClick={() => setIsModalOpen(true)}
            className={`${
              autoCreatedTags && Object.keys(autoCreatedTags).length !== 0
                ? "text-primary border-2 border-primary bg-white hover:font-bold"
                : "text-white"
            } p-3 w-[50%] flex flex-col rounded-md items-center justify-center text-xl transition-all duration-300 ease-in-out ${
              Object.keys(checkedItems).length !== 0
                ? "bg-primary hover:font-bold"
                : "bg-slate-300"
            } ${isAutoCreating ? "hidden" : "visible"}`}
            disabled={Object.keys(checkedItems).length === 0 || isAutoCreating}
          >
            {Object.keys(checkedItems).length === 0 ? (
              "Select one or more datasets"
            ) : isAutoCreating ? (
              <p>...Loading</p>
            ) : (
              "Generate Tags"
            )}
          </button>
          {isAutoCreating && (
            <button
              className="py-3 px-6 flex flex-row items-center justify-center text-lg"
              onClick={abortAutoCreateTags}
              disabled={taskID === ""}
            >
              <p className="text-md bg-red-400 text-white p-2 rounded-md font-bold">
                Abort All
              </p>
            </button>
          )}
        </div>
      </div>
      <ContextModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(context) => handleModalSubmit(context)}
      />
      <div className="flex flex-col w-3/5 overflow-y-hidden">
        <div className="flex flex-row w-full justify-between items-center">
          <header className=" text-gray-700 font-bold text-lg">
            Auto-generated tags{" "}
            <InfoIcon
              infoText={
                autoCreatedTags && Object.keys(autoCreatedTags).length > 0
                  ? "Choose tags to be added to your tag library"
                  : "Please choose datasets to generate your custom tags"
              }
            />
          </header>

          {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
            <button
              onClick={selectAll}
              className="font-bold text-md text-primary border-2 border-primary text-gray-800 px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              {selectAllTags ? "Deselect All" : "Select All"}
            </button>
          )}
        </div>

        {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
          <div className="flex flex-row gap-4 items-center mb-4">
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Filter by Option:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setFilterOption(e.target.value)}
                value={filterOption}
              >
                <option value="">All</option>
                {Object.keys(FILTERMAPPING).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">
                Filter by Output Type:
              </label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setOutputTypeFilter(e.target.value)}
                value={outputTypeFilter}
              >
                <option value="">All</option>
                {OUTPUTTYPES.map((type) => (
                  <option key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Sort by:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setSortOption(e.target.value)}
                value={sortOption}
              >
                <option value="">None</option>
                <option value="name">Tag Name</option>
              </select>
            </div>
          </div>
        )}

        <div className="flex flex-col overflow-y-auto w-full no-scrollbar">
          {!isAutoCreating &&
            sortTags(filteredTags).map((tag, index) => (
              <div
                key={`${tag.name}-${tag.option}`}
                className={`transition hide-scrollbar duration-500 ease-in-out p-10 rounded-lg shadow-md hover:shadow-lg mb-4 cursor-pointer ${
                  selectedTags.has(`${tag.name}-${tag.option}`)
                    ? "border-primary border-4"
                    : "bg-gray-50 text-gray-800 hover:bg-gray-100"
                }`}
                onClick={() => toggleTag(tag)}
              >
                <div className="flex justify-between items-center mb-4 w-full">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {tag.name}
                    </h3>
                    <p className="text-gray-600">{tag.description}</p>
                  </div>
                  <span
                    title="This is the output type of the tag"
                    className={`text-sm font-medium px-2 py-1 rounded ${
                      tag.output_type === "word"
                        ? "bg-deasieTurquoise text-white"
                        : tag.output_type === "number"
                          ? "bg-deasieBlack text-white"
                          : "bg-yellow-200 text-yellow-800"
                    }`}
                  >
                    {tag.output_type.charAt(0).toUpperCase() +
                      tag.output_type.slice(1)}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between whitespace-nowrap">
                  {tag.option !== "aiGenerated" && (
                    <div className="mt-2">
                      <h4 className="text-sm font-medium text-gray-700">
                        Available Values:
                      </h4>
                      <ul className="list-disc list-inside">
                        {tag.availableValues.map((value, valueIndex) => (
                          <li key={valueIndex} className="text-gray-600">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        {filteredTags && filteredTags.length > 0 && !isAutoCreating ? (
          <div className="flex flex-row w-full justify-end pb-8 pt-2">
            <button
              onClick={handleConfirmSelection}
              className={`flex flex-col text-white border-2 border-primary bg-primary px-4 py-2 rounded-md text-xl hover:bg-primary-dark transition-all duration-300 ease-in-out ${
                selectedTags.size === 0 && "opacity-50 cursor-not-allowed"
              }`}
              disabled={selectedTags.size === 0}
            >
              Add Selected Tags ({selectedTags.size})
            </button>
          </div>
        ) : (
          <>
            <div className="w-full h-full flex flex-col justify-center items-center">
              {isAutoCreating && (
                <NonTaskProgressBar progress={autoCreateProgress} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
