import { ENDPOINTS } from "../../../api/endpoints";
import Auth from "../../../auth/AuthProvider";
import { sendRequest } from "../functions/api";
import { DataContext } from "../../../context/DataContext";
import { useContext, useState } from "react";
import { toast } from "../Toast";


export default function TaskTrackingDashboardRow({task, isActive=false, isScheduled=false}) {
    const [abortingInProgress, setAbortingInProgress] = useState(false);
    const {
        preferences,
        abortedTasks,
        setAbortedTasks,
    } = useContext(DataContext);

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleString();
    }
    if (abortedTasks.includes(task.id)) {
        return <></>;
    }
    
    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap">
                {task.email}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {task.taskName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {isActive ? 
                'Executing' : 
                isScheduled ? `Scheduled for ${formatDate(task.scheduledTime)}` : 'Queued'
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
            {abortingInProgress ? (
                <div className="items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-red-500" />
                </div>
                ) : (
                <button
                    onClick={async () => {
                        const creds = (await Auth.currentAuthenticatedUser()).username;
                        try {
                            setAbortingInProgress(true);
                            const response = await sendRequest({
                                task_id: task.id,
                                [preferences.system.API_USERNAME_KEYWORD]: creds,
                            }, ENDPOINTS["revoke_task"]);
                            if (response.status === 200) {
                                setAbortedTasks([...abortedTasks, task.id]);
                                toast.success({
                                    title: "Task aborted successfully"
                                });
                            }
                        } catch (error) {
                            console.error("Error aborting the task:", error);
                        } finally {
                            setAbortingInProgress(false);
                        }
                    }}
                    className="bg-red-500 rounded-lg hover:bg-red-700 text-white font-bold p-2 m-2">
                    Abort
                </button>
                )}
            </td>
        </tr>
    )
}