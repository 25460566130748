import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./TagRuleFilter.css";
import { TagRule } from "../../../../../../../api/types";

type TagRuleFilterProps = {
  rules: TagRule[];
  onClose: () => void;
  onSubmit: (selectedRules: string[]) => void;
};

export function TagRuleFilter({
  rules,
  onClose,
  onSubmit,
}: TagRuleFilterProps) {
  const rulesByTagName = rules.reduce(
    (acc, rule) => {
      const existing = acc[rule.tagName] ?? [];
      acc[rule.tagName] = existing.concat(rule.children);
      return acc;
    },
    {} as Record<string, TagRule[]>,
  );
  const availableTags = new Set(Object.keys(rulesByTagName));
  const [selectedRules, setSelectedRules] = useState<Set<string>>(
    new Set(Object.keys(rulesByTagName)),
  );

  const handleSelectRule = (tagName: string, isChecked: boolean) => {
    setSelectedRules((prev) => {
      if (isChecked) {
        return new Set([...prev, tagName]);
      } else {
        return new Set([...prev].filter((rule) => rule !== tagName));
      }
    });
  };
  const allSelected = selectedRules.size === availableTags.size;

  const selectAll = () => setSelectedRules(new Set([...availableTags]));

  const deselectAll = () => setSelectedRules(new Set());

  return (
    <Modal open onClose={onClose}>
      <Box className="TagRuleFilterContainer">
        <Typography variant="h4" className="text-left">
          {selectedRules.size} tagging rule{selectedRules.size < 2 ? "" : "s"}{" "}
          triggered
        </Typography>
        <TableContainer className="flex flex-col justify-between">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Tags triggered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(rulesByTagName).map(([tagName, rules]) => (
                <TableRow key={tagName}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRules.has(tagName)}
                      onChange={(e) =>
                        handleSelectRule(tagName, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{tagName}</TableCell>
                  <TableCell>{rules.length}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="TagRuleFilterFooter">
          {allSelected && (
            <Button type="button" onClick={deselectAll}>
              Deselect all
            </Button>
          )}
          {!allSelected && (
            <Button type="button" onClick={selectAll}>
              Select all
            </Button>
          )}
          <Box className="flex gap-2">
            <Button type="button" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => onSubmit([...selectedRules])}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
