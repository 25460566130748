import { useContext, useEffect } from "react";
import "./Export.css";
import { sendRequest } from "../../../functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import Auth from "../../../../../auth/AuthProvider";
import { useCatalogNames } from "../../../../../api/queryHooks";
import { usePersistedState } from "../../../../../pages/hooks";
import { DataContext } from "../../../../../context/DataContext";

export default function Export() {
  const { data: catalogNames = [] } = useCatalogNames();
  const { usedCatalog } = useContext(DataContext);
  const [outputFormat, setOutputFormat] = usePersistedState("outputFormat", "");
  const [outputCatalog, setOutputCatalog] = usePersistedState(
    "outputCatalog",
    "",
  );

  useEffect(() => {
    if (!outputCatalog) {
      setOutputCatalog(usedCatalog);
    }
  }, [usedCatalog, outputCatalog, setOutputCatalog]);

  const handleOptionChange = (option) => {
    setOutputCatalog(option);
  };

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const handleDownload = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      output_format: outputFormat,
      catalog_name: outputCatalog,
      [API_USERNAME_KEYWORD]: creds,
    };

    const response = await sendRequest(sendDetails, ENDPOINTS["export"]);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${outputCatalog}.${outputFormat}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleConfirm = async () => {
    if (!outputFormat || !catalogNames.includes(outputCatalog)) {
      window.confirm(
        "Please select an output format and a valid export catalog.",
      );
    } else {
      await handleDownload();
    }
  };

  return (
    <div className="flex w-[30vw] h-[40vh] flex-col bg-white p-6 rounded-lg shadow-md max-w-md mx-auto gap-4">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Select Export Options
      </h3>

      <div className="mb-6">
        <label
          htmlFor="catalog-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Catalog
        </label>
        <div className="relative">
          <select
            id="catalog-select"
            value={outputCatalog}
            onChange={(event) => handleOptionChange(event.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md"
          >
            <option value="">Select Catalog</option>
            {catalogNames.map((catalog, index) => (
              <option key={index} value={catalog}>
                {catalog}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-6">
        <label
          htmlFor="format-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Output Format
        </label>
        <div className="relative">
          <select
            id="format-select"
            value={outputFormat}
            onChange={handleFormatChange}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md"
          >
            <option value="">Select Format</option>
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
          </select>
        </div>
      </div>

      <button
        onClick={handleConfirm}
        className="w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-75"
      >
        Export
      </button>
    </div>
  );
}
