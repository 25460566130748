import { FC, ReactElement, ReactNode } from "react";
import {
  PermissionFlags,
  Permissions,
  useUserProfile,
} from "../../../context/UserProfile";

type Props = {
  children: ReactNode;
  scope: keyof Permissions;
  level: keyof PermissionFlags;
  fallback?: ReactNode;
};

export const PermissionGuard: FC<Props> = ({
  children,
  scope,
  level,
  fallback,
}) => {
  const { permissions } = useUserProfile();

  if (!permissions[scope][level]) {
    return fallback as ReactElement<any, any> | null;
  }

  return children as ReactElement<any, any> | null;
};
