import { DataContext } from "../context/DataContext";
import { useCallback, useContext } from "react";
import { useAtom } from "jotai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { PermissionGuard } from "../components/utilities/PermissionGuard";
import { pdfAtom, pdfSearchAtom, selectedCatalogItemsAtom } from "../atoms";
import { toast } from "../components/utilities/Toast";
import { FaList } from "react-icons/fa6";
import { RiErrorWarningLine } from "react-icons/ri";
import { DocumentVersionIcon } from "../components/utilities/SearchBar/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const stringToColor = (stringValue, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

const CatalogTableRow = ({
  docKey,
  tags,
  index,
  actionsFile,
  expandedFiles,
  setExpandedFiles,
  expandedVersionFiles,
  setExpandedVersionFiles,
  availableTags,
  setActionsFile,
  loadDocumentInfo,
  onEvidenceClick,
  isVersion = false,
  haveVersions = false,
  isScanned = true,
}) => {
  const {
    hiddenCategories,
    ruleDict,
    handleDatasetDelete,
    currentDataGroup,
    setShowFileOnPage,
    isGloballyExpanded,
    isVersionGloballyExpanded,
    versioningEnabled,
    catalogFiles,
  } = useContext(DataContext);

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );
  const isExpanded = isGloballyExpanded || expandedFiles.has(docKey);
  const isVersionExpanded =
    isVersionGloballyExpanded || expandedVersionFiles.has(docKey);
  const isActionsOpened = docKey === actionsFile;

  const downloadFile = useCallback(
    async (docKey) => {
      if (!currentDataGroup) return;
      toast.info({
        title: "Downloading file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(docKey);

      window.open(documentInfo.file_url, "_blank");
      setActionsFile("");
    },
    [currentDataGroup, loadDocumentInfo],
  );

  const openFile = useCallback(
    async (docKey) => {
      if (!currentDataGroup) return;

      toast.info({
        title: "Opening file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(docKey);
      if (
        documentInfo.file_name?.toLowerCase().endsWith(".pdf") ||
        documentInfo.file_name?.toLowerCase().endsWith(".docx")
      ) {
        setShowFileOnPage("standard");
        setPdf(documentInfo.file_url.toString());
      } else {
        window.open(documentInfo.file_url, "_blank");
        setActionsFile("");
      }
    },
    [currentDataGroup, setPdf, setShowFileOnPage, loadDocumentInfo],
  );

  return (
    <tr
      className={`border-b ${isVersion ? "bg-gray-100" : "bg-white"}`}
      data-testid="catalogDocumentEntry"
      key={`${docKey}-${index}`}
    >
      <td className="w-1">
        <div className="justify-center items-center flex">
          <input
            type="checkbox"
            data-testid="documentEntrySelectCheckbox"
            className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
            checked={selectedCatalogItems.has(docKey)}
            onChange={(e) => {
              if (e.target.checked) {
                selectedCatalogItems.add(docKey);
              } else {
                selectedCatalogItems.delete(docKey);
              }
              setSelectedCatalogItems(new Set(selectedCatalogItems));
            }}
          />
        </div>
      </td>
      <td>
        <PermissionGuard scope="catalogs" level="canEdit">
          <div
            className="cursor-pointer  z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
            onClick={() => {
              setActionsFile("");
              handleDatasetDelete(docKey);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </PermissionGuard>
      </td>
      <td className="px-6 py-4 text-sm break-all text-gray-700">
        <div className="flex items-center gap-1">
          <div className="w-60">{docKey}</div>
        </div>
      </td>

      <td className="px-6 py-4 flex gap-1">
        <div
          className={`flex gap-1 transition-all flex-wrap ${isExpanded ? "max-h-none overflow-auto" : "max-h-6 overflow-hidden"}`}
          data-testid={`tags-${docKey}`}
        >
          {tags &&
            Object.entries(tags)
              .filter(([key, value]) => {
                return (
                  key !== "chunks" &&
                  key !== "file_directory" &&
                  !hiddenCategories?.includes(key) &&
                  value &&
                  Array.isArray(value) &&
                  value[0] &&
                  key in availableTags.sensitivity.tagger_params.tag_dict ===
                    false &&
                  Object.keys(
                    {
                      ...availableTags.llm.tagger_params.tag_dict,
                      ...ruleDict,
                    } || {},
                  ).includes(key)
                );
              })
              .map(([tagKey, value], index) => {
                let processedValue;

                if (Array.isArray(value)) {
                  // value being passed here is an array with a single element
                  if (
                    typeof value[0] === "string" &&
                    value[0].startsWith("[") &&
                    value[0].endsWith("]")
                  ) {
                    try {
                      const correctedString = value[0]
                        .replace(/'/g, '"')
                        .replace(/(\w)"(\w)/g, "$1'$2"); // revert double quotes back to single quotes if they occur within words
                      processedValue = JSON.parse(correctedString);
                    } catch (error) {
                      console.error(
                        "Error parsing the array-like string:",
                        error,
                        "Original value:",
                        value[0],
                      );
                      processedValue = [value[0]];
                    }
                  } else {
                    processedValue = [value[0]];
                  }
                } else if (typeof value === "string") {
                  const trimmedValue = value.trim();
                  if (
                    trimmedValue.startsWith("[") &&
                    trimmedValue.endsWith("]")
                  ) {
                    try {
                      processedValue = JSON.parse(trimmedValue); // parse a list-like string
                    } catch (error) {
                      console.error("Error parsing value:", error);
                      processedValue = [trimmedValue]; // fallback to original string if parsing fails
                    }
                  }
                }

                return processedValue.map((val, idx) => (
                  <div
                    className="text-xs py-1 px-2 rounded-md"
                    key={`${docKey}-${tagKey}-${val}-${idx}`}
                    style={{
                      backgroundColor:
                        stringToColor(tagKey, "40") || "#80808028",
                      cursor:
                        docKey.includes(".pdf") || docKey.includes(".docx")
                          ? "pointer"
                          : "auto",
                    }}
                    onClick={async () => {
                      if (
                        !docKey?.toLowerCase().includes(".pdf") &&
                        !docKey.includes(".docx")
                      )
                        return;

                      if (!currentDataGroup) return;
                      const response = await loadDocumentInfo(docKey);

                      if (
                        response.file_name?.toLowerCase().endsWith(".pdf") ||
                        response.file_name?.toLowerCase().endsWith(".docx")
                      ) {
                        setPdf(response.file_url.toString());
                        setShowFileOnPage("standard");

                        const filteredChunks = Object.entries(
                          tags["chunks"],
                        ).filter(
                          ([_, chunk]) =>
                            chunk[tagKey] &&
                            chunk[tagKey].value[0] !== "Not found" &&
                            chunk[tagKey].value[0] !== "No",
                        );

                        const searchEvidence = filteredChunks.map(
                          ([_, chunk]) => chunk[tagKey].evidence,
                        );
                        const chunkIndexArray = filteredChunks.map(
                          ([chunkIndex]) => chunkIndex,
                        );

                        setPdfSearch({
                          evidence: searchEvidence,
                          chunk_index: chunkIndexArray,
                          page_count: tags.page_count || null,
                          total_size: catalogFiles[docKey]?.total_size,
                        });
                      } else {
                        window.open(response.file_url, "_blank");
                        setActionsFile("");
                      }
                    }}
                  >
                    <span
                      className="font-bold text-black"
                      data-testid="documentTag"
                    >
                      {tagKey}:
                    </span>{" "}
                    {val}
                  </div>
                ));
              })
              .flat()}
        </div>
      </td>
      <td scope="col" className="text-left px-6 py-3 top-0  z-40">
        <div className="flex items-center gap-1">
          <div
            className={`items-center text-xl h-6 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
            onClick={() => {
              if (isExpanded) {
                expandedFiles.delete(docKey);
              } else {
                expandedFiles.add(docKey);
              }
              setExpandedFiles(new Set(expandedFiles));
            }}
          >
            {isExpanded ? <FaMinimize /> : <FaExpandArrowsAlt />}
          </div>
        </div>
      </td>

      {versioningEnabled && (
        <td className="px-6 py-4">
          <div className="flex justify-center relative">
            {isVersion ? (
              <></>
            ) : !isScanned ? (
              <RiErrorWarningLine
                className="ml-5 text-red-400"
                title="This document has not been checked for its version"
              />
            ) : !haveVersions ? (
              <div
                className={`ml-5 items-center text-xl h-6 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                onClick={() => {
                  if (isVersionExpanded) {
                    expandedVersionFiles.delete(docKey);
                  } else {
                    expandedVersionFiles.add(docKey);
                  }
                  setExpandedVersionFiles(new Set(expandedVersionFiles));
                }}
                title="This document is the latest version"
              >
                <DocumentVersionIcon size={24} />
              </div>
            ) : (
              <div
                className={`ml-5 items-center text-xl h-6 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                onClick={() => {
                  if (isVersionExpanded) {
                    expandedVersionFiles.delete(docKey);
                  } else {
                    expandedVersionFiles.add(docKey);
                  }
                  setExpandedVersionFiles(new Set(expandedVersionFiles));
                }}
              >
                <FaList />
              </div>
            )}
          </div>
        </td>
      )}
      <td className="px-6 py-4">
        <div className="flex justify-center relative">
          <BsThreeDotsVertical
            className="cursor-pointer"
            onClick={() => {
              setActionsFile(isActionsOpened ? "" : docKey);
            }}
          />
          {isActionsOpened && (
            <div className="absolute z-30 select-none top-[calc(100%_+_8px)] w-40 bg-white border rounded-md shadow-md flex flex-col">
              <div
                className="fixed inset-0 z-20"
                onClick={() => setActionsFile("")}
              ></div>
              <div
                className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                onClick={() => openFile(docKey)}
              >
                Open
              </div>
              <div
                className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                onClick={() => downloadFile(docKey)}
              >
                Download
              </div>
            </div>
          )}
        </div>
      </td>

      <td className="px-6 py-4">
        <button
          className="px-4 py-2 text-primary border-2 border-primary  rounded-md whitespace-nowrap"
          onClick={() => onEvidenceClick(docKey)}
        >
          See evidence
        </button>
      </td>
    </tr>
  );
};

export default CatalogTableRow;
