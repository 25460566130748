import { useContext, useEffect, useMemo, useCallback } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./TagFilter.css";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import ProgressPie from "./PieChart";
import { CgSpinnerTwo } from "react-icons/cg";
import { selectedCatalogItemsAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { PermissionGuard } from "../PermissionGuard";

export default function TagFilter({
  label,
  categoryKey,
  isBeingDeleted,
  selectedTagKeys,
  setSelectedTagKeys,
  runSelectedTags,
  tagRules,
}) {
  const {
    deleteAllLabels,
    availableTags,
    currentDataGroup,
    hiddenCategories,
    toggleCategoryVisibility,
    ruleDict,
    clearLabels,
  } = useContext(DataContext);
  const {
    relatedInfo,
    setRelatedInfo,
    processingTags,
    runSingleRule,
    editTag,
  } = useContext(TagContext);

  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const isRuleTag = useCallback(
    (tagName) => tagRules?.some((rule) => rule.tagName === tagName),
    [tagRules],
  );

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  const completionPercentage = useMemo(() => {
    if (relatedInfo[categoryKey]) {
      return (
        (1 -
          relatedInfo[categoryKey].counter /
            Object.keys(currentDataGroup).length) *
        100
      );
    }
    return 0;
  }, [relatedInfo, categoryKey, currentDataGroup]);

  useEffect(() => {
    const calculateRelatedInfo = () => {
      let info = {};

      Object.entries(tagDict).forEach(([key, value]) => {
        let counter = 0;
        let name = value.name;
        let matchingNames = [];

        Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
          if (!groupValue.hasOwnProperty(name)) {
            counter++;
            matchingNames.push(groupKey);
          }
        });

        info[key] = { name, counter, matchingNames };
      });

      setRelatedInfo(info);
    };

    calculateRelatedInfo();
  }, [currentDataGroup, setRelatedInfo, tagDict]);

  useEffect(() => {
    if (completionPercentage === 100 && selectedTagKeys.includes(categoryKey)) {
      setSelectedTagKeys((prevSelectedTagKeys) =>
        prevSelectedTagKeys.filter((key) => key !== categoryKey),
      );
    }
  }, [completionPercentage, categoryKey]);

  return (
    <div
      className={`flex relative bg-white border-b py-4 px-4 dark:border-b-zinc-600 dark:bg-zinc-800 dark:text-white transition-all duration-300 ease-in-out ${
        isBeingDeleted ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div className="flex shrink-0 w-16 items-start justify-center">
        <ProgressPie percentage={completionPercentage} />
      </div>
      <div className="flex-1 w-full px-4">
        <div className="flex items-center justify-between">
          <div className="break-all font-medium text-sm">
            {label}
            {completionPercentage !== 100 &&
              processingTags.map(({ label }) => label).includes(label) && (
                <div className="inline-block ml-1 mr-2 animate-spin">
                  <CgSpinnerTwo />
                </div>
              )}
          </div>
          {isRuleTag(label) && (
            <div className="text-xs px-2 py-1 font-medium rounded-md text-white bg-primary ml-2">
              Tag rule attached
            </div>
          )}
        </div>
        <p className="py-2 text-sm">{tagDict[label]?.description}</p>
        <div className="flex flex-col gap-2">
          {tagDict[label]?.tagType === "rule" ? (
            <button
              onClick={() =>
                runSingleRule(currentDataGroup, tagDict[label].name)
              }
              className={`text-xs px-2 py-1 font-medium rounded-md text-white bg-primary transition-opacity duration-300 ${
                !relatedInfo?.[categoryKey]?.counter
                  ? "opacity-50"
                  : "opacity-100"
              }`}
            >
              Run rule on{" "}
              {selectedCatalogItems.size ||
                relatedInfo[categoryKey]?.counter ||
                0}{" "}
              documents
            </button>
          ) : (
            <div className="flex flex-row items-center w-full justify-end gap-3 mt-4">
              <PermissionGuard scope="tags" level="canEdit">
                <>
                  {(!tagDict[label].tagged_at ||
                    new Date(tagDict[label].tagged_at) <
                      new Date(tagDict[label].updated_at)) && (
                    <div
                      className="border-2 text-grey p-1 rounded-md hover:text-white hover:bg-grey duration-200"
                      title="This tag has been changed since last usage and hence can be rerun to see updated results."
                      onClick={() => runSelectedTags(true, label)}
                    >
                      Rerun
                    </div>
                  )}
                  <button
                    onClick={(e) => clearLabels(e, [label])}
                    className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
                    title="Clearing the tag will remove the values from all/selected documents without deleting the tag itself."
                  >
                    Clear
                  </button>
                  <button
                    className="p-2 bg-white text-buttonGrey hover:bg-primary hover:text-white rounded-md"
                    onClick={(e) => toggleCategoryVisibility(e, categoryKey)}
                    title={
                      hiddenCategories.includes(categoryKey)
                        ? "Unhide Category"
                        : "Hide Category"
                    }
                  >
                    {hiddenCategories.includes(categoryKey) ? (
                      <EyeOffIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                  <button
                    className="p-2 bg-white text-buttonGrey hover:bg-primary hover:text-white rounded-md"
                    onClick={(e) => editTag(e, categoryKey)}
                    title="Edit Tag"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    className="p-2 bg-white text-buttonGrey hover:bg-red-400 hover:text-white rounded-md"
                    title="Delete tag"
                    onClick={(e) => deleteAllLabels(e, [categoryKey])}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </>
              </PermissionGuard>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
