import { useContext, useMemo, useState, useEffect } from "react";
import {
  useCatalogNames,
  useCreateCatalogMutation,
} from "../../../api/queryHooks";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import Auth from "../../../auth/AuthProvider";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast } from "../Toast";
import useCatalogData from "../../pages/Home/HomeComponents/DataCatalog/useCatalogData";
import { ENDPOINTS } from "../../../api/endpoints";
import { sendRequest } from "../functions/api";
import {
  selectedCatalogItemsAtom,
  runningTasksAtom,
  documentTaggingTaskAtom,
} from "../../../atoms";
import { RxCross2 } from "react-icons/rx";
import { useAtom } from "jotai";
import DataGraph from "../../pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/DataGraph/DataGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FaChartBar, FaFilter, FaPencilAlt, FaTable } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { PermissionGuard } from "../PermissionGuard";
import { useCatalogContext } from "../../../context/CatalogContext";
import { TbZoomReset } from "react-icons/tb";
import { waitTaskDone } from "../../../utils/workers";
import Select, { components } from "react-select";
import { FaTrash } from "react-icons/fa";
import { deleteCatalog, getTagRules } from "../functions/apiCalls";
import { colors } from "../../../twExtend";

const styleForButton =
  "ml-4 px-4 py-2 text-center rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-300 ease-in-out text-primary border-2 border-primary font-bold whitespace-nowrap items-center flex gap-1";

export const DocumentVersionIcon = ({
  size = 24,
  color = "#000000",
  version = "1.0",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2V8H20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <text
        x="12"
        y="19"
        fontFamily="Arial"
        fontSize="6"
        fill={color}
        textAnchor="middle"
      >{`v${version}`}</text>
    </svg>
  );
};

export const customStyleForSelect = {
  control: (provided) => ({
    ...provided,
    borderWidth: "2px",
    "&:hover": {
      borderColor: colors.primary,
    },
    zIndex: 1,
    color: colors.primary,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.primary : "white",
    color: state.isSelected ? "white" : colors.grey,
    "&:hover": {
      color: colors.primary,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    zIndex: 1,
    fontWeight: state.isSelected ? "bold" : "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "grey",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.grey,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 8px)",
  }),
  input: (provided) => ({
    ...provided,
    color: colors.primary,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "500px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  }),
};

const CustomOption = ({ children, ...props }) => {
  const { onDelete } = props.selectProps;
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between w-full px-2 py-2">
        <div className="flex-grow truncate mr-2">{children}</div>
        {!props.isDisabled && (
          <FaTrash
            className="flex-shrink-0 text-gray-400 hover:text-red-500 cursor-pointer transition-colors duration-200 ml-2"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(props.data);
            }}
          />
        )}
      </div>
    </components.Option>
  );
};

const CatalogForm = ({
  catalogNames,
  selectedTagCatalog,
  setSelectedTagCatalog,
  selectedTagRules,
  setSelectedTagRules,
}) => {
  const tagOptions = [
    { value: null, label: "Blank" },
    ...catalogNames.map((catalog) => ({
      value: catalog,
      label: catalog,
    })),
  ];

  return (
    <div className="flex flex-col space-y-4">
      <div className="space-y-2">
        <label htmlFor="tagCatalog" className="text-sm text-gray-600">
          Default Tags
        </label>
        <Select
          id="tagCatalog"
          value={tagOptions.find(
            (option) => option.value === selectedTagCatalog,
          )}
          onChange={(option) => setSelectedTagCatalog(option.value)}
          options={tagOptions}
          placeholder="Select default tags"
          className="w-full"
          styles={customStyleForSelect}
        />
      </div>
      {selectedTagCatalog && (
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
            checked={selectedTagRules === selectedTagCatalog}
            onChange={(e) => {
              setSelectedTagRules(e.target.checked ? selectedTagCatalog : null);
            }}
          />
          <label htmlFor="tagRules" className="text-sm text-gray-600">
            Import tag rules from {selectedTagCatalog}
          </label>
        </div>
      )}
    </div>
  );
};

export default function SearchBar() {
  const {
    setSearchTerm,
    usedCatalog,
    handleCatalogChange,
    handleCatalogRename,
    handleEvidenceButtonClick,
    handleMultipleDelete,
    setShowScreen,
    preferences,
    setCurrentTag,
    currentDataGroup,
    selectedFilters,
    setView,
    setShowConnectData,
    setCatalogGetsRenamed,
    catalogGetsRenamed,
    setUsedCatalog,
    catalogGetsCreated,
    setCatalogGetsCreated,
    fetchInitialCatalog,
    fetchInitialTaggerList,
    versioningEnabled,
  } = useContext(DataContext);
  const {
    setProcessingTags,
    setProcessingTagTasks,
    setProcessingTagTaskProgress,
  } = useContext(TagContext);
  const { quarantineFiles } = useCatalogData();
  const { isLoading, data: catalogNames = [], refetch } = useCatalogNames();
  const [isCatalogNameBeingEdited, setIsCatalogNameBeingEdited] =
    useState(false);
  const [oldCatalogName, setOldCatalogName] = useState("");
  const [newCatalogName, setNewCatalogName] = useState("");
  const [showAddOptions] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [selectedTagCatalog, setSelectedTagCatalog] = useState("");
  const [selectedTagRules, setSelectedTagRules] = useState("");
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [scanDocumentsLoading, setScanDocumentsLoading] = useState(false);
  const [resetScanLoading, setResetScanLoading] = useState(false);
  const [_, setRunningTasks] = useAtom(runningTasksAtom);
  const [documentTaggingTask, setDocumentTaggingTask] = useAtom(
    documentTaggingTaskAtom,
  );
  const [catalogCreationOption, setCatalogCreationOption] = useState("blank");
  const createCatalogMutation = useCreateCatalogMutation();
  const [existingRules, setExistingRules] = useState([]);

  const selectValue = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  const { setShowAdvancedTagFilters } = useCatalogContext();

  const fetchTagRules = async () => {
    const rules = await getTagRules(usedCatalog);

    const uniqueRules = rules.reduce((acc, rule) => {
      if (!acc[rule.tagName]) {
        acc[rule.tagName] = new Set();
      }
      rule.children.forEach((child) => {
        acc[rule.tagName].add(child.tagName);
      });
      return acc;
    }, {});

    const formattedRules = Object.entries(uniqueRules).map(
      ([tagName, children]) => ({
        tagName,
        ruleCount: children.size,
      }),
    );

    setExistingRules(formattedRules);
  };

  useEffect(() => {
    if (usedCatalog) {
      fetchTagRules();
    }
  }, [usedCatalog]);

  const scanDocuments = async () => {
    const userConfirmed = window.confirm(
      "Do you want to tag the versions of the datasets in this catalog?",
    );
    if (!userConfirmed) {
      toast.info({
        title: "Cancelled",
        description: "Version tagging cancelled by user.",
      });
      return;
    }

    const taskId = `scanDocuments-${Date.now()}`;
    const entries = [];
    const selectedFiles = Array.from(selectedCatalogItems);
    const dataSnapShot = { ...currentDataGroup };

    for (const file_name of Object.keys(dataSnapShot)) {
      if (selectedFiles.length === 0 || selectedFiles.includes(file_name)) {
        const catalogItem = dataSnapShot[file_name];
        const sendChunkObject = {
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              catalogItem.data_store_name
                ? catalogItem.data_store_name
                : catalogItem.storage_type
            ],
            path: `${catalogItem.file_directory}/${file_name}`,
          }),
        };

        entries.push(sendChunkObject);
      }
    }

    const SCANNING_TAGS = [
      "two_word_summary",
      "document_year",
      "document_purpose",
    ];
    setProcessingTags((prev) => [
      ...prev,
      ...SCANNING_TAGS.map((tag) => ({ label: tag })),
    ]);

    setRunningTasks((tasks) => [
      ...tasks,
      {
        id: taskId,
        type: "Scanning",
        description: "Scanning documents",
        completed: 0,
      },
    ]);

    try {
      setScanDocumentsLoading(true);
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const res = await sendRequest(
        {
          entries,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          preferences: JSON.stringify(preferences),
          catalog_name: usedCatalog,
        },
        ENDPOINTS["scan_documents"],
      );
      const { task_id } = await res.json();

      setRunningTasks((tasks) => {
        const updatedTask = tasks.find((task) => task.id === taskId);
        if (updatedTask) {
          updatedTask.id = task_id;
        }
        return [...tasks];
      });

      setProcessingTagTaskProgress(0);
      setDocumentTaggingTask(task_id);

      waitTaskDone(task_id, creds, undefined, ({ completed }) => {
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === taskId);
          if (updatedTask) {
            updatedTask.completed = completed;
          }
          return [...tasks];
        });
        setProcessingTagTaskProgress(completed);
      }).then(() => {
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === task_id);
          if (updatedTask) {
            updatedTask.completed = 1;
          }
          return [...tasks];
        });
        setProcessingTags((prev) =>
          prev.filter(({ label }) => !SCANNING_TAGS.includes(label)),
        );
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          SCANNING_TAGS.forEach((tag) => {
            newMap.delete(tag);
          });
          return newMap;
        });
        fetchInitialCatalog(usedCatalog);
        setScanDocumentsLoading(false);
        setDocumentTaggingTask(null);
      });
    } catch (error) {
      console.error("Error running all tags:", error);
      toast.error({
        title: "Error",
        description: "There was an issue processing the tags.",
      });

      setProcessingTags((prev) =>
        prev.filter(({ label }) => !SCANNING_TAGS.includes(label)),
      );
      setProcessingTagTasks((prev) => {
        const newMap = new Map(prev);
        SCANNING_TAGS.forEach((tag) => {
          newMap.delete(tag);
        });
        return newMap;
      });
    }
  };

  const clearScan = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const requestBody = {
      [preferences.system.API_USERNAME_KEYWORD]: creds,
      preferences: JSON.stringify(preferences),
      catalog_name: usedCatalog,
    };
    setResetScanLoading(true);
    await sendRequest(requestBody, ENDPOINTS["clear_scan"]).then(() => {
      fetchInitialCatalog(usedCatalog);
      setResetScanLoading(false);
    });
  };

  const handleCreateNewCatalog = async () => {
    const oldCatalogName = usedCatalog;
    const createdCatalogName = newCatalogName.trim();

    setCatalogGetsCreated(false);
    setUsedCatalog(createdCatalogName);
    setCatalogGetsRenamed(true);

    if (!createdCatalogName) {
      toast.error({
        title: "Error",
        description: "Catalog name and tag catalog are required.",
      });
      return;
    }

    let datasets = null;

    if (catalogCreationOption !== "blank") {
      datasets = Object.keys(currentDataGroup);
    }

    createCatalogMutation.mutate(
      {
        catalogName: newCatalogName,
        tagCatalog: selectedTagCatalog || "",
        tagRules: selectedTagCatalog || "",
        datasets: datasets,
        oldCatalogName: oldCatalogName,
      },
      {
        onSuccess: async () => {
          setNewCatalogName("");
          setSelectedTagCatalog("");
          await fetchInitialCatalog(createdCatalogName);
          await fetchInitialTaggerList(createdCatalogName);
          toast.success({
            title: "Success",
            description: "Catalog created successfully!",
          });
          setCatalogGetsRenamed(false);
        },
        onError: () => {
          toast.error({
            title: "Error",
            description: "Failed to create catalog.",
          });
          setCatalogGetsRenamed(false);
        },
      },
    );
  };

  const handleRenameCatalog = async () => {
    if (newCatalogName.trim() === "") {
      toast.error({
        title: "Error",
        description: "Catalog name cannot be empty",
      });
      return;
    }

    setCatalogGetsRenamed(true);

    toast.info({
      title: "Info",
      description: "Your catalog is being renamed, please wait",
    });

    try {
      await handleCatalogRename(oldCatalogName, newCatalogName);
      await refetch();
      toast.success({
        title: "Success",
        description: "Your catalog was successfully renamed!",
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: "Failed to rename catalog. Please try again.",
      });
    } finally {
      setCatalogGetsRenamed(false);
      setIsCatalogNameBeingEdited(false);
      setOldCatalogName("");
      setNewCatalogName("");
    }
  };

  const deleteFromCatalog = (name) => {
    const isConfirmed = window.confirm(
      `Deleting '${name}' will remove access to files in this catalog. Are you sure you want to proceed?`,
    );

    if (isConfirmed) {
      deleteCatalog(name)
        .then(() => {
          refetch();
          toast.success({
            title: "Success",
            description: `Catalog '${name}' deleted successfully!`,
          });
        })
        .catch((error) => {
          console.error("Failed to delete catalog:", error);
          toast.error({
            title: "Error",
            description: `Failed to delete catalog '${name}'. Please try again.`,
          });
        });
    }
  };

  return (
    <div className="w-full flex flex-col rounded-t-md bg-white">
      {catalogGetsCreated && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-[9999]">
          <div className="bg-white rounded-lg shadow-2xl w-[50vw] min-h-[45vh] p-10  space-y-2 relative">
            <h2 className="text-2xl font-bold mb-6 text-grey">
              Create New Catalog
            </h2>

            <div>
              <p className="text-md text-gray-600 mb-2 font-bold">
                {catalogCreationOption == "blank"
                  ? "Creating an empty catalog"
                  : `Creating a catalog with ${Object.keys(currentDataGroup).length}
              datasets`}
              </p>
              <label
                htmlFor="catalogName"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Catalog Name
              </label>
              <input
                id="catalogName"
                type="text"
                placeholder="Enter catalog name"
                value={newCatalogName}
                onChange={(e) => setNewCatalogName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
              />
            </div>
            <CatalogForm
              catalogNames={catalogNames}
              selectedTagCatalog={selectedTagCatalog}
              setSelectedTagCatalog={setSelectedTagCatalog}
              selectedTagRules={selectedTagRules}
              setSelectedTagRules={setSelectedTagRules}
              existingRules={existingRules}
            />
            <div className="w-full p-3 flex justify-end space-x-2 absolute right-10 bottom-10">
              <button
                onClick={() => setCatalogGetsCreated(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-150"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateNewCatalog}
                className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition duration-150"
              >
                Create Catalog
              </button>
            </div>
          </div>
        </div>
      )}
      {showDistributionModal && (
        <div
          onClick={() => {
            setShowDistributionModal(false);
          }}
          className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-[9999] p-4"
        >
          <DataGraph
            selectedFilters={selectedFilters}
            onClose={() => setShowDistributionModal(false)}
          />
        </div>
      )}
      <div className="flex w-full p-5 items-center z-50">
        <div className="flex flex-row w-full">
          <div className="relative">
            {isLoading || catalogGetsRenamed ? (
              <div className="px-2 py-1 rounded-md">Loading...</div>
            ) : isCatalogNameBeingEdited ? (
              <input
                className="p-2 ml-2 rounded-md border outline-none text-black"
                value={newCatalogName}
                onChange={(e) => setNewCatalogName(e.target.value)}
              />
            ) : (
              <Select
                onChange={(selectedOption) =>
                  handleCatalogChange({
                    target: { value: selectedOption.value },
                  })
                }
                value={
                  catalogNames.includes(usedCatalog)
                    ? { value: usedCatalog, label: usedCatalog }
                    : null
                }
                options={catalogNames.map((catalog) => ({
                  value: catalog,
                  label: catalog,
                }))}
                isLoading={isLoading || catalogGetsRenamed}
                placeholder="Select a catalog"
                className="w-64 font-bold text-primary"
                classNamePrefix="react-select"
                styles={customStyleForSelect}
                components={{ Option: CustomOption }}
                onDelete={(option) => deleteFromCatalog(option.value)}
              />
            )}

            {isCatalogNameBeingEdited && (
              <div className="flex items-center">
                <button
                  onClick={handleRenameCatalog}
                  className="bg-deasieTurquoise p-2 rounded-md"
                >
                  <MdCheck />
                </button>
                <button
                  onClick={() => {
                    setIsCatalogNameBeingEdited(false);
                    setOldCatalogName("");
                    setNewCatalogName("");
                  }}
                  className="bg-rose-500 p-2 rounded-md"
                >
                  <RxCross2 />
                </button>
              </div>
            )}
          </div>
          <PermissionGuard scope="catalogs" level="canEdit">
            {!isCatalogNameBeingEdited && (
              <div className="h-full flex flex-row items-center gap-3 align-middle justify-center">
                <button
                  className="p-2 text-deasieBlack"
                  onClick={() => {
                    setIsCatalogNameBeingEdited(true);
                    setOldCatalogName(selectValue);
                    setNewCatalogName(selectValue);
                  }}
                  title="Rename Catalog"
                >
                  <FaPencilAlt />
                </button>
                <div className="flex flex-row items-center border-2 rounded-md px-2">
                  <p>
                    <span className="font-bold text-primary">
                      {currentDataGroup
                        ? Object.keys(currentDataGroup).length
                        : 0}
                    </span>
                    <span className="text-primary"> datasets</span>
                  </p>
                  <button
                    className="p-2 text-buttonGrey"
                    onClick={handleMultipleDelete}
                    title="Delete multiple datasets"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <button
                    className="p-2 text-buttonGrey"
                    onClick={() => {
                      const confirmation = window.confirm(
                        `Are you sure you want to quarantine ${Object.keys(currentDataGroup || {}).length} files?`,
                      );
                      if (!confirmation) return;
                      quarantineFiles();
                    }}
                    title="Quarantine multiple datasets"
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </button>
                </div>
              </div>
            )}
          </PermissionGuard>
        </div>

        <div className="flex w-full justify-end">
          <div className="dropdown relative">
            <PermissionGuard scope="tags" level="canEdit">
              <div
                className={`${styleForButton} bg-primary text-white cursor-pointer`}
                onClick={() => {
                  setShowScreen("addNewTag");
                  setCurrentTag({});
                }}
              >
                New Tag
              </div>
            </PermissionGuard>
            <div
              className={`transition-all duration-300 ease-in-out ${
                showAddOptions
                  ? "opacity-100 transform translate-y-0 "
                  : "opacity-0 transform -translate-y-4 pointer-events-none"
              } absolute mt-2 z-50 bg-white shadow-lg rounded w-full`}
            >
              <a
                className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setShowScreen("addNewTag");
                  setCurrentTag({});
                }}
              >
                Manual
              </a>
              <PermissionGuard scope="tags" level="canEdit">
                <a
                  className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setShowScreen("autoCreateTag");
                  }}
                >
                  Auto create
                </a>
              </PermissionGuard>
            </div>
          </div>
          <PermissionGuard scope="tags" level="canEdit">
            <button
              className={styleForButton}
              onClick={() => {
                setView("options");
                setShowConnectData(true);
              }}
              title="Add Data"
            >
              Add Data
            </button>
          </PermissionGuard>
          <PermissionGuard scope="tags" level="canEdit">
            <div className="relative inline-block">
              <button
                className={styleForButton}
                onMouseEnter={() =>
                  document
                    .getElementById("catalogDropdown")
                    .classList.remove("hidden")
                }
              >
                Create Catalog
              </button>
              <div
                id="catalogDropdown"
                className="ml-4 absolute hidden mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10"
                onMouseLeave={() =>
                  document
                    .getElementById("catalogDropdown")
                    .classList.add("hidden")
                }
              >
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-primary hover:text-white"
                  onClick={() => {
                    setCatalogGetsCreated(true);
                    setCatalogCreationOption("blank");
                  }}
                >
                  Empty catalog
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-primary hover:text-white"
                  onClick={() => {
                    setCatalogGetsCreated(true);
                    setCatalogCreationOption("selected-datasets");
                  }}
                >
                  Include{" "}
                  <span className="font-bold">
                    {Object.keys(currentDataGroup).length}
                  </span> {" "}
                  dataset{Object.keys(currentDataGroup).length > 1 ? "s" : ""}
                </button>
              </div>
            </div>
          </PermissionGuard>
          <button
            className={styleForButton}
            onClick={() => setShowAdvancedTagFilters(true)}
            title="Filter Tags"
          >
            <FaFilter />
          </button>
          <button
            onClick={() =>
              handleEvidenceButtonClick(Object.keys(currentDataGroup || {}))
            }
            className={styleForButton}
          >
            <FaTable />
          </button>
          <button
            onClick={() => {
              setShowDistributionModal(true);
            }}
            className={styleForButton}
            title="Distribution"
          >
            <FaChartBar />
          </button>
          {versioningEnabled && (
            <>
              <div
                className={`${styleForButton} cursor-pointer`}
                onClick={async () => {
                  await scanDocuments();
                }}
              >
                {scanDocumentsLoading ? (
                  <div className="flex items-center gap-2">
                    <div className="text-red animate-spin rounded-full h-4 w-4 border-b-2 border-primary"></div>
                    <span>Scanning...</span>
                  </div>
                ) : (
                  <div
                    className="flex items-center gap-2"
                    title="Scan documents to detect versions"
                  >
                    <DocumentVersionIcon
                      size={24}
                      color="rgba(40, 165, 144, 1)"
                      version="2.1"
                    />
                  </div>
                )}
              </div>
              <div
                className={`${styleForButton} border-red-500 text-red-500 cursor-pointer`}
                onClick={async () => {
                  await clearScan();
                }}
                title="Clear document scan save"
              >
                {resetScanLoading ? (
                  <div className="flex items-center gap-2">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-red-500" />
                    <span>Clearing...</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <TbZoomReset />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center bg-white dark:bg-secondary rounded-lg p-4 shadow-md hover:shadow-xl m-2 transition-all duration-300 ease-in-out">
        <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3"></i>
        <input
          type="text"
          className="block w-full bg-transparent focus:outline-none"
          placeholder="Search for a document"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
