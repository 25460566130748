/**
 * Higher Order Function to apply debouncing on an async function
 *
 * @param {function(): Promise<any>} func - aync function to debounce
 * @param {number} delay
 */
export const asyncDebounce = (func, delay) => {
  let timerId;

  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(async () => {
      await func(...args);
    }, delay);
  };
};

export class Debouncer {
  constructor() {
    this.timerId = null;
  }

  static debounce(func, delay, ...args) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      func(...args);
    }, delay);
  }
}
