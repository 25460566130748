import { msalInstance, loginRequest } from "./msalConfig";
import { IAuthProvider, IAzureUser } from "./IAuthProvider";

export class AzureAuthProvider implements IAuthProvider<IAzureUser> {
  async currentAuthenticatedUser(): Promise<IAzureUser> {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0];
    } else {
      throw new Error("No Azure AD account found");
    }
  }

  async getIdToken(): Promise<string> {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.idToken;
    } else {
      throw new Error("No Azure AD account found");
    }
  }

  async getAccessToken(): Promise<string> {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    } else {
      throw new Error("No Azure AD account found");
    }
  }
}
