/* eslint-disable */

// replace the user pool region, id, and app client id details
export default {
  "REGION": "us-east-1",
  "USER_POOL_ID": "us-east-1_IpsHmCtnU",
  "USER_POOL_APP_CLIENT_ID": "rfup9dibq9l6a0j95aibff6rr",
  "aws_appsync_graphqlEndpoint": "https://c6orymecrjd27eyhhjmzugk66i.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
}
