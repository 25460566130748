import { useContext, useEffect } from "react";
import { DataContext } from "../../../../../context/DataContext";

export default function TagSelection(props) {
  const { availableTags } = useContext(DataContext);

  const tagCategories = Object.entries({
    ...availableTags.sensitivity.tagger_params.tag_dict,
  }).map(([key, value]) => ({ key, value }));

  const allTagsSelected = tagCategories.every(
    (category) => props.selectedTags[category.key]
  );


  useEffect(() => {
    return () => {
      props.setSelectedTags({});
      props.setCheckSensitivity(false);
    };
  }, []);

  return (
    <>
      {tagCategories && tagCategories.length > 0 ? (
        <div className="TagSelectionContainer">
          <div className="TagSelectionHeader">
            <div className="flex flex-col bg-white rounded-bg shadow-md mt-1 w-[20vw] ml-10 mr-10">
              <div className="flex my-5 mx-2.5 flex-col">
                <label className="flex items-center cursor-pointer p-2">
                  <input
                    type="checkbox"
                    className="h-5 w-5 text-primary bg-primary border-gray-300 rounded focus:ring-primary checked:text-primary"
                    onChange={(e) =>
                      props.setCheckSensitivity((prevState) => !prevState)
                    }
                    value={props.checkSensitivity}
                  />
                  <span className="ml-2 text-gray-700 focus:ring-primary">
                    Sensitivity Check
                  </span>
                </label>

                <div className="Explanation">
                  <p>
                    <strong>If checked:</strong> Performs all sensitivity checks
                    selected below and if triggered quarantines the dataset
                    <br />
                    <strong>If unchecked:</strong> Uploads the dataset without
                    running sensitivity checks
                  </p>
                </div>
                {props.checkSensitivity && (
                  <label className="flex items-center cursor-pointer p-2">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-primary bg-primary border-gray-300 rounded focus:ring-primary checked:primary"
                      onChange={(e) =>
                        props.handleSelectAllToggle(e, tagCategories)
                      }
                      checked={allTagsSelected && tagCategories.length > 0}
                    />
                    <span className="ml-2 text-gray-700 focus:ring-primary">
                      Select all sensitivity checks
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          {props.checkSensitivity &&
            tagCategories.map((tag) => (
              <div 
                key={tag.key} 
                className="TagCategory"
                onClick={() => {
                  const newSelectedTags = {...props.selectedTags};
                  newSelectedTags[tag.key] = !newSelectedTags[tag.key];
                  props.handleTagChange(tag.key, newSelectedTags[tag.key]);
                }}
              >
                <div>
                  <h3 className="font-bold">{tag.key}</h3>
                    <div className="text-buttonGrey flex-wrap max-w-[70vw]">
                      {tag.value.description}
                    </div>
                </div>
                <input
                  type="checkbox"
                  className="hiddenCheckbox"
                  checked={props.selectedTags[tag.key]}
                  readOnly
                />
                <span className="customCheckbox"></span>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex w-full h-full items-center justify-center p-2">
          <p className="text-lg text-buttonGrey font-bold">
            No sensitivity tags available
          </p>
        </div>
      )}
    </>
  );
}
