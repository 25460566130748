import { v4 as uuidv4 } from "uuid";
class ToastManager {
  ready = false;
  notifications = null;
  setNotifications = null;
  timeout = 3000;

  init(notifications, setNotifications) {
    this.notifications = notifications;
    this.setNotifications = setNotifications;
    this.ready = true;
  }

  success({ title, description }) {
    this.setNotifications((prev) => [
      { id: uuidv4(), title, description, level: "success" },
      ...prev,
    ]);
  }

  error({ title, description }) {
    this.setNotifications((prev) => [
      { id: uuidv4(), title, description, level: "error" },
      ...prev,
    ]);
  }

  warning({ title, description }) {
    this.setNotifications((prev) => [
      { id: uuidv4(), title, description, level: "warning" },
      ...prev,
    ]);
  }

  info({ title, description }) {
    this.setNotifications((prev) => [
      { id: uuidv4(), title, description, level: "info" },
      ...prev,
    ]);
  }
}

export const toast = new ToastManager();
