import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { toast } from "../components/utilities/Toast";

export const waitTaskDone = (
  task_id: string,
  username: string,
  intervalInSeconds = 2,
  onInterval?: ({
    status,
    completed,
  }: {
    status: "COMPLETED" | "PENDING" | "FAILED" | "quota_exceeded";
    completed: number;
  }) => {}
) => {
  return new Promise((resolve, reject) => {
    let timeoutId = 0;
    const timeoutHandler = async () => {
      sendRequest(
        { username },
        `${ENDPOINTS["get_task_status"]}${task_id}`,
        "GET"
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          return res.json();
        })
        .then(({ status, completed }) => {
          onInterval?.({ status, completed });
          if (status === "quota_exceeded") {
            toast.error({
              title: "Token Limit Exceeded",
              description: "Please contact your admin.",
            });            
            resolve(null);
            return;
          }
          if (status !== "PENDING") {
            resolve(null);
          }
          timeoutId = window.setTimeout(
            timeoutHandler,
            intervalInSeconds * 1000
          );
        })
        .catch(() => {
          window.clearTimeout(timeoutId);
          resolve(null);
        });
    };
    timeoutId = window.setTimeout(timeoutHandler, intervalInSeconds * 1000);
  });
};

export const abortTask = (task_id: string, username: string) => {
  return sendRequest({ username, task_id }, ENDPOINTS["revoke_task"], "POST");
};
