import "./DataCatalog.css";
import LoadComponent from "../../../../utilities/LoadComponent/LoadComponent";
import SearchBar from "../../../../utilities/SearchBar/SearchBar";
import DataList from "./DataCatalogComponents/DataList/DataList";
import { DataContext } from "../../../../../context/DataContext";
import TagDefinitions from "./TagDefinitions";
import useCatalogData from "./useCatalogData";
import { useContext } from "react";

export function DataCatalog() {
  const { setSearchTerm, searchTerm, isLoading } = useContext(DataContext);

  const { answerLoading, setAnswerLoading } = useCatalogData();

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full gap-4 flex-row">
      <div className="flex border flex-col rounded-md w-full h-auto overflow-hidden">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <DataList
          answerLoading={answerLoading}
          setAnswerLoading={setAnswerLoading}
        />
      </div>
      <div
        className={`flex border h-auto rounded-md flex-col w-full gap-4 overflow-hidden max-w-[20vw]`}
      >
        <TagDefinitions />
      </div>
    </div>
  );
}
