import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { useUserProfile } from "../../../../../../context/UserProfile";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import DynamicTable from "./DynamicTableComponent";
import { ca } from "date-fns/locale";
import DataStoreTable from "./DataStoreTable";
import { colors } from "../../../../../../twExtend";


const ALLOWED_KEYS = [
  "OPENAI_ENDPOINTS",
  "DEFAULT_CHUNKER",
  "CHUNK_SIZE",
  "CHUNK_OVERLAP",
];

const headerMaps = {
  "OPENAI_ENDPOINTS" : "LLM Endpoints",
  "DEFAULT_CHUNKER" : "Chunking Method",
  "CHUNK_SIZE" : "Chunk size (by tokens)",
  "CHUNK_OVERLAP" : "Chunk overlap (by tokens)",
}

const headerStyle = "block text-gray-800 dark:text-gray-300 mb-1 text-lg"

export function ProfileComponent({
  activeTab,
  currentFormValues,
  setCurrentFormValues,
}) {
  const { preferences, availableTags } =
    useContext(DataContext);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([])

  const RISK_LEVEL_OPTIONS = ["High", "Medium", "Low"];
  const VECTOR_DB_OPTIONS = ["local", "postgres", "qdrant"];
  const DEFAULT_CHUNKER_OPTIONS = ["page", "llm", "sentence"];
  const userProfile = useUserProfile();

  useEffect(() => {
    if (userProfile) {
      setCurrentFormValues(
        userProfile 
      );
      const [parsedValue, _] = safeParseJson(
        (userProfile).hidden_tags
          .HIDDEN_TAGS
      );
      setSelectedTags(parsedValue || []);
    }
  }, [userProfile, setCurrentFormValues]);

  const handleChange = (path, newValue) => {
    setCurrentFormValues((prevValues) => {
      const newValues = { ...prevValues };
      const pathKeys = path.split(".");
      let lastObj = newValues;
      for (let i = 0; i < pathKeys.length - 1; i++) {
        const key = pathKeys[i];
        if (
          !lastObj[key] ||
          typeof lastObj[key] !== "object" ||
          Array.isArray(lastObj[key])
        ) {
          lastObj[key] = {};
        }
        lastObj = lastObj[key];
      }
      lastObj[pathKeys[pathKeys.length - 1]] = newValue;
      return newValues;
    });
  };

  const handleChangeWrapper = (path, newValue) => {
    handleChange(`${activeTab}.${path}`, newValue);
  };

  const safeParseJson = (str) => {
    try {
      const parsed = JSON.parse(str);
      return [parsed, true];
    } catch (e) {
      return [str, false];
    }
  };

  const ConfigurationInput = ({
    label,
    type,
    value,
  }) => {
    const [inputValue, setInputValue] = useState(value);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
      handleChange(`${activeTab}.${label}`, inputValue);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
        <label className={headerStyle}>
          {headerMaps[label]}
          <input
            type={type}
            value={inputValue ?? ""}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
          />
        </label>
      </div>
    );
  };

  const ConfigurationSelect = ({
    label,
    value,
    options,
  }) => (
    <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
      <label className={headerStyle}>
      {headerMaps[label]}
        <select
          value={value || ""}
          onChange={(e) =>
            handleChange(`${activeTab}.${label}`, e.target.value)
          }
          className="font-medium form-select mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 text-base focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  );

  const ConfigurationCheckbox = ({
    label,
    checked,
  }) => (
    <div className={headerStyle}>
      <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1 items-center gap-2">
        {label}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) =>
            handleChange(`${activeTab}.${label}`, e.target.checked)
          }
          className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
        />
      </label>
    </div>
  );

  const renderInputs = (
    data,
    path = ""
  ) => {
    const entries = Object.entries(data);

    const filteredEntries =
    activeTab === "profile"
      ? entries.filter(([key]) => ALLOWED_KEYS.includes(key))
      : activeTab === "webapp_profile"
      ? entries.filter(([key]) => key === "DATA_STORES")
      : entries;

    return filteredEntries.map(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;
      const inputValue = getNestedValue(
        currentFormValues[activeTab] || {},
        currentPath.split(".")
      );
      const [parsedValue, isJson] = safeParseJson(value);

      if (key === "HIDDEN_TAGS") {
        return (
          <div key={currentPath}>
            {renderSelectedTags(`${activeTab}.${currentPath}`)}
            {renderTagDropdown(`${activeTab}.${currentPath}`)}
          </div>
        );
      } else if (key === "OPENAI_ENDPOINTS") {
        return (
          <>
            <div
              key={currentPath}
              className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md"
            >
              <label
                htmlFor={currentPath}
                className={headerStyle}
              >
                {headerMaps[currentPath]}
              </label>
              <DynamicTable
                {...{
                  key: currentPath,
                  label: currentPath,
                  value: parsedValue,
                }}
                initialData={parsedValue}
                onDataChange={(newData) =>
                  handleChange(`${activeTab}.${currentPath}`, newData)
                }
              />
            </div>
          </>
        );
      } else if (key === "DATA_STORES") {
        return (
          <>
            <div
              key={currentPath}
              className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md"
            >
              <label
                htmlFor={currentPath}
                className={headerStyle}
              >
                {headerMaps[currentPath]}
              </label>
              <div className="mt-2">
                <DataStoreTable
                  initialData={value}
                  onDataChange={(newData) =>
                    handleChange(`${activeTab}.${currentPath}`, newData)
                  }
                />
              </div>
            </div>
          </>
        );
      } else if (
        ["ACCESS_GROUP_RISK_LABEL", "VECTOR_STORE_BACKEND", "DEFAULT_CHUNKER"].includes(key)
      ) {
        let options = [];
        switch (key) {
          case "ACCESS_GROUP_RISK_LABEL":
            options = RISK_LEVEL_OPTIONS;
            break;
          case "VECTOR_STORE_BACKEND":
            options = VECTOR_DB_OPTIONS;
            break;
          case "DEFAULT_CHUNKER":
            options = DEFAULT_CHUNKER_OPTIONS;
            break;
        }
        return (
          <ConfigurationSelect
            {...{
              key: currentPath,
              label: currentPath,
              value: parsedValue,
              options: options,
            }}
          />
        );
      } else if (typeof inputValue === "boolean") {
        return (
          <ConfigurationCheckbox
            key={currentPath}
            label={currentPath}
            checked={inputValue}
          />
        );
      } else if (isJson || typeof inputValue === "object") {
        return (
          <div
            key={currentPath}
            className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md"
          >
            <label
              htmlFor={currentPath}
              className={headerStyle}
            >
              {headerMaps[currentPath]}
            </label>
            <JsonView
              src={parsedValue}
              onAdd={(params) => {
                handleChangeWrapper(`${currentPath}`, params.src);
              }}
              onEdit={(params) => {
                handleChangeWrapper(
                  `${currentPath}`,
                  params.parentType === "object" ? params.src : params.newValue
                );
              }}
              onDelete={(params) => {
                handleChangeWrapper(
                  `${currentPath}`,
                  params.parentType === "object" ? params.src : params.value
                );
              }}
              theme="default"
              enableClipboard={true}
              editable={true}
              style={{ fontSize: "0.9vw", color: colors.secondary }}
            />
          </div>
        );
      } else {
        return (
          <ConfigurationInput
            {...{
              key: currentPath,
              label: currentPath,
              type: typeof inputValue === "number" ? "number" : "text",
              value: inputValue,
            }}
          />
        );
      }
    });
  };

  const handleSelectChange = (
    e,
    currentPath
  ) => {
    setSelectedTag(e.target.value);
  };

  const addTag = (currentPath) => {
    if (selectedTag && !selectedTags.includes(selectedTag)) {
      const updatedTags = [...selectedTags, selectedTag];
      handleChange(currentPath, updatedTags);
      setSelectedTag("");
      setSelectedTags(updatedTags);
    }
  };

  const removeTag = (tagToRemove, currentPath) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    handleChange(currentPath, updatedTags);
    setSelectedTags(updatedTags);
  };

  const renderTagDropdown = (currentPath) => {
    const tags = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
      ...Object.fromEntries(
        preferences.system.EXCLUDE_TAGS.map((tag) => [tag, true])
      ),
    });

    return (
      <div className="flex items-center gap-2 bg-white p-4 rounded-lg shadow">
        <select
          className="form-select flex-grow border border-gray-300 rounded-md text-gray-700 p-2 focus:border-primary focus:ring-1 focus:ring-primary transition duration-150 ease-in-out"
          value={selectedTag}
          onChange={(e) => handleSelectChange(e, currentPath)}
        >
          {tags.map(
            (tag) =>
              !selectedTags.includes(tag) && (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              )
          )}
        </select>
        <button
          type="button"
          className="px-4 py-2 bg-primary text-white font-semibold rounded-md hover:bg-deasieTurquoise transition duration-150 ease-in-out"
          onClick={() => addTag(currentPath)}
          aria-label="Add tag"
        >
          + Add Tag
        </button>
      </div>
    );
  };

  const renderSelectedTags = (currentPath) => {
    return (
      <ul className="flex flex-wrap gap-2 p-4 bg-white rounded-lg shadow">
        {selectedTags.map((tag) => (
          <li
            key={tag}
            className="bg-gray-200 rounded-full px-3 py-1 flex items-center"
          >
            {tag}
            <button
              type="button"
              className="ml-2 text-red-500 hover:text-red-700 focus:outline-none focus:text-red-800"
              onClick={() => removeTag(tag, currentPath)}
              aria-label={`Remove ${tag}`}
            >
              ✕
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const getNestedValue = (
    obj,
    pathArray
  ) => {
    return pathArray.reduce((acc, key) => {
      return acc ? acc[key] : null;
    }, obj);
  };

  if (!preferences) {
    return <div>Loading preferences...</div>;
  }

  return (
    <div>
      {activeTab === "profile" && (
        <div>{renderInputs(preferences.profile)}</div>
      )}
      {activeTab === "hidden_tags" && (
        <div>{renderInputs(preferences.hidden_tags)}</div>
      )}
      {activeTab === "webapp_profile" && (
        <div>{renderInputs(preferences.webapp_profile)}</div>
      )}
    </div>
  );
}
