import React, { createContext, useContext, useState } from "react";
import Auth from "../auth/AuthProvider";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { DataContext } from "./DataContext";
import { toast } from "../components/utilities/Toast";
import { waitTaskDone } from "../utils/workers";
import { useAtom } from "jotai";
import { runningTasksAtom } from "../atoms";

export const UsecaseContext = createContext();

export const UsecaseProvider = ({ children }) => {
  const {
    dataGroups,
    catalogSummary,
    catalogFiles,
    setDetectedGroup,
    useCases,
    setUseCases,
    setDateRange,
    startDate,
    endDate,
    setShowScreen,
    setUsecaseSelected,
    showScreen,
    dateRange,
    preferences,
    usedCatalog,
  } = useContext(DataContext);

  const [currentUseCase, setCurrentUseCase] = useState(
    preferences.webapp_profile.BASE_USECASE,
  );
  const [addedData, setAddedData] = useState({});
  const [showDataFilter, setShowDataFilter] = useState(false);
  const [showMetadataFilter, setShowMetadataFilter] = useState(false);
  const [selectedMetadata, setSelectedMetadata] = useState([]);
  const [usecaseStage, setUsecaseStage] = useState("menu");
  const [, setRunningTasks] = useAtom(runningTasksAtom);

  const checkFieldsCompletion = (step) => {
    switch (step) {
      case 1:
        return currentUseCase.description;
      default:
        return false;
    }
  };

  const checkAllFields = () => {
    return Array(checkFieldsCompletion(1)).every(Boolean);
  };

  const confirmUsecaseData = () => {
    setCurrentUseCase((prev) => ({
      ...prev,
      usecase_catalog: addedData,
    }));
    setShowDataFilter(false);
  };

  const confirmMetaData = () => {
    setCurrentUseCase((prev) => ({
      ...prev,
      metadata: selectedMetadata,
    }));
    setShowMetadataFilter(false);
  };

  const addUseCase = async () => {
    if (
      !currentUseCase.usecase_catalog ||
      Object.keys(currentUseCase.usecase_catalog).length === 0
    ) {
      toast.warning({
        title: "Warning",
        description:
          "You have not selected any datasets. Please select at least one dataset before proceeding.",
      });
      return;
    }
    toast.info({
      title: "Info",
      description: "Preparing your usecase to be added",
    });
    const processingUseCase = { ...currentUseCase };
    processingUseCase.vector_database =
      preferences.profile.VECTOR_STORE_BACKEND;
    processingUseCase.catalog_name = usedCatalog;
    processingUseCase.usecase_created = false;
    processingUseCase.completed = 0;
    setUseCases((prevState) => {
      const existingIndex = prevState.findIndex(
        (useCase) => useCase.id === processingUseCase.id,
      );
      if (existingIndex !== -1) {
        return prevState.map((useCase, index) =>
          index === existingIndex ? processingUseCase : useCase,
        );
      } else {
        return [...prevState, processingUseCase];
      }
    });
    setCurrentUseCase(preferences.webapp_profile.BASE_USECASE);

    setUsecaseStage("menu");

    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;

    const sendObject = {
      [preferences.system.API_USERNAME_KEYWORD]: username,
      usecase: JSON.stringify(processingUseCase),
      model_name: "",
      embed_model_name: "",
    };

    try {
      const response = await sendRequest(
        sendObject,
        ENDPOINTS["create_usecase"],
      );
      if (!response) {
        throw new Error();
      }

      toast.success({
        title: "Success",
        description: "Your usecase is being created, please wait...",
      });
      const { task_id } = await response.json();
      waitTaskDone(task_id, username, undefined, ({ completed }) => {
        setRunningTasks((prev) => {
          const task = prev.find((t) => t.id === task_id);
          task.completed = completed;
          return [...prev];
        });
        if (completed !== 1)
          setUseCases((prevUseCases) => {
            return prevUseCases.map((useCase) =>
              useCase.id === processingUseCase.id
                ? { ...useCase, completed }
                : useCase,
            );
          });
      }).then(() => {
        setUseCases((prevUseCases) => {
          return prevUseCases.map((useCase) =>
            useCase.id === processingUseCase.id
              ? { ...useCase, usecase_created: true }
              : useCase,
          );
        });
      });
    } catch (error) {
      const filteredItems = useCases.filter(
        (item) => item.id !== sendObject.id,
      );
      setUseCases(filteredItems);
      toast.error({
        title: "Error",
        description: `There was an error trying to add your usecase: ${String(
          error,
        )}`,
      });
    }
  };

  const editUseCase = (id) => {
    setUsecaseStage("usecase-definition");
    setCurrentUseCase(useCases.find((useCase) => useCase.id === id));
  };

  return (
    <UsecaseContext.Provider
      value={{
        // Getters
        currentUseCase,
        showDataFilter,
        dataGroups,
        catalogSummary,
        catalogFiles,
        useCases,
        startDate,
        endDate,
        showScreen,
        dateRange,
        showMetadataFilter,
        selectedMetadata,
        usecaseStage,
        addedData,
        // Setters
        setSelectedMetadata,
        setCurrentUseCase,
        setAddedData,
        setShowDataFilter,
        setShowMetadataFilter,
        setUsecaseStage,
        setDetectedGroup,
        setUseCases,
        setDateRange,
        setShowScreen,
        setUsecaseSelected,
        // Functions
        checkAllFields,
        confirmUsecaseData,
        addUseCase,
        confirmMetaData,
        editUseCase,
      }}
    >
      {children}
    </UsecaseContext.Provider>
  );
};
