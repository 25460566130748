import { useCallback, useEffect, useState } from "react";
import { toast } from "./managers";
import Toast from "./Toast";

const ToastContainer = () => {
  const [notifications, setNotifications] = useState([]);

  const handleClose = useCallback(
    (notification) =>
      setNotifications((currentNotifications) =>
        currentNotifications.filter(
          (_notification) => _notification.id !== notification.id
        )
      ),
    [setNotifications]
  );

  useEffect(() => {
    toast.init(notifications, setNotifications);
  }, [notifications, setNotifications]);

  return (
    <div className="fixed p-4 pointer-events-none z-[999999] inset-0 flex flex-col items-start justify-end">
      {notifications.length > 0 && (
        <div className="p-2 w-full max-w-xs pointer-events-auto overflow-y-auto flex flex-col gap-2">
          {notifications.map((notification) => (
            <Toast
              key={notification.id}
              notification={notification}
              onClose={handleClose}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ToastContainer;
