export class Queue {
  tasks: (() => void | Promise<void>)[];
  running: boolean;
  onQueueFinished: ((lastTaskOutput: unknown) => void) | null = null;
  lastTaskOutput: unknown;
  maxTasks?: number;

  constructor({ maxTasks }: { maxTasks?: number } = {}) {
    this.maxTasks = maxTasks;
    this.tasks = [];
    this.running = false;
  }

  addNewTask(task: () => void, dropLastIfFull: boolean = false) {
    if (this.maxTasks !== undefined && this.tasks.length >= this.maxTasks) {
      if (dropLastIfFull) {
        this.tasks[this.tasks.length - 1] = task;
      }
    } else {
      this.tasks.push(task);
    }
    if (this.running) return;

    this.run();
  }

  run() {
    this.running = true;
    this.runNextTask();
  }

  async runNextTask() {
    if (!this.running) return;
    const task = this.tasks.shift();
    if (!task) {
      this.onQueueFinished?.(this.lastTaskOutput);
      this.running = false;
      return;
    }
    try {
      this.lastTaskOutput = await task();
    } catch (error) {
      console.error(error);
    }
    await this.runNextTask();
  }
}
