import Auth from "../auth/AuthProvider";
import { useState, useEffect, useCallback } from "react";
import { sendRequest } from "./../components/utilities/functions/api";
import { ENDPOINTS } from "./../api/endpoints";

export const useUsers = () => {
  const [state, setState] = useState({
    users: [],
    teams: [],
    catalogTeams: [],
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(({ username }) =>
        sendRequest({ username }, ENDPOINTS["get_teams"], "GET"),
      )
      .then((response) => response.json())
      .then((data) =>
        setState({
          users: data["users"],
          teams: data["teams"],
          catalogTeams: data["catalog_teams"],
          isLoading: false,
          isError: false,
        }),
      )
      .catch((error) => {
        setState({ users: [], teams: [], isLoading: false, isError: true });
      });
  }, []);

  return state;
};

export const updateGroups = (payload) =>
  Auth.currentAuthenticatedUser()
    .then(({ username }) =>
      sendRequest({ username, ...payload }, ENDPOINTS["update_teams"], "POST"),
    )
    .then((response) => response.json());
