import { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./DataCatalog.css";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import Auth from "../../../../../auth/AuthProvider";
import {
  getCatalogSummary,
  updateCatalog,
} from "../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../context/DataContext";
import { Debouncer } from "./../../../../../utils/debounce";
import Workers from "../../../../../utils/threading";

export default function useCatalogData() {
  const {
    setCatalogFiles,
    setCatalogSummary,
    catalogFiles,
    setCurrentDataGroup,
    currentDataGroup,
    quarantinedFiles,
    setQuarantinedFiles,
    setSearchTerm,
    searchTerm,
    searchDetails,
    setHiddenCategories,
    selectedFilters,
    setSelectedFilters,
    preferences,
    setSearchDetails,
    failedTags,
    usedCatalog,
  } = useContext(DataContext);

  const [answerLoading, setAnswerLoading] = useState(false);
  const [mappings, setMappings] = useState({});
  const [showStandardization, setShowStandardization] = useState(false);
  const [standardizeTag, setStandardizeTag] = useState("");
  const [standardizeScreen, setStandardizeScreen] = useState("config");
  const [standardizeSensitivity, setStandardSensitivity] = useState(0.5);
  const [standardizeMinCategories, setStandardizeMinCategories] = useState(3);
  const [standardizationLoading, setStandardizationLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState("none");
  const [semanticSimilarity, setSemanticSimilarity] = useState(false);
  const lastFetchedTimeRef = useRef(Date.now());

  const menuRef = useRef();
  const noOfDocumentsWithFailedTags = useMemo(
    () => new Set(Array.from(failedTags.values()).flat()).size,
    [failedTags],
  );

  const handleFilterChange = (categoryKey, selectedOptions) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [categoryKey]: selectedOptions,
    }));
  };

  const getSummary = () =>
    Debouncer.debounce(async () => {
      const newSummary = await getCatalogSummary(currentDataGroup);
      if (!newSummary) return;

      const newCatalogSummary = newSummary.catalog_summary;
      if (newCatalogSummary.hasOwnProperty("tags")) {
        delete newCatalogSummary.tags;
      }
      setCatalogSummary(newSummary.catalog_summary);
      setSearchDetails(newSummary.search_details);
    }, 5000);

  useEffect(() => {
    getSummary();
  }, [currentDataGroup]);

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const handleResetFilter = (e, category) => {
    e.stopPropagation();
    const filterCopy = { ...selectedFilters };
    delete filterCopy[category];
    setSelectedFilters(filterCopy);
  };

  const standardizeValues = async (e, tag) => {
    setStandardizeScreen("config");
    e.stopPropagation();
    setStandardizeTag(tag);
    setShowStandardization(true);
  };

  const quarantineFiles = async () => {
    const fileNames = Object.keys(currentDataGroup);
    const totalFiles = fileNames.length;

    const confirmQuarantine = window.confirm(
      `You are about to quarantine ${totalFiles} file(s). Are you sure you want to proceed?`,
    );
    if (!confirmQuarantine) {
      return;
    }

    const newCatalogFiles = { ...catalogFiles };
    const newQuarantinedFiles = { ...quarantinedFiles };
    const constructSensitivityString = () => {
      if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
        return "No filters applied";
      }

      let sensitivityDescriptions = [];
      for (const [key, values] of Object.entries(selectedFilters)) {
        let valuesArray;

        if (values instanceof Set) {
          valuesArray = Array.from(values);
        } else if (Array.isArray(values)) {
          valuesArray = values;
        } else {
          valuesArray = [values];
        }

        if (valuesArray.length > 0) {
          const valuesString = valuesArray.join(", ");
          sensitivityDescriptions.push(`${key}: ${valuesString}`);
        }
      }

      if (sensitivityDescriptions.length === 0) {
        return "No filters applied";
      }

      return sensitivityDescriptions.join("; ");
    };

    fileNames.forEach((fileName) => {
      if (newCatalogFiles.hasOwnProperty(fileName)) {
        newQuarantinedFiles[fileName] = newCatalogFiles[fileName];
        newQuarantinedFiles[fileName].quarantine = "quarantined";
        newQuarantinedFiles[fileName].custom_sensitivity = [
          "Yes",
          constructSensitivityString(),
        ];
        delete newCatalogFiles[fileName];
      }
    });

    setCatalogFiles(newCatalogFiles);
    setQuarantinedFiles(newQuarantinedFiles);
    await updateCatalog(preferences.system.QUARANTINECATALOG, quarantinedFiles);
    await updateCatalog(usedCatalog, catalogFiles);
    clearAllFilters();
  };

  useEffect(() => {
    (async () => {
      const catalogDataFiltered = await Workers.invoke(
        "getCatalogDataFiltered",
        {
          catalogFiles,
          searchDetails,
          searchTerm,
          selectedFilters,
        },
      );
      setCurrentDataGroup(catalogDataFiltered);
    })();
  }, [
    searchTerm,
    selectedFilters,
    catalogFiles,
    setCurrentDataGroup,
    searchDetails,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen("none");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return {
    mappings,
    setMappings,
    quarantineFiles,
    standardizeValues,
    handleResetFilter,
    handleFilterChange,
    noOfDocumentsWithFailedTags,
    lastFetchedTimeRef,
    semanticSimilarity,
    setSemanticSimilarity,
    dropdownOpen,
    setDropdownOpen,
    standardizationLoading,
    setStandardizationLoading,
    standardizeMinCategories,
    setStandardizeMinCategories,
    standardizeSensitivity,
    setStandardSensitivity,
    standardizeScreen,
    setStandardizeScreen,
    showStandardization,
    setShowStandardization,
    answerLoading,
    setAnswerLoading,
    standardizeTag,
    setStandardizeTag,
    clearAllFilters,
  };
}
