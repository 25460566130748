import React, { useState, useEffect, useContext } from "react";
import "./AccessControlGraph.css";
import { Bar } from "react-chartjs-2";
import { DataContext } from "../../../../../../../context/DataContext";

export default function AccessControlGraph(props) {
  const { availableTags } = useContext(DataContext);
  const [chartData, setChartData] = useState({
    sensitivity: { labels: [], datasets: [] },
    risk: { labels: [], datasets: [] },
    accessGroup: { labels: [], datasets: [] },
  });

  useEffect(() => {
    if (props.filteredFiles && Object.keys(props.filteredFiles).length > 0) {
      const sensitivityTags = Object.keys(
        availableTags.sensitivity.tagger_params.tag_dict
      );
      let sensitivityCounts = new Map(sensitivityTags.map((key) => [key, 0]));
      let riskCounts = new Map();
      let accessGroupCounts = new Map();

      Object.values(props.filteredFiles).forEach((fileData) => {
        let fileSensitivityTags = new Set();
        let fileRiskLevels = new Set();
        let fileAccessGroups = new Set();

        if (
          fileData.hasOwnProperty("chunks") &&
          typeof fileData.chunks === "object"
        ) {
          Object.values(fileData.chunks).forEach((chunk) => {
            Object.keys(chunk).forEach((tagKey) => {
              if (chunk[tagKey] && chunk[tagKey][0] === "Yes") {
                fileSensitivityTags.add(tagKey);
                const riskLevel = chunk[tagKey][3];
                if (riskLevel) {
                  fileRiskLevels.add(riskLevel);
                }
              }
            });
          });
        }

        let accessGroups = fileData.access_group;
        if (typeof accessGroups === "string") {
          accessGroups = accessGroups
            .split(",")
            .map((group) => group.trim().toLowerCase());
          accessGroups.forEach((group) => fileAccessGroups.add(group));
        } else if (Array.isArray(accessGroups)) {
          accessGroups
            .map((group) => group.trim().toLowerCase())
            .forEach((group) => fileAccessGroups.add(group));
        }

        fileSensitivityTags.forEach((tag) => {
          sensitivityCounts.set(tag, (sensitivityCounts.get(tag) || 0) + 1);
        });
        fileRiskLevels.forEach((level) => {
          riskCounts.set(level, (riskCounts.get(level) || 0) + 1);
        });
        fileAccessGroups.forEach((group) => {
          accessGroupCounts.set(group, (accessGroupCounts.get(group) || 0) + 1);
        });
      });

      const calculatePercentages = (countsMap, totalCount) => {
        return Array.from(countsMap.entries()).map(([key, count]) => ({
          label: key,
          value: ((count / totalCount) * 100).toFixed(2),
        }));
      };

      setChartData({
        sensitivity: {
          labels: [...sensitivityCounts.keys()],
          datasets: [
            {
              label: "Percentage (%)",
              data: calculatePercentages(
                sensitivityCounts,
                Object.keys(props.filteredFiles).length
              ).map((d) => d.value),
              backgroundColor: "rgba(54, 162, 235, 0.6)",
            },
          ],
        },
        risk: {
          labels: [...riskCounts.keys()],
          datasets: [
            {
              label: "Percentage (%)",
              data: calculatePercentages(
                riskCounts,
                Object.keys(props.filteredFiles).length
              ).map((d) => d.value),
              backgroundColor: "rgba(255, 159, 64, 0.6)",
            },
          ],
        },
        accessGroup: {
          labels: [...accessGroupCounts.keys()],
          datasets: [
            {
              label: "Percentage (%)",
              data: calculatePercentages(
                accessGroupCounts,
                Object.keys(props.filteredFiles).length
              ).map((d) => d.value),
              backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
          ],
        },
      });
    }
  }, [props.filteredFiles, availableTags]);

  const barChartOptions = {
    indexAxis: "y",
    scales: {
      x: { title: { display: true, text: "Percentage" } },
      y: { title: { display: true, text: "Categories" } },
    },
    plugins: { legend: { display: true, position: "top" } },
  };

  return (
    <div className="grid grid-cols-1 grid-rows-3 h-full w-full">
      <div className="flex flex-col bg-white w-full object-cover h-full">
        <h2 className="p-4 bg-slate-200 text-m ">Sensitivity Distribution</h2>
        <p></p>
        {chartData.sensitivity.labels.length > 0 ? (
          <Bar
            data={chartData.sensitivity}
            options={barChartOptions}
            className="chart-canvas  px-2"
          />
        ) : (
          <div className="w-full h-full justify-center items-center text-center align-center flex flex-col">
            <p className="text-lg text-gray-600 px-4 py-2">No data available</p>
          </div>
        )}
      </div>

      <div className="flex flex-col bg-white w-full object-fit  h-full">
        <h2 className="p-4 bg-slate-200 text-m ">Risk Distribution</h2>
        {chartData.risk.labels.length > 0 ? (
          <Bar
            data={chartData.risk}
            options={barChartOptions}
            className="chart-canvas  px-2 justify-center"
          />
        ) : (
          <div className="w-full h-full justify-center items-center text-center align-center flex flex-col">
            <p className="text-lg text-gray-600 px-4 py-2">No data available</p>
          </div>
        )}
      </div>
      <div className="flex flex-col bg-white w-full object-fit  h-full">
        <h2 className="p-4 bg-slate-200 text-m">Access Group Distribution</h2>
        {chartData.accessGroup.labels.length > 0 ? (
          <Bar
            data={chartData.accessGroup}
            options={barChartOptions}
            className="chart-canvas  px-2"
          />
        ) : (
          <div className="w-full h-full justify-center items-center text-center align-center flex flex-col">
            <p className="text-lg text-gray-600 px-4 py-2">No data available</p>
          </div>
        )}
      </div>
    </div>
  );
}
