import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import InfoIcon from "../../../../utilities/InfoIcon/InfoIcon";

const ValidationScoreCard = ({ tag, evaluatingTag, evaluatedScores }) => {
  const [score, setScore] = useState(null);
  const radius = 35;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (evaluatedScores.hasOwnProperty(tag.UUID) && tag.UUID) {
      setScore(evaluatedScores[tag.UUID]);
    } else if (evaluatedScores.hasOwnProperty(tag.name)) {
      setScore(evaluatedScores[tag.name]);
    } else {
      setScore(null);
    }
  }, [evaluatedScores, tag.UUID, tag.name]);

  const getStrokeDashoffset = (score) => {
    const normalizedScore = score > 1 ? score / 100 : score;
    return circumference - normalizedScore * circumference;
  };

  const displayScore = (score) => {
    return score > 1 ? Math.round(score) : Math.round(score * 100);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-xl shadow-inner p-3 max-w-xs mx-auto"
    >
      <div className="w-full justify-between flex flex-row items-center">
        <InfoIcon
          infoText={
            "Deasie calculates a validation score by comparing user-validated input-output examples against the expected results for a specific tag version. This score reflects the accuracy of the tag based on correct and incorrect validations."
          }
        />

        <h2 className="text-sm font-semibold text-gray-800 ml-1 mr-1 mb-3 pt-2">
          Validation Score
        </h2>
      </div>
      <div className="relative flex justify-center items-center">
        {evaluatingTag === tag.UUID | evaluatingTag === tag.name ? (
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="w-12 h-12 border-t-4 border-primary border-solid rounded-full"
          />
        ) : score !== null ? (
          <div className="relative w-20 h-20">
            <svg className="w-full h-full transform -rotate-90">
              <circle
                className="text-gray-200"
                strokeWidth="6"
                stroke="currentColor"
                fill="transparent"
                r={radius}
                cx="50%"
                cy="50%"
              />
              <motion.circle
                className="text-primary"
                strokeWidth="5"
                strokeDasharray={circumference}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r={radius}
                cx="50%"
                cy="50%"
                initial={{ strokeDashoffset: circumference }}
                animate={{ strokeDashoffset: getStrokeDashoffset(score) }}
                transition={{ duration: 1, ease: "easeInOut" }}
              />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <motion.span
                className="text-lg font-bold text-gray-800"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                {displayScore(score)}%
              </motion.span>
            </div>
          </div>
        ) : (
          <div className="pt-4 mb-4">
            <p className="text-md text-gray-600">Not validated</p>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ValidationScoreCard;
