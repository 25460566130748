import { useState, useContext, useMemo } from "react";
import { ProfileComponent } from "./PreferencesComponents/ProfileComponent";
import { CatalogComponent } from "./PreferencesComponents/CatalogComponent";
import { UserTokenUsageComponent } from "./PreferencesComponents/UserTokenUsageComponent";
import { DataContext } from "../../../../../context/DataContext";
import { savePreferences } from "../../../../utilities/functions/apiCalls";
import { toast } from "../../../../utilities/Toast";
import { useUserProfile } from "../../../../../context/UserProfile";
import { TeamTokenUsageComponent } from "./PreferencesComponents/TeamTokenUsageComponent";

const tabMapping = {
  profile: "Tag settings",
  hidden_tags: "Hidden Tags",
  webapp_profile: "Data Connectors",
  catalogs: "Catalogs",
  token_usage: "Usage",
};

export default function UserPreferences({ onSubmit, visibleTabs }) {
  const { permissions } = useUserProfile();
  const { preferences, setPreferences } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState("profile");
  const [isSaving, setIsSaving] = useState(false);
  const [currentFormValues, setCurrentFormValues] = useState(preferences);

  const availableTabs = useMemo(() => {
    const basicTabs = [
      "profile",
      "hidden_tags",
      "webapp_profile",
      "catalogs",
      "token_usage",
    ];

    if (permissions.teams.canView) {
      basicTabs.push("team_usage");
    }

    return basicTabs.filter((tab) => visibleTabs?.includes(tab) ?? true);
  }, [permissions, visibleTabs]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "profile":
      case "hidden_tags":
      case "webapp_profile":
        return (
          <ProfileComponent
            activeTab={activeTab}
            currentFormValues={currentFormValues}
            setCurrentFormValues={setCurrentFormValues}
          />
        );
      case "catalogs":
        return <CatalogComponent />;
      case "token_usage":
        return <UserTokenUsageComponent />;
      case "team_usage":
        return <TeamTokenUsageComponent />;
      default:
        return <div>Select a tab</div>;
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      onSubmit(currentFormValues);
      return;
    }

    setIsSaving(true);
    const updatedPreferences = JSON.parse(JSON.stringify(currentFormValues));
    try {
      await savePreferences(updatedPreferences);
      toast.success({
        title: "Preferences saved successfully.",
        description: "",
      });
      setPreferences(updatedPreferences);
    } catch (error) {
      toast.error({
        title: `Saving preferences failed: ${error}`,
        description: "",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col border-none rounded-lg p-4 bg-gray-100 shadow-md w-[60vw] h-[calc(95vh-100px)] overflow-hidden">
      <div className="bg-white p-3 rounded-lg shadow-md flex flex-col flex-1 overflow-hidden">
        <div className="flex mb-5 space-x-1 overflow-auto whitespace-nowrap justify-between">
          <div className="gap-2 flex flex-row">
            {availableTabs.map((tab) => (
              <button
                key={tab}
                className={`px-5 py-2 text-md ${activeTab === tab ? "bg-white border-2 border-primary text-primary font-bold rounded-md" : "bg-gray-200"} hover:bg-primary transition-all duration-150 hover:text-white hover:font-bold focus:outline-none rounded-md`}
                onClick={() => setActiveTab(tab)}
              >
                {tabMapping[tab]}
              </button>
            ))}
          </div>
          <div>
            {activeTab !== "catalogs" &&
              activeTab !== "token_usage" &&
              activeTab !== "team_usage" && (
                <button
                  className={`px-5 py-2 ${isSaving ? "bg-gray-400" : "bg-primary hover:bg-deasieTurquoise hover-text-white"} text-white rounded-lg shadow-lg disabled:bg-gray-400`}
                  onClick={handleSubmit}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save Preferences"}
                </button>
              )}
          </div>
        </div>
        <div className="flex-1 overflow-auto">{renderActiveTab()}</div>
      </div>
    </div>
  );
}
