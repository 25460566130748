import { set } from "date-fns";
import React, { useState } from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DataStoreTable({ initialData, onDataChange }) {
  const finalClasses =
    "bg-white border-gray-200 form-input block w-full mt-1 border-2 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-base";
  const connectorTypes = ["s3", "azureblob", "sharepoint"];
  const [tableData, setTableData] = useState(initialData);
  const [newS3Title, setNewS3Title] = useState("");
  const [newAzureTitle, setNewAzureTitle] = useState("");
  const [newSharepointTitle, setNewSharepointTitle] = useState("");

  const baseConnectorBodies = {
    s3: {
      storage: {
        name: "",
        type: "s3",
        credentials: {
          access_key_id: "",
          secret_access_key: "",
        },
      },
      base_path: "",
      name: "s3",
    },
    azureblob: {
      storage: {
        name: "",
        type: "azureblob",
        credentials: {
          tenant_id: "",
          client_id: "",
          client_secret: "",
        },
      },
      base_path: "",
      name: "azure",
    },
    sharepoint: {
      storage: {
        name: "",
        type: "sharepoint",
        credentials: {
          client_id: "",
          client_secret: "",
          sharepoint_url: "",
        },
      },
      base_path: "",
      name: "",
    },
  };

  const connectorDisplayNames = {
    s3: "S3",
    azureblob: "Azure Blob",
    sharepoint: "Sharepoint",
  };

  const headers = {
    s3: [
      "display_name",
      "bucket_name",
      "base_path",
      "access_key_id",
      "secret_access_key",
    ],
    azureblob: [
      "display_name",
      "storage_account",
      "storage_container",
      "tenant_id",
      "client_id",
      "client_secret",
    ],
    sharepoint: [
      "display_name",
      "site_name",
      "folder_path",
      "client_id",
      "client_secret",
      "sharepoint_url",
    ],
  };
  const getDisaplayedValue = (header, key, value, connectorType) => {
    if (header === "display_name") {
      return key;
    }
    if (connectorType === "s3") {
      switch (header) {
        case "bucket_name":
          return value["storage"]["name"];
        case "base_path":
          return value["base_path"];
        case "access_key_id":
          return value["storage"]["credentials"]["access_key_id"];
        case "secret_access_key":
          return value["storage"]["credentials"]["secret_access_key"];
      }
    } else if (connectorType === "azureblob") {
      switch (header) {
        case "storage_account":
          return value["storage"]["name"];
        case "storage_container":
          return value["base_path"];
        case "tenant_id":
          return value["storage"]["credentials"]["tenant_id"];
        case "client_id":
          return value["storage"]["credentials"]["client_id"];
        case "client_secret":
          return value["storage"]["credentials"]["client_secret"];
      }
    } else if (connectorType === "sharepoint") {
      switch (header) {
        case "site_name":
          return value["storage"]["name"];
        case "folder_path":
          return value["base_path"];
        case "client_id":
          return value["storage"]["credentials"]["client_id"];
        case "client_secret":
          return value["storage"]["credentials"]["client_secret"];
        case "sharepoint_url":
          return value["storage"]["credentials"]["sharepoint_url"];
      }
    }
  };

  const updateTableData = (header, key, connectorType, e) => {
    let newData = { ...tableData };
    if (connectorType === "s3") {
      switch (header) {
        case "bucket_name":
          newData[key]["storage"]["name"] = e.target.value;
          break;
        case "base_path":
          newData[key]["base_path"] = e.target.value;
          break;
        case "access_key_id":
          newData[key]["storage"]["credentials"]["access_key_id"] =
            e.target.value;
          break;
        case "secret_access_key":
          newData[key]["storage"]["credentials"]["secret_access_key"] =
            e.target.value;
          break;
      }
    } else if (connectorType === "azureblob") {
      switch (header) {
        case "storage_account":
          newData[key]["storage"]["name"] = e.target.value;
          break;
        case "storage_container":
          newData[key]["base_path"] = e.target.value;
          break;
        case "tenant_id":
          newData[key]["storage"]["credentials"]["tenant_id"] = e.target.value;
          break;
        case "client_id":
          newData[key]["storage"]["credentials"]["client_id"] = e.target.value;
          break;
        case "client_secret":
          newData[key]["storage"]["credentials"]["client_secret"] =
            e.target.value;
          break;
      }
    } else if (connectorType === "sharepoint") {
      switch (header) {
        case "site_name":
          newData[key]["storage"]["name"] = e.target.value;
          break;
        case "folder_path":
          newData[key]["base_path"] = e.target.value;
          break;
        case "client_id":
          newData[key]["storage"]["credentials"]["client_id"] = e.target.value;
          break;
        case "client_secret":
          newData[key]["storage"]["credentials"]["client_secret"] =
            e.target.value;
          break;
        case "sharepoint_url":
          newData[key]["storage"]["credentials"]["sharepoint_url"] =
            e.target.value;
          break;
      }
    }
    setTableData(newData);
    onDataChange(newData);
  };

  const addRow = (connectorType) => {
    let newData = { ...tableData };
    if (connectorType === "s3") {
      newData[newS3Title] = baseConnectorBodies[connectorType];
      newData[newS3Title]["name"] = newS3Title;
      setNewS3Title("");
    } else if (connectorType === "azureblob") {
      newData[newAzureTitle] = baseConnectorBodies[connectorType];
      newData[newAzureTitle]["name"] = newAzureTitle;
      setNewAzureTitle("");
    } else if (connectorType === "sharepoint") {
      newData[newSharepointTitle] = baseConnectorBodies[connectorType];
      newData[newSharepointTitle]["name"] = newSharepointTitle;
      setNewSharepointTitle("");
    }
    setTableData(newData);
  };

  const deleteRow = (key) => {
    let newData = { ...tableData };
    delete newData[key];
    setTableData(newData);
    onDataChange(newData);
  };

  const filterTableData = (data, keyMatch) => {
    return Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value["storage"]["type"] === keyMatch,
      ),
    );
  };

  const updateInputField = (e, connectorType) => {
    const value = e.target.value;
    if (connectorType === "s3") {
      setNewS3Title(value);
    } else if (connectorType === "azureblob") {
      setNewAzureTitle(value);
    } else if (connectorType === "sharepoint") {
      setNewSharepointTitle(value);
    }
  };

  const getInputValue = (connectorType) => {
    if (connectorType === "s3") {
      return newS3Title;
    } else if (connectorType === "azureblob") {
      return newAzureTitle;
    } else if (connectorType === "sharepoint") {
      return newSharepointTitle;
    }
    return "";
  };

  return (
    <div className="overflow-x-auto flex flex-col flex-1 p-4">
      {connectorTypes.map((connectorType) => {
        return (
          <div className="border-2 m-2 border-grey-700 rounded-md p-4">
            <table className="divide-y divide-gray-300 min-w-full">
              <thead>
                {connectorDisplayNames[connectorType] || ""}
                <tr className="bg-gray-100">
                  {headers[connectorType].map((header) => (
                    <th
                      key={header}
                      className="py-2 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300 ">
                {Object.entries(filterTableData(tableData, connectorType)).map(
                  ([key, value]) => (
                    <tr>
                      {headers[connectorType].map((header) => {
                        const displayedValue = getDisaplayedValue(
                          header,
                          key,
                          value,
                          connectorType,
                        );
                        return (
                          <td
                            key={header}
                            className={`px-2 py-3 whitespace-nowrap text-sm text-gray-700 bg-white hover:bg-gray-50 w-auto`}
                          >
                            <input
                              type="text"
                              className={`${finalClasses} ${header === "display_name" && "cursor-not-allowed pointer-events-none opacity-50"}`}
                              value={displayedValue}
                              onChange={(e) =>
                                updateTableData(header, key, connectorType, e)
                              }
                            />
                          </td>
                        );
                      })}
                      <td>
                        <button
                          className="text-grey hover:text-red-700"
                          onClick={() => deleteRow(key)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
            <div className="mb-8">
              <input
                className="bg-white border-gray-200 mt-1 border-2 rounded-lg shadow py-2 text-base me-2 p-3"
                placeholder={`Enter name for new ${connectorType}`}
                value={getInputValue(connectorType)}
                onChange={(e) => updateInputField(e, connectorType)}
              />
              <button
                className="mt-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded shadow-sm"
                onClick={() => addRow(connectorType)}
                disabled={!getInputValue(connectorType)}
              >
                Add Connection
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DataStoreTable;
