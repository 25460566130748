import React, { useState, useEffect, useContext } from "react";
import "./AccessControls.css"; // Import the CSS file here
import QuarantineList from "../Components/QuarantineList/QuarantineList";
import AccessControlGraph from "../Components/AccessControlGraph/AccessControlGraph";
import { DataContext } from "../../../../../../context/DataContext";

export default function AccessControl(props) {
  const { availableTags, quarantinedFiles, preferences } =
    useContext(DataContext);

  const [sensitivityRules, setSensitivityRules] = useState(
    Object.keys(preferences.system.SENSITIVITY_TAGS)
  );

  const [filteredFiles, setFilteredFiles] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sensitivityFilter, setSensitivityFilter] = useState("");
  const [riskFilter, setRiskFilter] = useState("");
  const [reviewFilter, setReviewFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [redactions, setRedactions] = useState({});

  useEffect(() => {
    let newFilteredFiles = Object.entries(quarantinedFiles);

    if (searchTerm) {
      newFilteredFiles = newFilteredFiles.filter(([fileName, _]) =>
        fileName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sensitivityFilter) {
      newFilteredFiles = newFilteredFiles.filter(([_, fileDetails]) => {
        return (
          fileDetails.hasOwnProperty("chunks") &&
          fileDetails.chunks &&
          Object.values(fileDetails.chunks).some(
            (chunk) =>
              chunk[sensitivityFilter] && chunk[sensitivityFilter][0] === "Yes"
          )
        );
      });
    }

    if (riskFilter) {
      newFilteredFiles = newFilteredFiles.filter(([_, fileDetails]) => {
        return (
          fileDetails.hasOwnProperty("chunks") &&
          fileDetails.chunks &&
          Object.values(fileDetails.chunks).some((chunk) => {
            return Object.keys(chunk).some((key) => {
              return chunk[key][0] === "Yes" && chunk[key][3] === riskFilter;
            });
          })
        );
      });
    }

    if (reviewFilter !== "") {
      const reviewFilterBool = reviewFilter === "true";

      newFilteredFiles = newFilteredFiles.filter(([_, fileDetails]) => {
        return (
          fileDetails.hasOwnProperty("chunks") &&
          fileDetails.chunks &&
          Object.values(fileDetails.chunks).every((chunk) => {
            const isReviewed = chunk.hasOwnProperty("reviewed")
              ? chunk.reviewed
              : null;
            if (reviewFilter === "false") {
              return isReviewed === false || isReviewed === null;
            }
            return isReviewed === reviewFilterBool;
          })
        );
      });
    }

    const filteredObject = newFilteredFiles.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );

    setFilteredFiles(filteredObject);
  }, [
    quarantinedFiles,
    searchTerm,
    sensitivityFilter,
    riskFilter,
    reviewFilter,
  ]);

  return (
    <div className="flex gap-4 h-[90vh] w-[95vw] dark:bg-zinc-800 rounded-md">
      <QuarantineList
        filteredFiles={filteredFiles}
        setFilteredFiles={setFilteredFiles}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sensitivityFilter={sensitivityFilter}
        setSensitivityFilter={setSensitivityFilter}
        riskFilter={riskFilter}
        setRiskFilter={setRiskFilter}
        reviewFilter={reviewFilter}
        setReviewFilter={setReviewFilter}
        searchText={searchText}
        setSearchText={setSearchText}
        redactions={redactions}
        setRedactions={setRedactions}
        availableTags={availableTags}
      />
      <div className="flex h-[90vh] w-[40vw] dark:bg-zinc-800 ">
        <AccessControlGraph
          sensitivityRules={sensitivityRules}
          filteredFiles={filteredFiles}
          setSensitivityRules={setSensitivityRules}
        />
      </div>
    </div>
  );
}
