import { useCallback, useEffect, useState } from "react";

export const usePersistedState = <T>(storageKey: string, defaultValue: T) => {
  const [data, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const localStoredFilters = localStorage.getItem(`deasie-${storageKey}`);
    let parsedValue: T;

    try {
      parsedValue = localStoredFilters
        ? JSON.parse(localStoredFilters)
        : defaultValue;
    } catch (e) {
      return;
    }

    setValue(parsedValue);
  }, [storageKey]);

  const storeValue = useCallback(
    (value: T | ((old: T) => T)) => {
      localStorage.setItem(`deasie-${storageKey}`, JSON.stringify(value));
      setValue(value);
    },
    [setValue, storageKey],
  );

  return [data, storeValue] as const;
};
