import Auth from "../../../auth/AuthProvider";
import { useEffect, useState } from "react";
import { sendRequest } from "../functions/api";
import { ENDPOINTS } from "../../../api/endpoints";
import TaskTrackingDashboardRow from "./TaskTrackingDashboardRow";
import { IoIosRefresh } from "react-icons/io";
import { useLocation } from "react-router-dom";

export default function TaskTrackingDashboard() {
    const location = useLocation();
    const [activeTasksStatuses, setActiveTasksStatuses] = useState([]);
    const [queuedTasksStatuses, setQueuedTasksStatuses] = useState([]);
    const [scheduledTasksStatuses, setSheduledTasksStatuses] = useState([]);
    const [pageInitialLoad, setPageInitialLoad] = useState(true);

    const triggerLongTask = async () => {
        const username = (await Auth.currentAuthenticatedUser()).username;
        await sendRequest({ username }, ENDPOINTS["trigger_long_task"]);
    };

    const getTasksStatus = async () => {
        const creds = await Auth.currentAuthenticatedUser();
        const username = creds.username;
        let user_email = username;
        const authProvider = process.env.REACT_APP_AUTH_PROVIDER ?? "AWS";
        if (authProvider === "AWS" && creds.attributes.email) {
            user_email = creds.attributes.email;
        } else if (authProvider === "Azure") {
            user_email = creds.idTokenClaims.preferred_username
        } else if (authProvider === "GCP") {} // TODO: see how to extract email for GCP

        const response = await sendRequest({ username, user_email }, ENDPOINTS["get_tasks_status"]);
        if (response === undefined) {
            setActiveTasksStatuses([]);
            setQueuedTasksStatuses([]);
            setSheduledTasksStatuses([]);
            return;
        }
        const json = await response.json();
        setActiveTasksStatuses(json === undefined || json.activeTasks === undefined ? [] : json.activeTasks);
        setQueuedTasksStatuses(json === undefined || json.queuedTasks === undefined ? [] : json.queuedTasks);
        setSheduledTasksStatuses(json === undefined || json.scheduledTasks === undefined ? [] : json.scheduledTasks);
    };
    const [refreshClicked, setRefreshClicked] = useState(false);

    const REFRESH_INTERVAL = 10000; // milliseconds
    useEffect(() => {
        setPageInitialLoad(true);

        const fetchTasksStatus = async () => {
            await getTasksStatus();
        };
        // Fetch tasks status immediately
        fetchTasksStatus().then(() => {
            console.log("Tasks status fetched");
            setPageInitialLoad(false);
        });
        const intervalId = setInterval(fetchTasksStatus, REFRESH_INTERVAL);
    
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [location]);

    return (
        <div className="flex w-full h-full gap-4 flex-row">
            <div className={`flex flex-col rounded-md w-full h-auto overflow-hidden bg-white`}>
                {pageInitialLoad ? (
                    <div className="h-full shrink-0 grow-0 flex flex-col bg-white rounded-md overflow-hidden justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
                        <p className="mt-4 text-lg font-semibold">
                        Retrieving ...
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-end space-x-2 me-2">
                            {/* <button
                                onClick={triggerLongTask}
                                className="bg-deasieTurquoise rounded-lg hover:opacity-50 text-white font-bold p-2 m-2 w-auto"
                            >
                                Trigger Long Task
                            </button> */}
                            {!refreshClicked ? (
                                <button
                                    onClick={async () => {
                                        setRefreshClicked(true);
                                        await getTasksStatus();
                                        setRefreshClicked(false);
                                    }}
                                    className="border border-primary rounded-lg w-auto hover:opacity-50 text-primary font-bold p-2 m-2 h-12 flex items-center justify-center"
                                >
                                    Refresh <IoIosRefresh className="text-2xl ml-2"/>
                                </button>) : (
                                <div className="border border-primary rounded-lg text-primary font-bold p-2 m-2 w-auto h-12 flex items-center justify-center">
                                    Refresh <div className="ml-2 animate-spin rounded-full h-4 w-4 border-b-2 border-primary" />
                                </div>)}
                        </div>
                        <div className="flex-grow flex flex-col" style={{ height: '70vh'}}>
                            <div className="overflow-auto flex-grow">
                                <table className="w-full rounded-lg text-sm text-left border border-gray-300">
                                    <thead className="text-xs uppercase">
                                        <tr>
                                            <th
                                            scope="col"
                                            className="px-6 p-3 top-0 sticky bg-gray-100 w-10 z-40"
                                            >
                                                Username
                                            </th>
                                            <th
                                            scope="col"
                                            className="px-6 p-3 top-0 sticky bg-gray-100 w-10 z-40"
                                            >
                                                Deasie Action
                                            </th>
                                            <th
                                            scope="col"
                                            className="px-6 p-3 top-0 sticky bg-gray-100 w-10 z-40"
                                            >
                                                Status
                                            </th>
                                            <th
                                            scope="col"
                                            className="px-6 p-3 top-0 sticky bg-gray-100 w-10 z-40"
                                            >
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="overflow-y-auto flex-grow">
                                        {activeTasksStatuses.map((task, index) =>    <TaskTrackingDashboardRow index={index} task={task} isActive={true} /> )}
                                        {queuedTasksStatuses.map((task, index) =>    <TaskTrackingDashboardRow index={index} task={task} /> )}
                                        {scheduledTasksStatuses.map((task, index) => <TaskTrackingDashboardRow index={index} task={task} isScheduled={true} />)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
