import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Select, MenuItem } from "@mui/material";
import { DataContext } from "../../../../../context/DataContext";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import { CgSpinner } from "react-icons/cg";
import { TagContext } from "../../../../../context/TagContext";
import {
  useCatalogDocumentInfoLoader,
  useFolderEntriesAvailable,
} from "../../../../../api/queryHooks";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../atoms";
import { DocumentViewer } from "../../../../utilities/DocumentViewer/DocumentViewer";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import Auth from "../../../../../auth/AuthProvider";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import { motion } from "framer-motion";
import FolderTabsComponent from "./FolderTabs.js";
import { update } from "react-spring";
import { customStyleForSelect } from "../../../../utilities/SearchBar/SearchBar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function TaggingStudio({ setPreviousExamples }) {
  const {
    currentTag,
    setCurrentTag,
    usedCatalog,
    showFileOnPage,
    setShowFileOnPage,
    setValidatingTag,
    validatedScores,
    setValidatedScores,
    catalogFiles,
  } = useContext(DataContext);

  const {
    tagStudioLoadingStatus,
    setTagStudioLoadingStatus,
    tagStudioCheckedItems,
    tagStudioResponses,
    setTagStudioResponses,
    setTagStudioCheckedItems,
    handleTagTest,
    handleChunkTagTest,
    tagStudioAbortTaskSignal,
  } = useContext(TagContext);

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [userResponses, setUserResponses] = useState(null);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();
  const [customFilteredFiles, setCustomFilteredFiles] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [isLoadingFolderTabs, setIsLoadingFolderTabs] = useState(true);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isTestRunning, setIsTestRunning] = useState(false);

  const { data: folderEntryByStorageKind, isLoadingFolderEntires } =
    useFolderEntriesAvailable();
  const availableStorageKinds = useMemo(
    () =>
      Object.keys(folderEntryByStorageKind ?? {}).filter(
        (storageKind) =>
          Object.keys(folderEntryByStorageKind[storageKind]).length > 0,
      ),
    [folderEntryByStorageKind],
  );

  const [selectedStorageKind, setSelectedStorageKind] = useState(
    () => availableStorageKinds[0] || "",
  );
  const selectedFolderEntry = useMemo(
    () => folderEntryByStorageKind?.[selectedStorageKind] ?? {},
    [folderEntryByStorageKind, selectedStorageKind],
  );

  const [customFilteredKeys, setCustomFilteredKeys] = useState([]);

  const filteredFolderKeys = useMemo(() => {
    return customFilteredKeys.length > 0
      ? customFilteredKeys
      : Object.keys(selectedFolderEntry).filter(
        (folderPath) =>
          folderPath.toLowerCase().includes(searchText.toLowerCase()) ||
          selectedFolderEntry[folderPath]?.some((file) =>
            file.toLowerCase().includes(searchText.toLowerCase()),
          ),
      );
  }, [selectedFolderEntry, searchText, customFilteredKeys]);

  const handleCustomFilterUpdate = (newKeys) => {
    setCustomFilteredKeys(newKeys);
  };

  useEffect(() => {
    if (tagStudioResponses && Object.keys(tagStudioResponses).length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [tagStudioResponses]);

  const handleValidationResponse = (chunkId, value, isYes, file_ref) => {
    setUserResponses((prevResponses) => {
      const updatedFileRef = file_ref ? { ...file_ref } : null;

      if (updatedFileRef && updatedFileRef.file_directory) {
        const parts = updatedFileRef.file_directory.split("/");
        updatedFileRef.file_directory = parts.slice(1).join("/");

        if (!updatedFileRef.file_directory.endsWith("/")) {
          updatedFileRef.file_directory += "/";
        }
      }
      return {
        ...prevResponses,
        [chunkId]: {
          response: isYes ? "Yes" : "No",
          value: value,
          file_ref: updatedFileRef,
        },
      };
    });
  };

  const viewEvidence = async (file, folderKey, evidence, chunk_key) => {
    if (!folderKey || !file || !evidence) {
      console.error("Missing required parameters for viewing evidence");
      return;
    }

    try {
      const documentInfo = await loadDocumentInfo(
        file,
        selectedStorageKind,
        folderKey,
      );

      const catalogEntry = catalogFiles[file];

      if (
        documentInfo.file_name?.toLowerCase().endsWith(".pdf") ||
        documentInfo.file_name?.toLocaleLowerCase().endsWith(".docx")
      ) {
        setPdf(documentInfo.file_url.toString());
        setPdfSearch(
          JSON.stringify([
            {
              evidence: evidence,
              chunk_index: [chunk_key],
              page_count: catalogEntry.page_count || null,
              total_size: catalogEntry.total_size,
            },
          ]),
        );
      } else {
        window.open(documentInfo.file_url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching the document", error);
    }
  };

  useEffect(() => {
    if (
      selectedStorageKind ||
      isLoadingFolderEntires ||
      !availableStorageKinds
    ) {
      return;
    }

    setSelectedStorageKind(availableStorageKinds[0]);
  }, [availableStorageKinds, isLoadingFolderEntires, selectedStorageKind]);

  useEffect(() => {
    setTagStudioResponses([]);
    setTagStudioLoadingStatus({});
  }, [setTagStudioLoadingStatus, setTagStudioResponses]);

  const updateValidationRecords = async () => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        tag_UUID: currentTag.UUID ? currentTag.UUID : "",
        tag_name: currentTag.name,
        catalog_name: usedCatalog,
        [API_USERNAME_KEYWORD]: creds,
        validation_records: userResponses,
      };
      await sendRequest(sendDetails, ENDPOINTS["update_validation_records"]);
    } catch (error) {
      console.error("Error updating validation records:", error);
    }
  };

  const handleConfirmButtonClick = async () => {
    const yesResponses = Object.entries(userResponses).filter(
      ([_, { response, evidence }]) => response === "Yes" && evidence !== "",
    );
    const noResponses = Object.entries(userResponses).filter(
      ([_, { response, evidence }]) => response === "No" && evidence !== "",
    );

    const newExamples = yesResponses.map(([_, { value, file_ref }]) => ({
      evidence: `${value.evidence}`,
      value: `${value.value}`,
      file_ref: file_ref ?? {},
    }));

    setPreviousExamples(newExamples);

    const newNegExamples = noResponses.map(([_, { value, file_ref }]) => ({
      evidence: `${value.evidence}`,
      value: `${value.value}`,
      file_ref: file_ref ?? {},
    }));

    const existingExamples = Array.isArray(currentTag.examples)
      ? currentTag.examples
      : [];
    const existingNegExamples = Array.isArray(currentTag.neg_examples)
      ? currentTag.neg_examples
      : [];

    setCurrentTag({
      ...currentTag,
      examples: [...existingExamples, ...newExamples],
      neg_examples: [...existingNegExamples, ...newNegExamples],
    });

    setUserResponses({});
    await updateValidationRecords();
    setRefreshTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (currentTag && !validatedScores[currentTag.UUID]) {
      calculateValidationScore();
    }
    setActiveTab(0);
    setRefreshTrigger((prev) => prev + 1);
  }, [currentTag.UUID]);

  const fetchValidationRecords = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const tag_id =
      currentTag.UUID && currentTag.UUID !== ""
        ? currentTag.UUID
        : currentTag.name;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: usedCatalog,
      tag_id: tag_id,
    };
    try {
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_validation_records"],
      );
      if (!response.ok) {
        console.error("Failed to fetch validation records:", response);
        return { folderKeys: [], validation_records: {} };
      }
      const data = await response.json();
      const validation_records = data.validation_records || {};
      return validation_records;
    } catch (error) {
      console.error("Error fetching validation records:", error);
    }
  };

  const calculateValidationScore = async () => {
    setValidatingTag(
      currentTag.UUID && currentTag.UUID !== ""
        ? currentTag.UUID
        : currentTag.name,
    );
    try {
      const records = await fetchValidationRecords();
      let totalCorrect = 0;
      let totalRecords = Object.keys(records).length;

      for (const key in records) {
        const record = records[key];
        if (record.label === "correct") {
          totalCorrect++;
        }
      }
      let validationScore;
      if (totalRecords === 0) {
        validationScore = null;
      } else {
        validationScore =
          totalRecords > 0 ? (totalCorrect / totalRecords) * 100 : 0;
      }
      setValidatedScores((prevScores) => ({
        ...prevScores,
        [currentTag.UUID && currentTag.UUID !== ""
          ? currentTag.UUID
          : currentTag.name]: validationScore,
      }));
    } catch (error) {
      console.error("Error during tag evaluation:", error);
    } finally {
      setValidatingTag(null);
    }
  };

  return (
    <div className="flex flex-col w-full bg-white rounded-md overflow-hidden h-full">
      <div className="flex h-full gap-4">
        <div className="w-full flex flex-col justify-between h-full">
          <div className="overflow-auto mb-2 hide-scrollbar">
            <div className="flex flex-col gap-1 p-4 bg-slate-200 mt-4">
              <header className="text-grey font-bold text-lg">
                1. Select testing data{" "}
              </header>
              <p className="">
                Data selected for testing will not be added to the data catalog
              </p>
            </div>
            <div className="flex flex-col gap-4 p-4">
              <div className="flex gap-4">
                <div className="flex justify-between w-full">
                  <Select
                    value={selectedStorageKind}
                    onChange={(e) => setSelectedStorageKind(e.target.value)}
                    isSearchable={true}
                    className="h-10 w-1/6"
                    styles={customStyleForSelect}
                  >
                    {availableStorageKinds.map((storageKind) => (
                      <MenuItem value={storageKind} key={storageKind}>
                        {storageKind}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex flex-row gap-2">
                    <div className="bg-light opacity-80 w-[5px] h-[5px] p-2 border-2 rounded-md">
                      <div className="bg-light"></div>
                    </div>
                  </div>
                  <p className="text-bold text-grey text-md opacity-80 whitespace-nowrap">
                    Data already in the catalog
                  </p>
                </div>
              </div>
            </div>
            <FolderTabsComponent
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setFilteredFolderKeys={handleCustomFilterUpdate}
              setCustomFilteredFiles={setCustomFilteredFiles}
              folders={selectedFolderEntry}
              refreshTrigger={refreshTrigger}
              setCheckedItems={setTagStudioCheckedItems}
              setIsLoadingFolderTabs={setIsLoadingFolderTabs}
            />
            <div className="flex flex-col justify-between border-r h-[90%]">
              {isLoadingFolderEntires || isLoadingFolderTabs ? (
                <div className="flex justify-center items-center pt-3 h-[30vh]">
                  <CgSpinner className="text-4xl animate-spin" />
                </div>
              ) : (
                <>
                  <div className="w-full flex justify-center items-center overflow-hidden">
                    <FolderList
                      checkedItems={tagStudioCheckedItems}
                      currentFolder={currentFolder}
                      filteredFolderKeys={filteredFolderKeys}
                      folders={selectedFolderEntry}
                      integration={selectedStorageKind}
                      searchText={searchText}
                      setCheckedItems={setTagStudioCheckedItems}
                      setCurrentFolder={setCurrentFolder}
                      setSearchText={setSearchText}
                      filenameFilterList={customFilteredFiles}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-left gap-4 relative h-50 ml-4 pt-2">
            {!isTestRunning && (
                <button
                  className={`text-lg py-2 rounded-md font-bold w-[160px] border-2 ${
                    userResponses &&
                    (Object.keys(tagStudioCheckedItems).length === 0 ||
                      Object.keys(userResponses).length > 0)
                      ? "text-primary border-primary"
                      : "bg-primary text-white"
                  }`}
                  onClick={async () => {
                    if (Object.keys(tagStudioCheckedItems).length > 0) {
                      setUserResponses({});
                      setIsTestRunning(true);
                      tagStudioAbortTaskSignal.current = new AbortController();
                      if (activeTab === 0) {
                        await handleTagTest();
                      } else {
                        await handleChunkTagTest(activeTab);
                      }
                      setIsTestRunning(false);
                    }
                  }}
                  disabled={
                    Object.keys(tagStudioCheckedItems).length === 0 || isTestRunning
                  }
                >
                  Run Test
                </button>
              )}
            {
              isTestRunning && (
                <button
                  className="py-3 px-6 flex flex-row items-center justify-center text-lg"
                  onClick={() => {
                    tagStudioAbortTaskSignal.current.abort();
                    setIsTestRunning(false);
                  }}
                >
                  <p className="text-md bg-red-400 text-white p-2 rounded-md font-bold">
                    Abort All
                  </p>
                </button>
              )}
          </div>
        </div>
        <div className="flex overflow-hidden h-full flex-col w-full">
          {tagStudioResponses && (
            <div className="flex flex-col gap-1 p-4 bg-slate-200 mt-4">
              <header className="text-grey font-bold text-lg">
                2. Validate outputs
              </header>
              <p>View outputs and validate them to fine-tune the tag</p>
            </div>
          )}
          <div className="overflow-auto break-all flex flex-col gap-2 h-full w-full hide-scrollbar">
            {tagStudioLoadingStatus &&
              Object.entries(tagStudioLoadingStatus).map(
                ([documentName, isLoading]) =>
                  isLoading && (
                    <div
                      key={documentName}
                      className="animate-pulse font-small text-gray-700 p-2"
                    >
                      Loading {documentName}...
                    </div>
                  ),
              )}
            {tagStudioResponses &&
              tagStudioResponses.map((catalogItem, catalogIndex) => {
                const documentName = Object.keys(catalogItem.catalog)[0];
                const documentDetails = catalogItem.catalog[documentName];
                const file_ref = {
                  data_store_name: documentDetails.data_store_name[0],
                  file_directory: documentDetails.file_directory[0],
                  file_type: documentDetails.file_type[0],
                  storage_name: documentDetails.storage_name[0],
                  storage_type: documentDetails.storage_type[0],
                  file_name: documentName,
                };
                return (
                  <div
                    key={`catalog-item-${catalogIndex}`}
                    className="p-4 border m-2 rounded-md"
                  >
                    <h3 className="font-semibold">
                      <span className="font-bold text-grey font-md">
                        Data:{" "}
                      </span>
                      <span className="text-grey font-md">{documentName}</span>
                    </h3>
                    {documentDetails.chunks &&
                      Object.entries(documentDetails.chunks).map(
                        ([chunkKey, chunkDetails]) => {
                          return (
                            <div key={chunkKey} className="mt-2">
                              {Object.entries(chunkDetails).map(
                                ([key, value], detailIndex) => {
                                  if (
                                    key === "status" ||
                                    (!Array.isArray(value) &&
                                      typeof value !== "object")
                                  ) {
                                    return null;
                                  }
                                  const displayValue = Array.isArray(value)
                                    ? value[0]
                                    : value.value;
                                  const evidence = Array.isArray(value)
                                    ? value[2]
                                    : value.evidence;

                                  if (
                                    displayValue === "No" ||
                                    displayValue === "Not found"
                                  ) {
                                    return null;
                                  }

                                  const uniqueKey = `${catalogIndex}-${chunkKey}-${detailIndex}`;
                                  return (
                                    <div
                                      className="border rounded-md p-4"
                                      key={uniqueKey}
                                    >
                                      <div className="text-white bg-slate-500 p-2 rounded-md w-fit">
                                        <strong>{currentTag.name}</strong>:{" "}
                                        {displayValue}
                                      </div>
                                      <div
                                        className="mb-4 p-2 border rounded mt-2 cursor-pointer bg-containerLight"
                                        onClick={() =>
                                          viewEvidence(
                                            documentName,
                                            currentFolder,
                                            evidence,
                                            chunkKey,
                                          )
                                        }
                                      >
                                        <strong>Evidence </strong> <br />
                                        {evidence}
                                      </div>
                                      <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2">
                                          <input
                                            type="radio"
                                            name={`response-${uniqueKey}`}
                                            value="Yes"
                                            onChange={() =>
                                              handleValidationResponse(
                                                chunkKey,
                                                value,
                                                true,
                                                file_ref,
                                              )
                                            }
                                          />
                                          <span>Correct</span>
                                        </label>
                                        <label className="flex items-center space-x-2">
                                          <input
                                            type="radio"
                                            name={`response-${uniqueKey}`}
                                            value="No"
                                            onChange={() =>
                                              handleValidationResponse(
                                                chunkKey,
                                                value,
                                                false,
                                                file_ref,
                                              )
                                            }
                                          />
                                          <span>Incorrect</span>
                                        </label>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          );
                        },
                      )}
                  </div>
                );
              })}
          </div>
          <div className="flex flex-row justify-left h-50 ml-4 pt-2">
            <div className="flex flex-col justify-left gap-4 relative">
              {showButton && (
                <>
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{
                      height: showButton ? "auto" : 0,
                      opacity: showButton ? 1 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <button
                      onClick={async () => {
                        await handleConfirmButtonClick();
                        calculateValidationScore();
                      }}
                      className={`w-full text-lg py-2 px-3 rounded-md border-2 font-bold whitespace-nowrap ${
                        userResponses && Object.keys(userResponses).length > 0
                          ? "bg-primary text-white border-primary disabled:opacity-50 disabled:cursor-not-allowed"
                          : "text-primary border-primary"
                      }`}
                      disabled={
                        !userResponses ||
                        Object.keys(userResponses).length === 0 ||
                        isTestRunning
                      }
                    >
                      Fine-tune
                    </button>
                  </motion.div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {pdf && showFileOnPage === "tagStudio" && (
        <DocumentViewer
          url={pdf}
          onClose={() => {
            setPdf("");
            setShowFileOnPage(null);
            setPdfSearch([
              {
                evidence: "",
                chunk_index: "",
                page_count: null,
                total_size: null,
              },
            ]);
          }}
          search={pdfSearch}
        />
      )}
    </div>
  );
}

export default TaggingStudio;
