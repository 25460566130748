export const COLOURS = {
  SubText: "#28A490",
  MainText: "#505E71",
  ContrastText: "rgb(137, 74, 97)",
  SpecialText: "linear-gradient(#46a68f, #30917a, #1a735e)",
  HeaderBackground: "rgba(231, 237, 244, 1)",
  LightGreen: "#39E5BC",
  NeutralGreen: "#28A490",
  DarkGreen: "rgb(68, 141, 118)",
  StrongGreen: "#2f8c75",
};

export const BarChartColours = ["#46a68f", "#30917a", "#1a735e"];

export const greenishColors = [
  '#28A490',
  '#33BFA4',
  '#2FA593',
  '#1D9077',
  '#16806D'
];
