import { TagFilters } from "../context/CatalogContext";
import { CatalogEntryMetadata, CatalogResponse } from "../types";

export const getAdvancedTaggingFilters = (
  catalog: CatalogResponse["catalog"]
) => {
  const response = {} as Record<string, string[]>;
  for (const fileName in catalog) {
    for (const tagKey in catalog[fileName]) {
      const value = catalog[fileName][tagKey];
      const canBeSkipped =
        [
          "chunks",
          "word_count",
          "document_length",
          "file_size",
          "last_modified",
        ].includes(tagKey) ||
        !value ||
        !Array.isArray(value) ||
        value[0] === undefined ||
        value[0] === null;

      if (canBeSkipped) continue;

      response[tagKey] = response[tagKey] || [];
      response[tagKey] = [...new Set([...response[tagKey], ...value])];
    }
  }

  return response;
};

export const getCatalogDataFiltered = ({
  catalogFiles,
  selectedFilters,
  searchDetails,
  searchTerm,
}: {
  catalogFiles: CatalogResponse["catalog"];
  selectedFilters: TagFilters;
  searchTerm: string;
  searchDetails: Record<
    string,
    {
      title: string;
      metadata_search_string: string;
    }
  >;
}) => {
  let data = { ...catalogFiles };

  const matchesSelectedFilters = (
    item:
      | CatalogResponse["catalog"][string]
      | CatalogEntryMetadata["chunks"][string],
    categoryKey: string,
    fileLevel = false
  ) => {
    if (!item || !categoryKey) return false;
    const selectedOptions = selectedFilters[categoryKey];
    if (!selectedOptions || selectedOptions.size === 0) {
      return true;
    }

    const itemValue = item[categoryKey] as unknown as
      | { value: string[] }
      | undefined;

    if (fileLevel && itemValue && Array.isArray(itemValue)) {
      return Array.from(selectedOptions).some((option) =>
        itemValue.includes(option)
      );
    }

    if (!itemValue || !itemValue.value || !Array.isArray(itemValue.value))
      return false;

    return Array.from(selectedOptions).some((option) =>
      itemValue.value.includes(option)
    );
  };

  data = Object.keys(data).reduce((result: CatalogResponse["catalog"], key) => {
    const file = data[key];
    const fileChunks = file.chunks || {};

    // Check if the file has chunks to process
    if (Object.keys(fileChunks).length > 0) {
      let matchingChunks = {} as Record<
        string,
        CatalogEntryMetadata["chunks"][string]
      >;

      Object.keys(fileChunks).forEach((chunkKey) => {
        const chunk = fileChunks[chunkKey];
        if (
          Object.keys(selectedFilters).every((categoryKey) =>
            matchesSelectedFilters(chunk, categoryKey)
          )
        ) {
          matchingChunks[chunkKey] = chunk;
        }
      });

      if (Object.keys(matchingChunks).length > 0) {
        result[key] = {
          ...file,
          chunks: matchingChunks,
        } as unknown as CatalogResponse["catalog"][string];
      }
    }

    if (!result[key]) {
      if (
        Object.keys(selectedFilters).every((categoryKey) =>
          matchesSelectedFilters(file, categoryKey, true)
        )
      ) {
        result[key] = { ...file };
      }
    }

    return result;
  }, {});

  if (searchTerm) {
    const searchTermLower = searchTerm.toLowerCase().trim();
    data = Object.keys(data)
      .filter((key) => {
        const searchDetail = searchDetails[key];
        return (
          searchDetail &&
          (searchDetail.title.toLowerCase().includes(searchTermLower) ||
            searchDetail.metadata_search_string
              .toLowerCase()
              .includes(searchTermLower))
        );
      })
      .reduce((newData: any, key) => {
        newData[key] = data[key];
        return newData;
      }, {});
  }

  return data;
};
