import { createContext, FC, ReactElement, useContext, useState } from "react";
import { usePersistedState } from "../pages/hooks";
import { useDataContext } from "./DataContext";

export type TagFilters = {
  [key: string]: Set<string>;
};
export const CatalogContext = createContext<{
  showAdvancedTagFilters: boolean;
  taggingFilters: TagFilters;
  setShowAdvancedTagFilters: (value: boolean) => void;
  setTaggingFilters: (
    filters: TagFilters | ((old: TagFilters) => TagFilters)
  ) => void;
}>({
  // Getters
  showAdvancedTagFilters: false,
  taggingFilters: {},
  // Setters
  setShowAdvancedTagFilters: () => {},
  setTaggingFilters: () => {},
  // Functions
});

export const CatalogProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { usedCatalog } = useDataContext();
  const [showAdvancedTagFilters, setShowAdvancedTagFilters] = useState(false);
  const [taggingFilters, setTaggingFilters] = usePersistedState<TagFilters>(
    `${usedCatalog}-taggingFilters`,
    {}
  );

  return (
    <CatalogContext.Provider
      value={{
        // Getters
        showAdvancedTagFilters,
        taggingFilters,
        // Setters
        setShowAdvancedTagFilters,
        setTaggingFilters,
        // Functions
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => useContext(CatalogContext);
