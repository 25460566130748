import * as types from "./types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { format } from "date-fns";
import Auth from "../auth/AuthProvider";
import {
  fetchTeamTokenUsage,
  fetchUserTokenUsage,
} from "../components/utilities/functions/apiCalls";
import {
  getAuthHeaders,
  sendRequest,
} from "../components/utilities/functions/api";
import { backendBaseUrl } from "../utils/config";
import { ENDPOINTS } from "./endpoints";
import { AvailableCatalogsResponse } from "./types";
import { useUserProfile } from "../context/UserProfile";
import { useCallback } from "react";
import { getDocumentInfo } from "./client";
import { useDataContext } from "../context/DataContext";
import { API_USERNAME_KEYWORD } from "../constants/fixedValues";
import { waitTaskDone } from "../utils/workers";

export const useCatalogNames = () =>
  useQuery({
    queryKey: ["catalogNames"],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest({ username }, "/api/catalog", "GET")
        .then((res) => res.json())
        .then((res: { data: AvailableCatalogsResponse }) => {
          const catalogNames = res.data.catalogs;
          return catalogNames;
        });
    },
  });

interface CatalogParams {
  catalogName: string;
  tagCatalog: string;
  datasets: string[] | null;
  tagRules: string | null;
  oldCatalogName?: string;
}

export const useCreateCatalogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      catalogName,
      tagCatalog,
      tagRules = null,
      datasets = null,
      oldCatalogName = "",
    }: CatalogParams) => {
      const username = (await Auth.currentAuthenticatedUser()).username;

      return sendRequest(
        {
          username,
          catalog_name: catalogName,
          tag_catalog: tagCatalog,
          tag_rules_catalog: tagRules,
          datasets: datasets,
          oldCatalogName: oldCatalogName,
        },
        ENDPOINTS["add_new_catalog"],
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["catalogNames"] });
    },
  });
};
export type TokenModelCost = {
  name: string;
  cost: number;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
};
export type TokenEntry = {
  date: string;
  endpoint: string;
  model_costs: TokenModelCost[];
};
export type TokenUsageTracking = {
  token_list: TokenEntry[];
};
export const useUserTokenUsage = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  return useQuery({
    queryKey: [
      "tokenUsage",
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
    ],
    queryFn: async () => {
      return (await fetchUserTokenUsage(
        format(startDate, "yyy-MM-dd"),
        format(endDate, "yyy-MM-dd"),
      )) as Promise<TokenUsageTracking>;
    },
  });
};

export const useTeamTokenUsage = (startDate: Date, endDate: Date) => {
  return useQuery({
    queryKey: [
      "teamTokenUsage",
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
    ],
    queryFn: async () => {
      return (await fetchTeamTokenUsage(
        format(startDate, "yyy-MM-dd"),
        format(endDate, "yyy-MM-dd"),
      )) as Promise<{
        users_usage: {
          username: string;
          email: string;
          token_usage: TokenUsageTracking;
        }[];
      }>;
    },
  });
};

export const useFileUploadMutation = (
  {
    storage,
  }: {
    storage: {
      type: "s3" | "azureblob";
      name: string;
      base_path: string;
      credentials:
        | {
            access_key_id: string;
            secret_access_key: string;
          }
        | {
            client_id: string;
            client_secret: string;
            tenant_id: string;
          };
    };
  },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  },
) => {
  const { 
    setUploadFileTaskProgress, 
    uploadFileTaskProgress, 
    setUploadFileTask, 
    uploadFileTask 
  } = useDataContext();
  return useMutation({
    mutationFn: async ({ files }: { files: File[] }) => {
      setUploadFileTaskProgress(0);
      const username = (await Auth.currentAuthenticatedUser()).username;
      const formData = new FormData();
      formData.set("storage_type", storage.type);
      formData.set("storage_name", storage.name);
      formData.set("credentials", JSON.stringify(storage.credentials));
      formData.set("base_path", storage.base_path);
      // formData.set("username", username);

      files.forEach((file) => {
        formData.append("files", file);
      });
      const response = await fetch(
        `${backendBaseUrl}${ENDPOINTS["upload_files"]}`,
        {
          method: "POST",
          body: formData,
          headers: {
            ...(await getAuthHeaders()),
          },
        },
      );
      const { task_id } = await response.json();

      setUploadFileTask(task_id);

      waitTaskDone(task_id, username, undefined, ({ completed }) => {
        setUploadFileTaskProgress(completed);
        return completed;
      }).then(() => {
        setUploadFileTask(null);
        setUploadFileTaskProgress(null);
        onSuccess?.();
      });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }
    },
    onError,
  });
};

export type EvidenceReportEntry = {
  username: string;
  tag_name: string;
  email: string;
  catalog_name: string;
  catalog_team: string | null;
  is_valid: boolean;
  is_submitted: boolean;
  filename: string;
};

export const useEvidenceReportsMutation = () => {
  const { catalog_team } = useUserProfile();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      reportEntries: Omit<
        EvidenceReportEntry,
        "catalog_team" | "username" | "timestamp" | "email"
      >[],
    ) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      const requests = reportEntries.map((entry) => ({
        ...entry,
        username,
        catalog_team,
        timestamp: new Date().toISOString(),
      }));
      return Promise.all(
        requests.map((request) =>
          sendRequest(request, ENDPOINTS["evidence_reports"]),
        ),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["evidenceReports"] });
    },
  });
};

export const useEvidenceReports = () => {
  return useQuery({
    queryKey: ["evidenceReports"],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username },
        ENDPOINTS["evidence_reports"],
        "GET",
      ).then((res) => res.json());
    },
  });
};

// TODO: Need API endpoint for this
// NOTE: unused
export const useCatalogEvidenceReports = (catalogName: string) => {
  return useQuery({
    queryKey: ["catalogEvidenceReports", catalogName],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username },
        `${ENDPOINTS["evidence_reports"]}${catalogName}/`,
        "GET",
      ).then((res) => res.json());
    },
  });
};

export const useUpateTeamPreferencesMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (preferences: types.TeamPreferencesUpdateRequest) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username, ...preferences },
        ENDPOINTS["team_preferences"],
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teamPreferences"] });
      onSuccess?.();
    },
  });
};

export const useCatalogDocumentInfoLoader = () => {
  const queryClient = useQueryClient();
  const { getStorageInfo } = useUserProfile();
  const { currentDataGroup, usedCatalog } = useDataContext();

  return useCallback(
    (
      documentName: string,
      storageKind: string = "",
      folderKey: string = "",
    ): Promise<types.DocumentInfo> => {
      const docEntry = currentDataGroup[documentName];
      const fileStorageKind = docEntry
        ? docEntry.data_store_name[0]
        : storageKind;
      const storageInfo = getStorageInfo(fileStorageKind);
      let fileDirectory = docEntry ? docEntry.file_directory[0] : folderKey;
      let storageBasePath = storageInfo?.base_path.replaceAll("/", "") ?? null;

      const replaceRegex = storageBasePath
        ? new RegExp(`/?${storageBasePath}/?`, "g")
        : null;
      fileDirectory =
        fileDirectory && replaceRegex
          ? fileDirectory.replace(replaceRegex, "")
          : fileDirectory;
      fileDirectory = fileDirectory === "/" ? null : fileDirectory;

      return queryClient.fetchQuery({
        queryKey: ["documentInfo", usedCatalog, documentName],
        queryFn: () =>
          getDocumentInfo(documentName, fileDirectory, storageInfo),
        staleTime: 60 * 1000 * 60,
      });
    },
    [currentDataGroup, getStorageInfo, queryClient, usedCatalog],
  );
};

export const useExternalDocumentInfoLoader = () => {
  const queryClient = useQueryClient();
  const { getStorageInfo } = useUserProfile();

  return useCallback(
    (
      documentName: string,
      folderPath: string,
      storageKind: string,
    ): Promise<types.DocumentInfo> => {
      const storageInfo = getStorageInfo(storageKind);
      let fileDirectory: string | null = folderPath;
      let storageBasePath = storageInfo?.base_path.replaceAll("/", "") ?? null;
      const replaceRegex = storageBasePath
        ? new RegExp(`/?${storageBasePath}/?`, "g")
        : null;
      fileDirectory =
        fileDirectory && replaceRegex
          ? fileDirectory.replace(replaceRegex, "")
          : fileDirectory;
      fileDirectory = fileDirectory === "/" ? null : fileDirectory;

      return queryClient.fetchQuery({
        queryKey: ["documentInfo", storageKind, folderPath, documentName],
        queryFn: () =>
          getDocumentInfo(documentName, fileDirectory, storageInfo),
        staleTime: 60 * 1000 * 60,
      });
    },
    [getStorageInfo, queryClient],
  );
};

export const useFolderEntry = (
  storageKind: types.StorageKind,
): UseQueryResult<types.FolderEntry> => {
  const { getStorageInfo } = useUserProfile();
  const storageInfo = getStorageInfo(storageKind);

  return useQuery({
    queryKey: ["folderEntry", storageKind],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username, storage_info: storageInfo },
        ENDPOINTS["fetch_folders"],
      )
        .then((res) => res.json() as Promise<types.DirectoriesResponse>)
        .then((info) => info.folder_list);
    },
    staleTime: 60 * 1000 * 60,
  });
};

export const useFolderEntriesAvailable = (): UseQueryResult<
  Record<types.StorageKind, types.FolderEntry>
> => {
  const { webapp_profile } = useUserProfile();
  const availableStorage = Object.keys(
    webapp_profile.DATA_STORES,
  ) as types.StorageKind[];

  return useQuery({
    queryKey: ["folderEntries", ...availableStorage],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      const queries = availableStorage.map(async (storageKind) => {
        const response = await sendRequest(
          {
            username,
            data_store: JSON.stringify(webapp_profile.DATA_STORES[storageKind]),
          },
          ENDPOINTS["fetch_folders"],
        );
        const data = (await response.json()) as types.DirectoriesResponse;
        return { [storageKind]: data.folder_list };
      });

      return Promise.all(queries).then((results) =>
        results.reduce((acc, result) => ({ ...acc, ...result }), {}),
      );
    },
    staleTime: 60 * 1000 * 60,
  });
};

export const useTagRules = (
  catalogName: string,
): UseQueryResult<types.TagRule[]> => {
  return useQuery({
    queryKey: ["tagRules", catalogName],
    queryFn: async () => {
      const rawResponse = await sendRequest(
        {
          catalog_name: catalogName,
          [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser())
            .username,
        },
        ENDPOINTS["get_tag_rules"],
      );
      if (rawResponse.ok) {
        const jsonResponse =
          (await rawResponse.json()) as types.TagRulesResponse;
        return jsonResponse.rules;
      } else {
        console.error("failed to get tag rules", await rawResponse.text());
        throw new Error("Failed to get tag rules");
      }
    },
  });
};
