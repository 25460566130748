// Redesigned DataGraph component
import { useState, useCallback, useContext, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import Modal from "../../../../../../utilities/Modal/Modal";
import { Chart, registerables } from "chart.js";
import "./DataGraph.css";
import { calculateDistribution } from "../../../../../../utilities/functions/utils";
import { DataContext } from "../../../../../../../context/DataContext";
import twExtend from "../../../../../../../twExtend";
import { useAtom } from "jotai";
import { darkModeAtom } from "../../../../../../../atoms";
import { usePersistedState } from "../../../../../../../pages/hooks";
Chart.register(...registerables);

const DataGraph = (props) => {
  const { catalogSummary, currentDataGroup, availableTags, usedCatalog } =
    useContext(DataContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = usePersistedState(
    `${usedCatalog}-selectedCategory`,
    Object.keys(catalogSummary)[0]
  );
  const data = useMemo(() => {
    if (!catalogSummary || Object.keys(catalogSummary).length === 0) {
      return {
        labels: ["No tag selected"],
        datasets: [
          {
            label: "Proportion in %",
            data: [0],
            backgroundColor: [twExtend.colors.primary],
            borderColor: [twExtend.colors.primary],
            borderWidth: 3,
          },
        ],
      };
    }
    const currentOptions =
      catalogSummary[selectedCategory]?.availableValues || [];
    const { selectedFilters } = props;
    const distribution = calculateDistribution(
      selectedCategory,
      catalogSummary,
      currentDataGroup,
      selectedFilters
    );
    const { labels, data: calculatedData } = distribution || {
      labels: [],
      data: [],
    };

    const sortedIndices = calculatedData
      .map((value, index) => ({ value, index }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 15)
      .map((item) => item.index);

    const top15Labels = sortedIndices.map(
      (index) => labels[index] || "Unknown"
    );
    const top15Data = sortedIndices.map((index) => calculatedData[index] || 0);

    return {
      labels: top15Labels.length > 0 ? top15Labels : currentOptions,
      datasets: [
        {
          label: "Proportion in %",
          backgroundColor: [twExtend.colors.primary],
          borderColor: [twExtend.colors.primary],
          borderWidth: 3,
          data: top15Data,
        },
      ],
    };
  }, [catalogSummary, currentDataGroup, props, selectedCategory]);
  const [darkMode] = useAtom(darkModeAtom);
  const { onClose } = props;

  const handleCategoryClick = useCallback(
    (category) => {
      if (category === "chunks" || !catalogSummary || !catalogSummary[category])
        return;

      setSelectedCategory(category);
    },
    [catalogSummary, setSelectedCategory]
  );

  const filteredCategories = useMemo(() => {
    return Object.keys(catalogSummary || {}).filter((category) => {
      return (
        category !== "file_directory" &&
        availableTags.sensitivity &&
        !Object.keys(availableTags.sensitivity.tagger_params.tag_dict).includes(
          category
        ) &&
        category.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [availableTags?.sensitivity, catalogSummary, searchTerm]);

  return (
    <div
      className="h-full overflow-auto w-full"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col shrink-0 grow-0 bg-zinc-100 dark:bg-zinc-800 dark:text-white rounded-md h-full">
        <div className="relative p-4 rounded-t-md">
          <button className="closeButton" onClick={onClose}>
            X
          </button>
        </div>
        <div className="p-4 overflow-auto flex flex-col h-full">
          <div className="flex h-2/3 mb-4">
            <Bar
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,

                scales: {
                  x: {
                    ticks: {
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90,
                      color: darkMode ? "white" : "black",
                    },
                    grid: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
          <div className="flex flex-col h-1/3">
            <div className="flex items-center bg-white dark:bg-secondary rounded-lg px-4 py-2 shadow-md hover:shadow-xl mb-4">
              <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3 "></i>
              <input
                type="text"
                className="block w-full  focus:outline-none"
                placeholder="Search Tag"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
            <div className="overflow-auto">
              <div className="flex flex-wrap gap-2">
                {catalogSummary &&
                  filteredCategories.map((category) => (
                    <div
                      key={category}
                      onClick={() => handleCategoryClick(category)}
                      className={`flex px-2 py-1 text-white rounded-md cursor-pointer text-sm hover:bg-primary ${
                        selectedCategory === category
                          ? "bg-primary"
                          : "bg-secondary"
                      }`}
                    >
                      {catalogSummary[category]?.real || category}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataGraph;
