import React from "react";
import { DataContext } from "../../../context/DataContext";
export const AdvancedTagFiltersModal = ({
  currentFilters,
  onCancel,
  onAccept,
}) => {
  const [newFilters, setNewFilters] = React.useState(new Set(currentFilters));
  const [search, setSearch] = React.useState("");
  const { availableTags, preferences, ruleDict } =
    React.useContext(DataContext);

  return (
    <div className="w-full p-4 flex flex-col overflow-hidden">
      <header className="text-lg text-buttonGrey font-bold">
        Available tags to filter your data
      </header>
      <input
        type="text"
        placeholder="Search Tag"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className="w-full rounded-md px-4 py-2 mt-4 outline-none border mb-1"
      />
      <div className="w-full overflow-auto">
        <table className="w-full">
          <tbody>
            {availableTags &&
              availableTags.llm.tagger_params.tag_dict &&
              Object.keys({
                ...availableTags.llm.tagger_params.tag_dict,
                ...ruleDict,
              })
                .filter((key) => {
                  return search
                    ? key.toLowerCase().includes(search.toLowerCase())
                    : key !== "file_directory" &&
                        !Object.keys(
                          preferences.system.SENSITIVITY_TAGS,
                        ).includes(key) &&
                        !preferences.system.EXCLUDE_TAGS.includes(key);
                })
                .map((tagKey, index) => {
                  return (
                    <tr
                      key={`filter-tag-${tagKey}`}
                      className={index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}
                    >
                      <td className="text-md p-2">{tagKey}</td>
                      <td>
                        <div className="flex p-2 gap-1 flex-wrap">
                          <input
                            type="checkbox"
                            className="p-3"
                            checked={newFilters.has(tagKey)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                newFilters.add(tagKey);
                              } else {
                                newFilters.delete(tagKey);
                              }
                              setNewFilters(new Set(newFilters));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <div className="flex mt-2 gap-2 w-full">
        <button
          onClick={() => {
            onAccept([...newFilters]);
          }}
          className="text-sm w-full text-center bg-primary text-white rounded-md shadow-md px-4 py-2"
        >
          Apply
        </button>
        <button
          onClick={onCancel}
          className="text-sm w-full text-center bg-white text-primary border-2 border-primary rounded-md shadow-md px-4 py-2"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
