import { useCallback, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataContext } from "../../../context/DataContext";
import { useAtom } from "jotai";

import {
  faFolder,
  faChevronRight,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FileCheckbox } from "../FileCheckbox";
import { pdfAtom, pdfSearchAtom } from "../../../atoms";
import { DocumentViewer } from "../DocumentViewer/DocumentViewer";
import { toast } from "../Toast";

const FolderList = ({
  checkedItems,
  currentFolder,
  filteredFolderKeys,
  folders,
  integration,
  searchText,
  setCheckedItems,
  setCurrentFolder,
  setSearchText,
  mode = "standard",
  filenameFilterList = null, // if it is null, then we do not filter by filenames
}) => {
  const {
    catalogFiles,
    quarantinedFiles,
    catalogGetsRenamed,
    usedCatalog,
    setShowFileOnPage,
  } = useContext(DataContext);

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  const handleCheckboxChange = (file, folderKey) => {
    setCheckedItems((prevState) => {
      const isChecked = !(prevState[file]?.isChecked ?? false);
      if (!isChecked && prevState[file]) {
        const { [file]: removedFile, ...rest } = prevState;
        return rest;
      }
      return {
        ...prevState,
        [file]: {
          isChecked,
          folder: folderKey,
          source: integration,
        },
      };
    });
  };

  const handleFolderCheckboxChange = (folderKey) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const filesInFolder = folders[folderKey] || [];
      const filteredFiles =
        filenameFilterList === null
          ? filesInFolder
          : filesInFolder.filter((file) => filenameFilterList.includes(file));

      const allFilteredChecked = filteredFiles.every(
        (file) => newCheckedItems[file]?.isChecked,
      );

      filteredFiles.forEach((file) => {
        if (allFilteredChecked) {
          if (newCheckedItems[file]) {
            delete newCheckedItems[file];
          }
        } else {
          newCheckedItems[file] = {
            isChecked: true,
            folder: folderKey,
            source: integration,
          };
        }
      });
      return newCheckedItems;
    });
  };

  const handleSelectPrepared = () => {
    const allFiles = filteredFolderKeys.flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          catalogFiles.hasOwnProperty(file),
      ),
    );
    const anyChecked = allFiles.some((file) => checkedItems[file]?.isChecked);

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (anyChecked) {
        allFiles.forEach((file) => {
          if (newCheckedItems[file]?.isChecked) {
            delete newCheckedItems[file];
          }
        });
      }
    });

    const allPreparedFiles = filteredFolderKeys.flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          catalogFiles[file]?.Key_Questions !== undefined &&
          catalogFiles[file]?.Key_Questions !== null &&
          catalogFiles[file]?.Key_Questions &&
          catalogFiles.hasOwnProperty(file),
      ),
    );
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      allPreparedFiles.forEach((file) => {
        newCheckedItems[file] = {
          isChecked: true,
          folder: filteredFolderKeys.find((key) =>
            folders[key]?.includes(file),
          ),
          source: integration,
        };
      });
      setIsDataFiltered(true);
      return newCheckedItems;
    });
  };

  const handleSelectUnpreparedFiles = () => {
    const allFiles = filteredFolderKeys.flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          catalogFiles.hasOwnProperty(file),
      ),
    );
    const anyChecked = allFiles.some((file) => checkedItems[file]?.isChecked);

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (anyChecked) {
        allFiles.forEach((file) => {
          if (newCheckedItems[file]?.isChecked) {
            delete newCheckedItems[file];
          }
        });
      }

      return newCheckedItems;
    });

    const allUnpreparedFiles = filteredFolderKeys.flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          (catalogFiles[file]?.Key_Questions === undefined ||
            catalogFiles[file]?.Key_Questions === null ||
            !catalogFiles[file]?.Key_Questions) &&
          catalogFiles.hasOwnProperty(file),
      ),
    );
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      allUnpreparedFiles.forEach((file) => {
        newCheckedItems[file] = {
          isChecked: true,
          folder: filteredFolderKeys.find((key) =>
            folders[key]?.includes(file),
          ),
          source: integration,
        };
      });
      setIsDataFiltered(true);
      return newCheckedItems;
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFolderClick = (folderKey) => {
    setCurrentFolder((prevFolder) =>
      prevFolder === folderKey ? null : folderKey,
    );
  };

  const openFile = useCallback(
    (file) => {
      const documentInfo = catalogFiles[file];
      if (!documentInfo) {
        toast.error({
          title: "File not found",
          description: "File could not be found in the catalog.",
        });
        return;
      }

      toast.info({
        title: "Opening file...",
        description: "This may take a few seconds.",
      });

      if (
        documentInfo.file_name?.toLowerCase().endsWith(".pdf") ||
        documentInfo.file_name?.toLowerCase().endsWith(".docx")
      ) {
        setShowFileOnPage(mode);
        setPdf(documentInfo.file_url.toString());
        setPdfSearch([
          {
            evidence: "",
            chunk_index: "",
            page_count: null,
            total_size: null,
          },
        ]);
      } else {
        window.open(documentInfo.file_url, "_blank");
      }
    },
    [catalogFiles, mode],
  );

  const handleSelectAll = () => {
    let allFiles;

    if (mode === "autoCreation") {
      allFiles = filteredFolderKeys.flatMap((folderKey) =>
        (folders[folderKey] || []).filter(
          (file) =>
            (filenameFilterList === null ||
              filenameFilterList.includes(file)) &&
            catalogFiles.hasOwnProperty(file),
        ),
      );
    } else {
      allFiles = filteredFolderKeys.flatMap((folderKey) =>
        (folders[folderKey] || []).filter(
          (file) =>
            filenameFilterList === null || filenameFilterList.includes(file),
        ),
      );
    }

    const anyChecked = allFiles.some((file) => checkedItems[file]?.isChecked);

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (anyChecked) {
        allFiles.forEach((file) => {
          if (newCheckedItems[file]?.isChecked) {
            delete newCheckedItems[file];
          }
        });
        setIsDataFiltered(false);
      } else {
        allFiles.forEach((file) => {
          newCheckedItems[file] = {
            isChecked: true,
            folder: filteredFolderKeys.find((key) =>
              folders[key]?.includes(file),
            ),
            source: integration,
          };
        });
        setIsDataFiltered(true);
      }

      return newCheckedItems;
    });
  };

  const renderItems = () => {
    const selectedFileCount = Object.values(checkedItems).filter(
      (item) => item.isChecked,
    ).length;

    return (
      <>
        <div className="w-full">
          <div
            className={`flex justify-between flex-row items-center gap-3 ${
              mode === "standard" ? "p-4" : "pb-4"
            }`}
          >
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-2 rounded-md outline-none border"
              value={searchText}
              onChange={handleSearchChange}
            />
            <button
              onClick={handleSelectAll}
              className={`rounded-md text-primary border-2 border-primary whitespace-nowrap px-2 py-1 hover:bg-primary hover:text-white ${
                isDataFiltered ? "bg-primary text-white" : ""
              }`}
            >
              {isDataFiltered
                ? "Deselect all selected data"
                : "Select all data"}
            </button>
            {mode === "autoCreation" && (
              <>
                <button
                  onClick={handleSelectPrepared}
                  className="rounded-md text-primary border-2 border-primary whitespace-nowrap px-2 py-1 hover:bg-primary hover:text-white"
                >
                  Select prepared files
                </button>
                <button
                  onClick={handleSelectUnpreparedFiles}
                  className="rounded-md text-primary border-2 border-primary whitespace-nowrap px-2 py-1 hover:bg-primary hover:text-white"
                >
                  Select unprepared files
                </button>
              </>
            )}
          </div>
        </div>
        <div className="w-full px-4 py-2 bg-gray-100 border-t border-b flex justify-end">
          <span className="font-semibold text-primary text-lg">
            {selectedFileCount} file{selectedFileCount !== 1 ? "s" : ""}{" "}
            selected
          </span>
        </div>

        {pdf && (
          <DocumentViewer
            url={pdf}
            onClose={() => {
              setPdf("");
              setShowFileOnPage(null);
              setPdfSearch([
                {
                  evidence: "",
                  chunk_index: "",
                  page_count: null,
                  total_size: null,
                },
              ]);
            }}
            search={pdfSearch}
          />
        )}
        <div className="flex flex-col overflow-hidden pb-[10vh]">
          <ul className="w-full text-sm h-full overflow-auto">
            {filteredFolderKeys &&
              filteredFolderKeys.map((folderKey) => {
                const filesInFolder = folders[folderKey] || [];

                let filteredFiles =
                  filenameFilterList === null
                    ? filesInFolder
                    : filesInFolder.filter((file) =>
                        filenameFilterList.includes(file),
                      );

                if (mode === "autoCreation") {
                  filteredFiles = filteredFiles.filter((file) =>
                    catalogFiles.hasOwnProperty(file),
                  );
                }
                if (filteredFiles.length === 0) return null;

                const totalFiles = filteredFiles.length;

                const containedFiles = filteredFiles.filter(
                  (file) => catalogFiles[file] || quarantinedFiles[file],
                ).length;

                const containedFilesPercentage = Math.round(
                  (containedFiles / totalFiles) * 100,
                );

                const filesWithKeyQuestions = filteredFiles.filter(
                  (file) => catalogFiles[file]?.Key_Questions,
                ).length;

                const keyQuestionsPercentage = Math.round(
                  (filesWithKeyQuestions / filteredFiles.length) * 100,
                );

                const isFolderSelected = filteredFiles.every(
                  (file) => checkedItems[file]?.isChecked,
                );
                return (
                  <li
                    key={folderKey}
                    className={`border-b px-2 ${
                      isFolderSelected ? "selected" : ""
                    } ${
                      containedFilesPercentage === 100 && mode === "standard"
                        ? "!bg-light !bg-opacity-40"
                        : "bg-slate-100"
                    }`}
                  >
                    <div
                      className={`flex gap-2 items-center cursor-pointer py-2`}
                      onClick={() => handleFolderClick(folderKey)}
                    >
                      <FontAwesomeIcon
                        icon={
                          currentFolder === folderKey
                            ? faChevronDown
                            : faChevronRight
                        }
                      />

                      <input
                        type="checkbox"
                        id={`checkbox-folder-${folderKey}`}
                        className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
                        onChange={() => handleFolderCheckboxChange(folderKey)}
                        checked={filteredFiles.every(
                          (file) => checkedItems[file]?.isChecked,
                        )}
                      />
                      <div htmlFor={`checkbox-folder-${folderKey}`}>
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="pr-1 text-yellow-400"
                        />
                        {folderKey}
                      </div>
                      <span className="file-count">
                        {totalFiles} files
                        {mode !== "autoCreation" && (
                          <>
                            {" "}
                            ({containedFilesPercentage.toFixed(0)}% contained)
                          </>
                        )}
                        {mode === "autoCreation" && (
                          <> ({keyQuestionsPercentage.toFixed(0)}% prepared)</>
                        )}
                      </span>
                    </div>
                    {currentFolder === folderKey && (
                      <ul className="file-tree">
                        {filteredFiles
                          .filter((file) =>
                            file
                              .toLowerCase()
                              .includes(searchText.toLowerCase()),
                          )
                          .map((file, index) => {
                            const isContained =
                              catalogFiles[file] || quarantinedFiles[file];
                            const hasKeyQuestions =
                              catalogFiles[file]?.Key_Questions ?? false;

                            return (
                              <li
                                key={file}
                                className={`p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all ${
                                  isContained &&
                                  (mode === "standard" || mode === "tagStudio")
                                    ? "bg-light bg-opacity-30"
                                    : index % 2
                                      ? "bg-slate-50"
                                      : "bg-slate-100"
                                }`}
                              >
                                <FileCheckbox
                                  fileName={file}
                                  isSelected={
                                    checkedItems[file]?.isChecked ?? false
                                  }
                                  onClick={() =>
                                    handleCheckboxChange(file, currentFolder)
                                  }
                                />
                                <div className="flex items-center md:w-auto w-full justify-end">
                                  {mode === "autoCreation" && (
                                    <FontAwesomeIcon
                                      icon={
                                        hasKeyQuestions
                                          ? faCheckCircle
                                          : faTimesCircle
                                      }
                                      className={
                                        hasKeyQuestions
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }
                                      title={
                                        hasKeyQuestions
                                          ? "Data is prepared"
                                          : "Data is not prepared"
                                      }
                                    />
                                  )}
                                  <button
                                    onClick={() => openFile(file, folderKey)}
                                    className="px-2 py-1 text-buttonGrey rounded-md whitespace-nowrap ml-2 flex items-center justify-center hover:bg-buttonGrey hover:text-white"
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (Object.keys(checkedItems).length === 0) {
      setCheckedItems([]);
    }
  }, []);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {!catalogGetsRenamed ? (
        renderItems()
      ) : (
        <div className="h-[700vh] p-4 flex flex-col rounded-md overflow-hidden justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
          <p className="mt-4 text-lg font-semibold">Loading {usedCatalog}...</p>
        </div>
      )}
    </div>
  );
};

export default FolderList;
