import format from "date-fns/format";

export const calculateDistribution = (
  category,
  catalogSummary,
  categorizedData,
  selectedFilters,
) => {
  if (category === "chunks") {
    return { labels: [], data: [] };
  }

  const availableValues = catalogSummary[category]?.availableValues || [];
  const distribution = {};
  const labels = [];
  let data = [];

  availableValues.forEach((value) => {
    if (typeof value === "string" && value.length === 1) {
      distribution[value] = 0;
    }
  });

  let totalCount = 0;

  const isFilterApplied =
    selectedFilters[category] && selectedFilters[category].size > 0;
  Object.values(categorizedData).forEach((item) => {
    const value = item[category];

    const updateDistribution = (val) => {
      if (val === "chunks") {
        return;
      }

      if (
        !isFilterApplied ||
        (isFilterApplied && selectedFilters[category].has(val))
      ) {
        distribution[val] = (distribution[val] || 0) + 1;
        totalCount++;
      }
    };

    if (Array.isArray(value)) {
      value.forEach(updateDistribution);
    } else {
      updateDistribution(value);
    }
  });

  // Convert the distribution object into labels and data arrays, and calculate proportions
  if (totalCount > 0) {
    Object.entries(distribution).forEach(([label, count]) => {
      labels.push(label);
      data.push((count / totalCount) * 100); // Convert to percentage
    });
  }

  return { labels, data };
};

export const formatDateRange = (startDate, endDate, type = "both") => {
  if (startDate === "Unknown") {
    return "Unknown";
  } else {
    const dateStartObj = new Date(startDate);
    const dateEndObj = new Date(endDate);
    // Check if the dates are valid
    if (isNaN(dateStartObj) || isNaN(dateEndObj)) {
      throw new Error("Invalid date parsed");
    }

    const formattedStart = format(dateStartObj, "yyyy-MM-dd");
    const formattedEnd = format(dateEndObj, "yyyy-MM-dd");

    if (type === "both") {
      return `${formattedStart} to ${formattedEnd}`;
    } else if (type === "start") {
      return formattedStart;
    } else {
      return formattedEnd;
    }
  }
};

export function generateTemplate(type, description) {
  return `For the use case named "${type}", the description is as follows: ${description}. How can you assist with this?`;
}

function deepMerge(objA, objB) {
  const result = { ...objA };
  for (const key in objB) {
    if (objB.hasOwnProperty(key)) {
      if (typeof objB[key] === "object" && objA.hasOwnProperty(key)) {
        result[key] = deepMerge(objA[key], objB[key]);
      } else {
        result[key] = objB[key];
      }
    }
  }
  return result;
}

export const mergeObjectsBasedOnKeys = async (obj1, obj2) => {
  return Object.keys(obj1)
    .filter((key) => key in obj2)
    .reduce((result, key) => {
      result[key] = deepMerge(obj1[key], obj2[key]);
      return result;
    }, {});
};

export function computeAverages(data) {
  let totalTimeliness = 0;
  let countRelevanceTrue = 0;

  Object.values(data).forEach((entry) => {
    if (entry && entry.relevance && entry.relevance.relevant) {
      totalTimeliness += entry.timeliness;
      if (entry.relevance.relevant === "True") {
        countRelevanceTrue++;
      }
    }
  });

  const totalCount = Object.keys(data).length;

  const averageTimeliness =
    totalCount !== 0
      ? roundToTwoDecimalPlaces(totalTimeliness / totalCount)
      : 0;
  const averageRelevance =
    totalCount !== 0
      ? roundToTwoDecimalPlaces(countRelevanceTrue / totalCount)
      : 0;

  return {
    averageTimeliness: averageTimeliness,
    averageRelevance: averageRelevance,
  };
}

export const roundToTwoDecimalPlaces = (number) => Math.round(number * 100);

export function updateAttributes(obj1, obj2) {
  let mergedObj = {};

  for (let key in obj2) {
    if (obj1.hasOwnProperty(key)) {
      mergedObj[key] = {
        ...obj2[key],
        ...obj1[key],
      };
    } else {
      mergedObj[key] = obj2[key];
    }
  }

  return mergedObj;
}

export const categorizeData = (data) => {
  let relevant = {};
  let nonRelevant = {};

  Object.entries(data).forEach(([key, value]) => {
    let isRelevant = value.relevance && value.relevance.relevant === "True";
    let isTimely = value.timeliness === 1;

    if (isRelevant && isTimely) {
      relevant[key] = value;
    } else {
      nonRelevant[key] = value;
    }
  });

  return {
    relevant,
    nonRelevant,
  };
};

export function getRandomColor() {
  const hue = Math.floor(Math.random() * 361);
  const saturation = 60;
  const lightness = 80;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function addNewLabel(name, availableValues, existingLabels) {
  const key = name.toLowerCase().replace(/ /g, "_");

  const newLabel = {
    [name]: {
      name: name,
      real: key,
      colour: getRandomColor(),
      availableValues: availableValues,
    },
  };
  return { ...existingLabels, ...newLabel };
}

export const isFileContainedInArray = (fileName, filelist) =>
  filelist.some((file) => file === fileName);

export function generateRandomNumber() {
  return Math.floor(Math.random() * 10000) + 1;
}

export function objectDeepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const updateParentLabelObj = (prevState, currentTag) => {
  let newState = { ...prevState };

  if (
    !currentTag.hasOwnProperty("tagType") ||
    currentTag.tagType === "Sensitivity"
  ) {
    return {
      ...newState,
      sensitivity: {
        ...newState.sensitivity,
        tagger_params: {
          ...newState.sensitivity.tagger_params,
          tag_dict: {
            ...newState.sensitivity.tagger_params.tag_dict,
            [currentTag.name]: currentTag,
          },
        },
      },
    };
  }

  return {
    ...newState,
    llm: {
      ...newState.llm,
      tagger_params: {
        ...newState.llm.tagger_params,
        tag_dict: {
          ...newState.llm.tagger_params.tag_dict,
          [currentTag.name]: currentTag,
        },
      },
    },
  };
};

export const deleteLabelParentLabelObj = (taggerList, labelToRemove) => {
  const newTaggerList = objectDeepCopy(taggerList);

  if (newTaggerList.llm.tagger_params.tag_dict.hasOwnProperty(labelToRemove)) {
    delete newTaggerList.llm.tagger_params.tag_dict[labelToRemove];
  }
  if (
    newTaggerList.hasOwnProperty("sensitivity") &&
    newTaggerList.sensitivity.tagger_params.tag_dict.hasOwnProperty(
      labelToRemove,
    )
  ) {
    delete newTaggerList.sensitivity.tagger_params.tag_dict[labelToRemove];
  }
  const NEW_TAGGER_LIST = { ...newTaggerList };

  return NEW_TAGGER_LIST;
};

export const filterTaggerList = (taggerList, catalogSummary) => {
  const newTaggerList = JSON.parse(JSON.stringify(taggerList));

  if (
    newTaggerList &&
    newTaggerList.tagger_params &&
    newTaggerList.tagger_params.tag_dict
  ) {
    const tagDict = newTaggerList.tagger_params.tag_dict;
    const newTagDict = {};

    Object.keys(tagDict).forEach((key) => {
      if (catalogSummary.hasOwnProperty(key)) {
        newTagDict[key] = tagDict[key];
      }
    });

    newTaggerList.tagger_params.tag_dict = newTagDict;
  }

  return newTaggerList;
};

export const filterDataGroupByRelatedInfo = (dataGroup, relatedInfo) => {
  return Object.keys(dataGroup)
    .filter((key) => relatedInfo.includes(key))
    .reduce((obj, key) => {
      obj[key] = dataGroup[key];
      return obj;
    }, {});
};

export const updateModelVersion = (taggers, modelVersion, providerVersion) => {
  Object.values(taggers).forEach((tagger) => {
    if (tagger.tagger_params?.model) {
      tagger.tagger_params.model.version = modelVersion;
      tagger.tagger_params.model.provider = providerVersion;
    }
  });
  return taggers;
};

export const areAllProcessed = (data) => {
  return Object.values(data).some((obj) => obj.status === "Processing");
};

export function countStatusValues(data) {
  const statusCounts = {};

  Object.values(data).forEach((item) => {
    const status = item.status;
    if (status) {
      statusCounts[status] = (statusCounts[status] || 0) + 1;
    }
  });

  return statusCounts;
}

export const searchInText = (text, search) => {
  if (!search.trim()) return true;
  const searchCleaned = search.trim().toLowerCase();
  const searchWordsCleaned = searchCleaned.split(" ");
  const textCleaned = text.trim().toLowerCase();
  const textWordsCleaned = textCleaned.split(" ");
  for (const word of searchWordsCleaned) {
    if (
      !textWordsCleaned.includes(word) &&
      !textWordsCleaned.join(" ").includes(word)
    ) {
      return false;
    }
  }
  return true;
};

export function isSpreadsheetFile(fileName) {
  const spreadsheetExtensions = ["xlsx", "xls", "ods", "csv"];

  const extension = fileName.split(".").pop().toLowerCase();

  return spreadsheetExtensions.includes(extension);
}

/**
 * @returns {Map<string, Array<string>>} affected files grouped by tags
 */
export const gatherFailedTags = (catalog) => {
  /**
   * e.g. ['myTag', ['myFile.doc', 'otherfile.pdf']]
   * @type {Map<string, Array<string>>}
   */
  const failedTagsToFiles = new Map();

  Object.entries(catalog).forEach(([fileName, fileEntry]) => {
    if (!fileEntry.chunks) {
      return;
    }

    Object.values(fileEntry.chunks).forEach((chunkEntry) =>
      Object.entries(chunkEntry).forEach(([tagName, chunkResult]) => {
        if (chunkResult.successful === false) {
          const collected = failedTagsToFiles.get(tagName) || [];
          failedTagsToFiles.set(tagName, [
            ...new Set([...collected, fileName]),
          ]);
        }
      }),
    );
  });

  return failedTagsToFiles;
};

/**
 * @param {Record<string, Record>} catalog
 * @param {Array<string>} failedDocuments
 * @returns {Record<string, Record>}
 */
export const filterCatalogByFailedTag = (catalog, failedDocuments) => {
  return Object.fromEntries(
    Object.entries(catalog).filter(([documentName, _]) =>
      failedDocuments.includes(documentName),
    ),
  );
};

export function isImage(fileName) {
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".tiff",
    ".webp",
    ".svg",
    ".heic"
  ];

  const extension = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();

  return imageExtensions.includes(extension);
}

export const mergeTagWithDefaults = (
  existingTag,
  defaultTag,
  isEdit = false
) => {
  const mergeDeep = (target, source) => {
    Object.keys(source).forEach((key) => {
      if (source[key] && typeof source[key] === "object") {
        if (!target[key]) {
          target[key] = Array.isArray(source[key]) ? [] : {};
        }
        mergeDeep(target[key], source[key]);
      } else {
        if (!target.hasOwnProperty(key)) {
          target[key] = source[key];
        }
      }
    });
  };

  const mergedTag = JSON.parse(JSON.stringify(existingTag));
  mergeDeep(mergedTag, defaultTag);

  if (!mergedTag.created_at) {
    mergedTag.created_at = new Date().toISOString();
  }

  if (!mergedTag.updated_at || isEdit) {
    mergedTag.updated_at = new Date().toISOString();
  }

  return mergedTag;
};
