module.exports = {
  colors: {
    primary: "rgba(40, 165, 144, 1)",
    secondary: "#1a735e",
    light: "#39e5bc",
    grey: "#505e71",
    deasieBlack:"rgba(23, 33, 31, 1)",
    deasieTurquoise: "rgba(64, 215, 179, 1)",
    buttonGrey: "rgb(163, 163, 163)",
    containerLight: "rgb(243,245,249)",
    parentContainerBackground: "rgb(222,221,221)"
  },
};
