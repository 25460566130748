import TaskTrackingDashboard from "./TaskTrackingDashboard";

export default function TaskDashboardModal() {
    return (
        <div className="flex h-full w-full p-4 flex-col gap-8">
            <div className="text-center text-3xl">
                Task Dashboard
            </div>
            <div className="overflow-auto">
                <TaskTrackingDashboard />
            </div>
        </div>
  );
};