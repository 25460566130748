import React, { createContext, useState, useContext, useEffect } from "react";
import Auth from "../auth/AuthProvider";

import {
  sendRequest,
  getBackendBaseUrl,
} from "../components/utilities/functions/api";
import { ENDPOINTS } from "../api/endpoints";
import { DataContext } from "./DataContext";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [relevantDocuments, setRelevantDocuments] = useState({});
  const [evidences, setEvidences] = useState(new Map());
  const [messages, setMessages] = useState([]);
  const [blockReset, setBlockReset] = useState(false);

  const [contextFile, setContextFile] = useState([]);
  const [referenceFile, setReferenceFile] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [generationPrompt, setGenerationPrompt] = useState("");
  const [chats, setChats] = useState({});
  const [currentChatId, setCurrentChatId] = useState(null);

  const { usecaseSelected, catalogFiles, preferences, availableTags } =
    useContext(DataContext);

  const getUsecaseHistory = async (getLatestChat = false) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.get_usecase_history
      );
      if (response.ok) {
        const data = await response.json();
        setChats(data.chats);

        if (getLatestChat && data.chats) {
          const chatsArray = Object.entries(data.chats).map(
            ([chatId, chatDetails]) => ({
              chatId,
              createdAt: new Date(chatDetails.created_at),
            })
          );

          chatsArray.sort((a, b) => b.createdAt - a.createdAt);

          if (chatsArray.length > 0) {
            setCurrentChatId(chatsArray[0].chatId);
          }
        }
        if (currentChatId) {
          setMessages(
            data.chats[currentChatId].messages.map((msg, index) => ({
              id: msg.id,
              text: msg.content,
              type: msg.role,
            }))
          );
        }
      } else {
        console.error(
          "Failed to fetch use case history",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error during API call", error);
    }
    setIsHistoryLoading(false);
  };

  const createChat = async (chatName) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          chat_name: chatName,
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.create_usecase_history
      );
      if (response.ok) {
        await getUsecaseHistory(true);
        return await response.json();
      } else {
        console.error("Failed to create chat", await response.text());
      }
    } catch (error) {
      console.error("Error during chat creation", error);
    }
    setIsHistoryLoading(false);
  };

  const deleteChat = async (chatId) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          chat_id: chatId,
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.delete_usecase_history
      );
      if (response.ok) {
        setCurrentChatId(null);
        await getUsecaseHistory();
      } else {
        console.error("Failed to delete chat", await response.text());
      }
    } catch (error) {
      console.error("Error during chat deletion", error);
    }
    setIsHistoryLoading(false);
  };

  const renameChat = async (chatId, chatName) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          chat_id: chatId,
          chat_name: chatName,
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.rename_chat
      );
      if (response.ok) {
        setCurrentChatId(chatId);
        await getUsecaseHistory();
      } else {
        console.error("Failed to rename chat", await response.text());
      }
    } catch (error) {
      console.error("Error during chat renaming", error);
    }
    setIsHistoryLoading(false);
  };

  const clearChat = async (chatId) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          chat_id: chatId,
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.clear_usecase_history
      );
      if (response.ok) {
        setMessages([]);
        await getUsecaseHistory();
      } else {
        console.error("Failed to clear chat", await response.text());
      }
    } catch (error) {
      console.error("Error during chat clear", error);
    }
    setIsHistoryLoading(false);
  };

  const deleteMessage = async (chatId, messageId) => {
    setIsHistoryLoading(true);
    try {
      const response = await sendRequest(
        {
          usecase_id: usecaseSelected.id.toString(),
          chat_id: chatId,
          message_id: messageId,
          username: (await Auth.currentAuthenticatedUser()).username,
        },
        ENDPOINTS.delete_usecase_history_message
      );
      if (response.ok) {
        setMessages((prevMessages) =>
          prevMessages.filter((message) => message.id !== messageId)
        );
        await getUsecaseHistory();
      } else {
        console.error("Failed to delete chat message", await response.text());
      }
    } catch (error) {
      console.error("Error during chat message deletion", error);
    }
    setIsHistoryLoading(false);
  };

  const handleMessageReceived = (data) => {
    return new Promise((resolve) => {
      if (data.chat_id !== currentChatId) {
        setCurrentChatId(data.chat_id);
      }
      setMessages((prevMessages) => {
        let updatedMessages;
        if (
          prevMessages.length > 0 &&
          prevMessages[prevMessages.length - 1].type === "assistant"
        ) {
          updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            text:
              updatedMessages[updatedMessages.length - 1].text + data.response,
          };
        } else {
          updatedMessages = [
            ...prevMessages,
            {
              id: prevMessages.length + 1,
              text: data.response,
              type: "assistant",
            },
          ];
        }
        resolve(updatedMessages);
        return updatedMessages;
      });
    });
  };

  async function setupWebSocket() {
    const authUser = (await Auth.currentAuthenticatedUser()).username;
    const idToken = await Auth.getIdToken();
    const accessToken = await Auth.getAccessToken();

    const endpoint = ENDPOINTS.chat;
    const backendBaseUrl = getBackendBaseUrl();
    const url = `${backendBaseUrl.replace(/^http/, "ws")}${endpoint}?chat_id=${currentChatId}&user_id=${encodeURIComponent(authUser)}&id_token=${encodeURIComponent(idToken)}&access_token=${encodeURIComponent(accessToken)}&auth_provider=${process.env.REACT_APP_AUTH_PROVIDER ?? "AWS"}&client_id=${process.env.REACT_APP_AZURE_CLIENT_ID ?? ""}&tenant_id=${process.env.REACT_APP_AZURE_TENANT_ID ?? ""}`;

    const webSocket = new WebSocket(url);

    webSocket.onopen = () => {
      console.log("WebSocket connection established");
    };

    webSocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleMessageReceived(data);
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    webSocket.onclose = async (event) => {
      console.log("WebSocket closed:", event);
      if (currentChatId === null) {
        await getUsecaseHistory(true);
      } else {
        await getUsecaseHistory();
      }

      stopListeningForMessages();
      setIsChatLoading(false);
      setBlockReset(false);
      setNewMessage("");
    };

    return webSocket;
  }

  const stopListeningForMessages = () => {
    if (window.chatWebSocket) {
      window.chatWebSocket = null;
    }
  };

  function waitForWebSocketReady(webSocket) {
    return new Promise((resolve, reject) => {
      if (webSocket.readyState === webSocket.OPEN) {
        resolve();
      } else {
        webSocket.onopen = () => resolve();
        webSocket.onerror = () => reject(new Error("WebSocket error"));
      }
    });
  }

  const handleSendMessage = async (documentsSelected) => {
    if (newMessage.trim() !== "") {
      setIsChatLoading(true);
      setBlockReset(true);

      if (!window.chatWebSocket) {
        window.chatWebSocket = await setupWebSocket();
      }

      const newUserMessage = {
        id: messages.length + 1,
        text: newMessage,
        type: "user",
      };

      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setNewMessage("");

      const sendObject = {
        messages: [...messages, newUserMessage].map((message) => ({
          role: message.type,
          content: message.text,
        })),
        chat_message: newMessage,
        vector_index_usecase: usecaseSelected.id.toString(),
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        model_name: "",
        embed_model_name: "",
        usecase_datasets: documentsSelected,
        tags: JSON.stringify(availableTags.llm.tagger_params.tag_dict),
        metadata: usecaseSelected.metadata,
        chat_id: currentChatId,
        usecase: JSON.stringify(usecaseSelected),
      };

      try {
        await waitForWebSocketReady(window.chatWebSocket);
        window.Sentry?.captureMessage(`Sending message to chat:
        ${JSON.stringify(sendObject)}`);
        window.chatWebSocket.send(JSON.stringify(sendObject));
        //const rawResponse = await sendRequest(sendObject, ENDPOINTS["chat"]);
      } catch (error) {
        console.error("Error during API call", error);
      }
    }
  };

  const handleGenerate = async (referenceFile, contextFile) => {
    setIsChatLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: prevMessages.length + 2,
        text: generationPrompt,
        type: "user",
      },
    ]);

    if (!referenceFile || !contextFile) {
      return;
    }

    const getFileDetails = (file) => {
      const fileDetails = catalogFiles[file];
      if (!fileDetails) {
        console.warn(`File details not found or corrupt for ${file}`);
        return { corrupt: true, file };
      }
      return {
        path: fileDetails.file_directory[0] + "/" + file,
        storage: {
          type: fileDetails.storage_type[0],
          name: fileDetails.storage_name[0],
        },
        name: fileDetails.data_store_name ? fileDetails.data_store_name[0] : fileDetails.storage_type[0],
      };
    };

    const sendGenerationDetails = {
      prompt: generationPrompt,
      reference_text: referenceFile
        .map(getFileDetails)
        .filter((f) => !f.corrupt),
      context_text: contextFile.map(getFileDetails).filter((f) => !f.corrupt),
      [preferences.system.API_USERNAME_KEYWORD]: (
        await Auth.currentAuthenticatedUser()
      ).username,
    };

    const corruptFiles = [...referenceFile, ...contextFile]
      .map(getFileDetails)
      .filter((f) => f.corrupt);
    if (corruptFiles.length > 0) {
      console.warn(
        "Corrupt files detected:",
        corruptFiles.map((f) => f.file)
      );
    }

    try {
      const rawResponse = await sendRequest(
        sendGenerationDetails,
        ENDPOINTS["text_generation"]
      );
      if (rawResponse.ok) {
        const response = await rawResponse.json();
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 2,
            text: response.generated_text,
            type: "assistant",
          },
        ]);
      } else {
        console.error("API call failed", await rawResponse.text());
      }
    } catch (error) {
      console.error("Error during API call", error);
    }
    setIsChatLoading(false);
    setBlockReset(true);
  };

  return (
    <ChatContext.Provider
      value={{
        // Getters
        relevantDocuments,
        messages,
        blockReset,
        contextFile,
        referenceFile,
        newMessage,
        isChatLoading,
        generationPrompt,
        chats,
        currentChatId,
        isHistoryLoading,

        // Setters
        setIsChatLoading,
        setMessages,
        setNewMessage,
        setRelevantDocuments,
        setBlockReset,
        setContextFile,
        setReferenceFile,
        setGenerationPrompt,
        setCurrentChatId,
        setIsHistoryLoading,
        // Functions
        handleSendMessage,
        handleGenerate,
        getUsecaseHistory,
        createChat,
        deleteChat,
        renameChat,
        clearChat,
        deleteMessage,
        setChats,
        evidences,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
