import { Document, Page } from "react-pdf";
import { toast } from "../Toast";
import { backendBaseUrl } from "../../../utils/config";
import React from "react";

export const DocumentViewer = ({ url, onClose, search }) => {
  const [numPages, setNumPages] = React.useState();
  const [pageNumber, setPageNumber] = React.useState();
  const [zoom, setZoom] = React.useState(1.3);
  const [searchResults, setSearchResults] = React.useState([]); // tuple where the first item is the page number and the second is the number of highlights
  const [hoveredEvidence, setHoveredEvidence] = React.useState();
  const [isEvidenceCollapsed, setIsEvidenceCollapsed] = React.useState(true);

  const onDocumentLoadSuccess = React.useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  React.useEffect(() => {
    const handler = async () => {
      const pdfUrl = new URL(
        `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
      );

      pdfUrl.searchParams.append("pdf_url", url);
      pdfUrl.searchParams.append("search", JSON.stringify(search));
      pdfUrl.searchParams.append("just_page", "true");

      const response = await fetch(pdfUrl.href);
      const data = (await response.json()).data.pages;
      if (
        data.length === 0 ||
        data[0].length === 0 ||
        typeof data[0][0] !== "number"
      ) {
        setPageNumber(1);
        if (search) {
          toast.info({
            title: "Info",
            description: "No evidence highlighted in the document.",
          });
        }
      } else {
        setPageNumber(data[0][0] + 1);
        setSearchResults(data);
      }
    };
    handler().catch(() => {
      setPageNumber(1);
    });
  }, [search, url]);

  const pdfUrl = new URL(
    `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
  );
  pdfUrl.searchParams.append("pdf_url", url);
  pdfUrl.searchParams.append("just_page", "false");
  pdfUrl.searchParams.append("search", JSON.stringify(search));

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-10 backdrop-blur-md overflow-hidden z-[999999]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full h-full p-4 inset-0 flex flex-col gap-4 justify-center items-center">
        <div
          className={`flex ${pageNumber === undefined && "bg-white"} overflow-hidden`}
        >
          <div className="overflow-auto">
            <Document file={pdfUrl.href} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale={zoom} />
            </Document>
          </div>
          <div
            className={`flex flex-col p-4 bg-gray-100 rounded-lg shadow space-y-2 ml-3 overflow-auto`}
          >
            <div className="flex justify-end">
              <button
                className="text-grey border-2 p-2 rounded-md border-grey inline-block"
                onClick={() => setIsEvidenceCollapsed(!isEvidenceCollapsed)}
              >
                {isEvidenceCollapsed ? ">" : "<"}
              </button>
            </div>
            {!isEvidenceCollapsed &&
              searchResults.map((result, index) => {
                return (
                  <div
                    key={index}
                    className={`relative hover-trigger p-2 rounded-md shadow cursor-pointer hover:opacity-50 ${result[0] + 1 === pageNumber ? "bg-deasieTurquoise text-white" : "bg-white"}`}
                    onClick={() => {
                      setPageNumber(result[0] + 1);
                    }}
                    onMouseEnter={() => setHoveredEvidence(index)}
                    onMouseLeave={() => setHoveredEvidence(undefined)}
                  >
                    {result[1].length} evidence on page {result[0] + 1}
                  </div>
                );
              })}
          </div>
          {hoveredEvidence !== undefined && (
            <div
              className={`absolute left-3/4 p-4 space-y-2 overflow-auto bg-gray-100 rounded-lg shadow me-6 transition ease-in-out delay-150`}
            >
              <span className="font-bold">Evidence highlighted</span>
              {searchResults[hoveredEvidence]?.[1].map((highlight, index) => {
                return (
                  <div key={index} className="text-xs whitespace-pre-wrap">
                    {highlight}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="h-10 shrink-0 flex gap-2">
          <button
            className="bg-secondary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) - 1 > 0 ? pageNumber - 1 : pageNumber,
              );
            }}
          >
            prev
          </button>
          <button
            className="bg-primary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (!numPages) return;
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) + 1 < numPages ? pageNumber + 1 : numPages,
              );
            }}
          >
            next
          </button>
          <button
            onClick={onClose}
            className="bg-rose-400 px-4 py-2 text-white rounded-md"
          >
            Close
          </button>
          <button
            onClick={() => setZoom(zoom + 0.1)}
            className="bg-primary ml-20 px-4 py-2 text-white rounded-md"
          >
            +
          </button>

          <button
            onClick={() => setZoom(zoom - 0.1)}
            className="bg-primary px-4 py-2 text-white rounded-md"
          >
            -
          </button>
          <div className="bg-white text-sm rounded-md flex items-center p-2">
            page {pageNumber} of {numPages}
          </div>
        </div>
      </div>
    </div>
  );
};
