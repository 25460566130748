import Auth from "../../../auth/AuthProvider";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { backendBaseUrl } from "../../../utils/config";

export const getAuthHeaders = async () => {
  const authUser = (await Auth.currentAuthenticatedUser()).username;
  const idToken = await Auth.getIdToken();
  const accessToken = await Auth.getAccessToken();
  return {
    "x-user-id": authUser,
    "x-id-token": idToken,
    "x-access-token": accessToken,
    "x-auth-provider": process.env.REACT_APP_AUTH_PROVIDER ?? "AWS",
    "x-client-id": process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
    "x-tenant-id": process.env.REACT_APP_AZURE_TENANT_ID ?? "",
  };
};

/**
 * @returns {Promise<Response>}
 */
export async function sendRequest(
  sendObject,
  endpoint,
  method = "POST",
  timeout = 5000,
  signal = undefined,
) {
  let apiResponse;
  const authUser = (await Auth.currentAuthenticatedUser()).username;
  const idToken = await Auth.getIdToken();
  const accessToken = await Auth.getAccessToken();

  if (!(API_USERNAME_KEYWORD in sendObject)) {
    apiResponse = `"${API_USERNAME_KEYWORD}" keyword missing in request, so no API request sent.`;
    return apiResponse;
  } else {
    if (sendObject[API_USERNAME_KEYWORD] !== authUser) {
      apiResponse = `"${API_USERNAME_KEYWORD}" keyword does not match the authenticated user, so no API request sent.`;
      return apiResponse;
    }
  }
  try {
    if (method === "POST") {
      apiResponse = await fetch(backendBaseUrl + endpoint, {
        method: method,
        mode: "cors",
        headers: {
          ...(await getAuthHeaders()),
          "content-type": "application/json",
        },
        body: JSON.stringify(sendObject),
        signal,
      });
    } else {
      apiResponse = await fetch(backendBaseUrl + endpoint, {
        method: method,
        mode: "cors",
        headers: {
          ...(await getAuthHeaders()),
          "Content-Type": "application/json",
        },
        signal,
      });
    }
  } catch (error) {
    console.error("Error during fetch:", error);
    apiResponse = null;
  }
  return apiResponse;
}

export async function listenForServerSentEvents(
  endpoint,
  onMessageCallback,
  controller,
) {
  const authUser = (await Auth.currentAuthenticatedUser()).username;
  const idToken = await Auth.getIdToken();
  const accessToken = await Auth.getAccessToken();

  const url = `${backendBaseUrl}${endpoint}`;
  fetchEventSource(url, {
    method: "GET",
    headers: {
      "x-user-id": authUser,
      "x-id-token": idToken,
      "x-access-token": accessToken,
      "x-auth-provider": process.env.REACT_APP_AUTH_PROVIDER ?? "AWS",
      "x-client-id": process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
      "x-tenant-id": process.env.REACT_APP_AZURE_TENANT_ID ?? "",
    },
    onmessage: (event) => {
      try {
        const data = JSON.parse(event.data);
        onMessageCallback(data);
      } catch (error) {
        console.error("Error parsing event data:", error);
      }
    },
    onerror: (error) => {
      console.error("EventSource failed:", error);
      controller.abort();
    },
    signal: controller.signal,
  });
}

export function getBackendBaseUrl() {
  return backendBaseUrl;
}
