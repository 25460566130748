/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onTableUpdate = /* GraphQL */ `
  subscription OnTableUpdate(
    $username_catalog_name: String
    $file_name: String
    $file_entry: String
  ) {
    onTableUpdate(username_catalog_name: $username_catalog_name, file_name: $file_name, file_entry: $file_entry) {
      username_catalog_name
      file_name
      file_entry
    }
  }
`;